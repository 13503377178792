<template>
  <div class="EditGroupModal" ref="editRefModel">
    <a-modal
      v-model="isShow"
      :title="title + '集团信息'"
      @ok="handleOk"
      :maskClosable="false"
      :zIndex="9"
      centered
      width="720px"
      :getContainer="() => $refs.editRefModel"
      @cancel="handleCancel"
      cancelText="取消"
      okText="确定"
    >
      <a-form-model
        :model="editGroupForm"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        ref="ruleForm"
        :rules="rules"
        :colon="false"
      >
        <a-form-model-item label="集团客户名称" prop="name">
          <span v-if="!title">{{ editGroupForm.name }}</span>
          <a-input
            v-else
            allowClear
            placeholder="请输入"
            :maxLength="50"
            style="width: 224px"
            v-model.trim="editGroupForm.name"
          />
        </a-form-model-item>
        <a-form-model-item label="集团经理" prop="contact">
          <span v-if="!title">{{ editGroupForm.contact }}</span>
          <a-input
            v-else
            allowClear
            placeholder="请输入"
            :maxLength="50"
            style="width: 224px"
            v-model.trim="editGroupForm.contact"
          />
        </a-form-model-item>
        <a-form-model-item label="服务目标数" prop="number">
          <span v-if="!title">{{ editGroupForm.number }}</span>
          <a-input
            v-else
            allowClear
            placeholder="请输入"
            type="number"
            :maxLength="50"
            oninput="value=value.replace('.', '',).replace('-', '',)"
            style="width: 130px"
            v-model.trim="editGroupForm.number"
          />
          <span> 次</span>
        </a-form-model-item>
        <a-form-model-item label="服务统计周期" prop="periodId">
          <span v-if="!title">{{ editGroupForm.periodName }}</span>
          <a-select
            v-else
            allowClear
            placeholder="请选择"
            v-model="editGroupForm.periodId"
          >
            <a-select-option
              v-for="item in periodList"
              :value="item.id"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="当前服务完成度" v-if="title !== '新增'">
          {{
            editGroupForm.latestCompleteness ||
            editGroupForm.latestCompleteness == 0
              ? editGroupForm.latestCompleteness + "%"
              : "/"
          }}
        </a-form-model-item>
        <a-form-model-item label="上个周期完成率" v-if="title !== '新增'">
          {{
            editGroupForm.visitedCompleteness ||
            editGroupForm.visitedCompleteness == 0
              ? editGroupForm.visitedCompleteness + "%"
              : "/"
          }}
        </a-form-model-item>
        <a-form-model-item
          label="门店拜访完成率"
          v-if="title !== '新增'"
          class="completeness"
        >
          {{
            editGroupForm.completeness || editGroupForm.completeness == 0
              ? editGroupForm.completeness + "%"
              : "/"
          }}
          <span @click="getUnvisitList">（未拜访门店）</span>
        </a-form-model-item>
        <a-form-model-item
          v-if="
            (editGroupForm.brandsSet && editGroupForm.brandsSet.length) || title
          "
          label="下属品牌"
          :label-col="labelCol1"
          :wrapper-col="wrapperCol1"
          class="title"
        >
          门店数量
        </a-form-model-item>

        <a-form-model-item
          :label-col="labelCol2"
          :wrapper-col="wrapperCol2"
          v-for="(item, index) in editGroupForm.brandsSet"
          class="brand-box"
          :key="index"
          :prop="'brandsSet.' + index + '.name'"
          :rules="[
            {
              required: true,
              message: '请输入品牌名称',
              trigger: 'blur',
            },
          ]"
        >
          <div class="brand-item">
            <div class="brand-left">
              <span
                class="del"
                @click="del(item.id, index)"
                v-show="showIcon || title == '新增' || title == '编辑'"
              >
                —
              </span>
              <a-input
                :disabled="activeIndex !== index"
                allowClear
                placeholder="请输入"
                :maxLength="50"
                style="width: 224px"
                v-model.trim="item.name"
                @blur="(e) => handleBrand(e)"
                @pressEnter="(e) => e.target.blur(e)"
              />
              <a-icon
                v-show="showIcon"
                type="edit"
                class="edit"
                @click="clickIcon(item.id, index)"
              />
            </div>

            <div>{{ item.storeCount }}</div>
          </div>
        </a-form-model-item>

        <a-form-model-item :label-col="labelCol2" :wrapper-col="wrapperCol2">
          <div class="create" @click="createBrand" v-if="title">+</div>
        </a-form-model-item>
      </a-form-model>

      <template slot="footer">
        <div v-if="title">
          <a-button
            type="primary"
            style="
              background-color: #fff;
              border-color: #bfc1c6;
              color: #303030;
            "
            @click.stop="handleCancel"
            >取消</a-button
          ><a-button
            type="primary"
            style="background-color: #83ba7b; border-color: #83ba7b"
            @click.stop="handleOk"
            >确定</a-button
          >
        </div>
        <div v-else>
          <a-button
            type="primary"
            style="background-color: #83ba7b; border-color: #83ba7b"
            @click.stop="handleCancel"
            >关闭</a-button
          >
        </div>
      </template>
    </a-modal>
    <div ref="handleRefModel" class="handle">
      <a-modal
        v-model="delVisible"
        title="操作确认"
        :zIndex="99"
        @ok="delOk"
        :maskClosable="false"
        centered
        width="395px"
        :getContainer="() => $refs.handleRefModel"
        cancelText="否"
        okText="是"
      >
        <div>确认删除该品牌？</div>
      </a-modal>
    </div>
    <GetUnvisitModal
      ref="getUnvisitModal"
      :visible="getUnvisitVisible"
      @cancel="getUnvisitCancel"
    ></GetUnvisitModal>
  </div>
</template>

<script>
import { editBrand, delBrand, createBrand } from "../common/api/group";
import GetUnvisitModal from "../components/GetUnvisitModal.vue";
import { debounce } from "lodash";

export default {
  name: "EditGroupModal",
  props: {
    editGroupForm: {
      type: Object,
      default() {
        return {};
      },
    },
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    GetUnvisitModal,
  },
  data() {
    return {
      getUnvisitVisible: false,
      delVisible: false,
      activeId: null,
      activeIndex: null,
      periodList: [
        {
          id: 1,
          name: "自然年",
        },
        {
          id: 2,
          name: "自然季度",
        },
        {
          id: 3,
          name: "自然月",
        },
      ],
      labelCol: { span: 8 },
      wrapperCol: { span: 15, offset: 1 },
      labelCol1: { span: 7 },
      wrapperCol1: { span: 7, offset: 10 },
      labelCol2: { span: 0 },
      wrapperCol2: { span: 24 },
      rules: {
        name: [
          { required: true, message: "请输入集团客户名称", trigger: "blur" },
        ],
        contact: [
          { required: true, message: "请输入集团经理", trigger: "blur" },
        ],
        number: [
          { required: true, message: "请输入服务目标数", trigger: "blur" },
        ],
        periodId: [
          { required: true, message: "请选择服务统计周期", trigger: "blur" },
        ],
      },
      isShow: false,
    };
  },
  methods: {
    getUnvisitCancel() {
      this.getUnvisitVisible = false;
      this.$parent.editGroupVisible = true;
    },
    async getUnvisitList() {
      this.$refs.getUnvisitModal.form.id = this.editGroupForm.id;
      await this.$refs.getUnvisitModal.getUnvisitList();
      this.$parent.editGroupVisible = false;
      this.getUnvisitVisible = true;
    },
    del(id, index) {
      if (this.showIcon) {
        if (
          this.editGroupForm.brandsSet.length == 1 &&
          this.editGroupForm.brandsSet[0].id
        ) {
          this.$message.info("请至少保留一个下属品牌");
          return;
        }
        this.activeId = id;
        this.delVisible = true;
      } else {
        this.editGroupForm.brandsSet.splice(index, 1);
      }
    },
    async delOk() {
      const res = await delBrand({ id: this.activeId });
      console.log(res);
      this.$message.success(res.data);
      this.$parent.getGroupDetails(this.editGroupForm.id);
      this.delVisible = false;
    },
    clickIcon(id, index) {
      if (this.activeId) {
        this.activeIndex = null;
        this.activeId = null;
      } else {
        this.activeIndex = index;
        this.activeId = id;
      }
    },
    handleBrand(e) {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          console.log(this.title);
          if (this.title == "编辑") {
            if (this.showIcon) {
              const params = {
                id: this.activeId,
                name: e.target.value,
                groupId: this.editGroupForm.id,
              };
              const res = await editBrand(params);
              this.$message.success(res.msg);
            } else {
              const params = {
                groupId: this.activeId,
                name: e.target.value,
              };
              const res = await createBrand(params);
              this.$message.success(res.msg);
              this.$parent.getGroupDetails(this.editGroupForm.id);
            }

            this.$parent.showIcon = true;
            this.activeIndex = null;
            this.activeId = null;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    createBrand() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$parent.showIcon = false;
          console.log(this.$parent.groupInfo);
          this.$parent.groupInfo.brandsSet.push({
            name: "",
            storeCount: 0,
          });

          this.activeId = this.editGroupForm.id;
          if (this.title == "编辑") {
          }
          this.activeIndex = this.$parent.groupInfo.brandsSet.length - 1;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    handleOk: debounce(function () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log(this.editGroupForm);
          if (!this.editGroupForm.brandsSet.length) {
            this.$message.info("请至少添加一个下属品牌");
            return;
          }
          this.$emit("ok", { title: this.title, params: this.editGroupForm });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }, 500),
    handleCancel() {
      this.$emit("cancel");
      this.$refs.ruleForm.resetFields();
    },
  },
  watch: {
    visible(val) {
      this.isShow = val;
    },
  },
};
</script>

<style scoped lang="scss">
.EditGroupModal::v-deep {
  .ant-modal-header {
    .ant-modal-title {
      text-align: center !important;
    }
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 650px;
    overflow: auto;
    .ant-form {
      width: 100%;
      .ant-form-item {
        margin-bottom: 0px;

        &.completeness {
          span {
            cursor: pointer;
            color: #9a9ea7;
          }
        }

        &.title {
          margin-top: 10px;
          background: rgba(131, 186, 123, 0.1);
          .ant-form-item-children {
            color: rgba(0, 0, 0, 0.85);
          }
        }
        .ant-select {
          width: 130px;
        }
        &.brand-box {
          border: 1px solid rgba(191, 193, 198, 0.5);
          border-top: 0;
          padding: 5px 0;
          box-sizing: border-box;
          .has-error {
            .ant-form-explain {
              width: 50%;
              text-align: center;
            }
          }
          .brand-item {
            display: flex;
            justify-content: space-between;

            div {
              width: 50%;
              text-align: center;
              &.brand-left {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #bfc1c6;

                .ant-input-affix-wrapper {
                  margin: 0 8px;
                  text-align: center;
                }
                .del,
                .edit {
                  cursor: pointer;
                }
              }
            }

            .ant-select-disabled.ant-select:not(.ant-select-customize-input)
              .ant-select-selector,
            .ant-input-disabled,
            .ant-input-number-disabled {
              color: rgba(0, 0, 0, 0.65) !important;
              background: #fff !important;
              cursor: not-allowed !important;
              border: none;
            }
          }
        }
        .create {
          text-align: center;
          padding: 0;
          color: #9a9ea7;
          font-size: 32px;
          border: 1px solid rgba(191, 193, 198, 0.5);
          border-top: 0;
          cursor: pointer;
        }
      }
    }
  }
  .ant-modal-footer {
    text-align: center;
    padding-bottom: 44px;
    border: 0;
    div button {
      width: 72px;
      height: 32px;
      &:nth-child(2) {
        margin-left: 80px;
        background: #83ba7b;
        border: 0;
      }
    }
    .ant-btn:nth-child(1):hover {
      color: #fff;
    }
  }
  .handle .ant-modal-footer .ant-btn:nth-child(1):hover {
    color: #83ba7b;
  }
}
</style>
