import $http from '../http'


// 登录
export const login = data => {
    return $http.post('/api/v1/auth/adminlogin', data)
}

// 刷新token
export const refreshToken = data => {

    return $http.get('/api/v1/auth/api-token-refresh', data)
}

// 修改个人密码
export const editPwd = data => {
    const params = JSON.parse(JSON.stringify(data))
    delete params.id
    return $http.post(`/api/v1/admin/user/${data.id}/passwd`, params)
}