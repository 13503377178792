<template>
  <div class="CreateClientModal">
    <!-- 新建门店弹框 -->
    <div ref="createRefModel" class="create-client-modal">
      <a-modal
        v-model="isShow"
        :title="title"
        :maskClosable="false"
        @ok="handleOk"
        centered
        width="620px"
        :getContainer="() => $refs.createRefModel"
        @cancel="handleCancel"
      >
        <a-form-model
          :model="createClientForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="ruleForm"
          :rules="rules"
          :colon="false"
        >
          <a-form-model-item label="门店名称" prop="name">
            <a-input
              allowClear
              placeholder="请输入门店名称"
              :maxLength="50"
              style="width: 224px"
              v-model.trim="createClientForm.name"
            />
          </a-form-model-item>

          <a-form-model-item label="集团品牌" prop="bid">
            <SearchSelect
              :bid="createClientForm.bid"
              :flag="false"
              :iptWidth="220"
              @updateParams="groupChange"
            ></SearchSelect>
          </a-form-model-item>
          <!-- <a-form-model-item label="集团经理">
            <a-input
              allowClear
              placeholder="请输入集团经理"
              :maxLength="50"
              style="width: 224px"
              v-model.trim="createClientForm.groupManager"
            />
          </a-form-model-item> -->
          <a-form-model-item label="门店地址">
            <a-input
              placeholder="请填写详细的省市区街道门牌号"
              :maxLength="50"
              style="width: 224px"
              v-model.trim="createClientForm.address"
            />
          </a-form-model-item>
          <!-- <a-form-model-item label="所属区域">
            <a-select
              allowClear
              placeholder="请选择所属区域"
              v-model="createClientForm.region"
            >
              <a-select-option
                :value="item"
                v-for="(item, index) in areaList"
                :key="index"
              >
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-model-item> -->
          <a-form-model-item label="指派区域经理/服务工程师" prop="uid">
            <a-select
              placeholder="请选择区域经理/服务工程师"
              v-model="createClientForm.uid"
              allowClear
            >
              <a-select-option
                :value="item.id"
                v-for="(item, index) in staffList"
                :key="index"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="拜访间隔" prop="period">
            <a-input
              :maxLength="8"
              style="width: 52px"
              class="interval"
              @change="timeChange"
              v-model.trim="createClientForm.period"
            />
            <span> 天</span>
          </a-form-model-item>
          <a-form-model-item label="下次拜访截止日">
            <a-date-picker
              @change="dateChange"
              :value="createClientForm.nextVistDay"
            />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import SearchSelect from "../components/SearchSelect.vue";
import { debounce } from "lodash";

export default {
  name: "CreateClientModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    areaList: {
      type: Array,
      default() {
        return [];
      },
    },
    staffList: {
      type: Array,
      default() {
        return [];
      },
    },
    clientInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    SearchSelect,
  },
  data() {
    return {
      brandList: [],
      isShow: false,
      createClientForm: {
        name: "",
        // groupManager: "",
        address: "",
        region: undefined,
        uid: undefined,
        nextVistDay: null,
        period: "",
        bid: undefined,
      },
      labelCol: { span: 9 },
      wrapperCol: { span: 13, offset: 1 },
      rules: {
        name: [{ required: true, message: "请输入门店名称", trigger: "blur" }],
        bid: [{ required: true, message: "请选择集团品牌", trigger: "blur" }],
        uid: [
          {
            required: true,
            message: "请选择区域经理/服务工程师",
            trigger: "blur",
          },
        ],
        period: [
          { required: true, message: "请输入拜访间隔", trigger: "blur" },
        ],
      },
      params: {
        keyword: "",
      },
      // 集团
      groupList: [],
      choose: true,
      isblur: false,
      iptVal: "",
    };
  },
  methods: {
    // 选择集团品牌
    groupChange(e) {
      if (e == 0) {
        this.createClientForm.bid = 0;
      } else {
        this.createClientForm.bid = e;
      }
    },

    // 选择截止日期
    dateChange(date, dateStr) {
      if (dateStr) {
        this.createClientForm.nextVistDay = moment(dateStr, "YYYY-MM-DD");
      } else {
        this.createClientForm.nextVistDay = null;
      }
    },
    // 输入天数
    timeChange(e) {
      var r = /^\+?[1-9][0-9]*$/;
      let flag = r.test(e.target.value);

      if (flag) {
        let num = e.target.value;

        let time = this.getLaterDate(num);

        this.createClientForm.nextVistDay = moment(time, "YYYY-MM-DD");
      } else {
        this.createClientForm.period = "";
      }
    },
    // 根据天数计算时间
    getLaterDate(dayNum, dateTime = null) {
      // 如果为null,则格式化当前时间为时间戳
      if (!dateTime) dateTime = +new Date();
      // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
      if (dateTime.toString().length == 10) dateTime *= 1000;
      const timestamp = +new Date(Number(dateTime));

      const one_day = 86400000; // 24 * 60 * 60 * 1000;
      const addVal = dayNum * one_day + timestamp;
      //x天后的日期
      const date = new Date(addVal);

      //格式化日期
      const filters = (n) => {
        return n < 10 ? (n = "0" + n) : n;
      };
      const month = filters(date.getMonth() + 1);
      const day = filters(date.getDate());
      const hours = filters(date.getHours());
      const minutes = filters(date.getMinutes());
      const seconds = filters(date.getSeconds());

      const lastTime = `${date.getFullYear()}-${month}-${day}`;

      return lastTime;
    },

    // 确认新建门店
    handleOk: debounce(function () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // if (this.createClientForm.nextVistDay && !this.createClientForm.uid) {
          //   return this.$message.info("请选择区域经理/服务工程师");
          // }
          if (this.clientInfo.id) {
            this.createClientForm.id = this.clientInfo.id;
          }
          console.log(this.createClientForm, " this.createClientForm");
          this.$emit("createClient", this.createClientForm);
        }
      });
    }, 500),
    // 取消
    handleCancel() {
      this.$emit("cancel");
      this.$refs.ruleForm.resetFields();
      this.$parent.clientInfo = {};

      this.createClientForm = {
        name: "",
        bid: undefined,
        groupManager: "",
        address: "",
        region: undefined,
        uid: undefined,
        nextVistDay: null,
        period: "",
      };
    },
  },

  watch: {
    visible(val) {
      this.isShow = val;
    },
    clientInfo(val) {
      console.log(val);
      this.createClientForm.name = val.name;
      if (val.bid) {
        this.createClientForm.bid = val.bid;
      }
      if (val.groupManager) {
        this.createClientForm.groupManager = val.groupManager;
      }
      if (val.address) {
        this.createClientForm.address = val.address;
      }
      if (val.region) {
        this.createClientForm.region = val.region;
      }
      if (val.saleId) {
        this.createClientForm.uid = val.saleId;
      }
      if (val.period) {
        this.createClientForm.period = val.period;
      }
      if (val.nxtDealDate) {
        this.createClientForm.nextVistDay = moment(
          val.nxtDealDate,
          "YYYY-MM-DD"
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.CreateClientModal {
  .create-client-modal::v-deep {
    .ant-modal-header {
      .ant-modal-title {
        text-align: center !important;
      }
    }
    .ant-modal-body {
      padding: 20px 78px 0;
      .ant-form-item {
        margin-bottom: 10px;

        textarea.ant-input {
          min-height: 100px;
        }
        .ant-input[disabled],
        .ant-input {
          color: #303030;
          &.ant-calendar-picker-input {
            width: 128px;
          }
        }
        .ant-input[disabled] {
          background-color: #fff;
        }

        .ant-select {
          width: 224px;
          color: #303030;
        }
        .interval {
          margin-right: 10px;
        }
        .hidden {
          .ant-select-arrow {
            display: none;
          }
        }
      }
    }
    .ant-modal-footer {
      text-align: center;
      padding-bottom: 44px;
      border: 0;
      div button {
        width: 72px;
        height: 32px;
        &:nth-child(2) {
          margin-left: 80px;
          background: #83ba7b;
          border: 0;
        }
      }
    }
  }
}
</style>
