import axios from 'axios';
import { refreshToken } from './api/login'
import { message } from 'ant-design-vue';
import router from '../router/index'

// 如果你要用到session验证码功能，让请求携带cookie
// axios.defaults.withCredentials = true;

// 配置请求的根路径
const baseURL = process.env.VUE_APP_SERVER_URL

const request = axios.create({
    baseURL,
    // timeout: 3000
})



// 请求拦截器
request.interceptors.request.use(
    config => {
        // console.log('请求拦截器:', config);
        var token = sessionStorage.getItem('token');

        config.headers = {
            'Content-Type': 'application/json;charset=UTF-8',
        }
        // console.log(config.url.indexOf('auth/adminlogin') == -1);
        if (token && config.url.indexOf('auth/adminlogin') == -1) {
            config.headers.Authorization = 'Auth ' + token
        }
        return config;
    }
);


// 响应拦截器
request.interceptors.response.use(
    res => {
        // console.log('响应拦截器:', res);
        if (res.data.code == 1) {
            return res.data
        }

    },

    async error => {

        const res = error.response

        // console.log('是否跳转到登录页面:', res.data.code == 418 && res.config.url.includes('/api-token-refresh') || res.data.code == 401);
        if (res.data && res.data.code == 418 && res.config.url.includes('/api-token-refresh') || res.data.code == 401) {
            message.info('登录过期，请重新登录');
            // 清除token
            sessionStorage.removeItem('token');
            // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
            setTimeout(() => {
                router.replace({
                    path: '/login',
                    query: {
                        redirect: router.currentRoute.fullPath
                    }
                });
            }, 1000);
        } else if (res.data && res.data.code == 418) {
            const newToken = window.sessionStorage.getItem('refreshToken')
            window.sessionStorage.setItem('token', newToken)
            const r = await refreshToken()
            // console.log('刷新token后返回:', r);
            window.sessionStorage.setItem('token', r.data.token)
            return request(res.config)
        } else {
            message.error(res.data.msg)
        }

        return Promise.reject(error.response);

    }
);

/**
 * 封装get方法
 * @param url
 * @param params
 * @returns {Promise}
 */
function get(url, params = {}) {
    return request.get(url, {
        params
    })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

function post(url, data = {}) {
    return request.post(url, data, {
        onUploadProgress: progressEvent => {
            console.log(progressEvent);
            // 这里可以获取上传进度信息
            const { loaded, total } = progressEvent;
            console.log(`上传进度: ${Math.round(loaded / total * 100)}%`);
        }
    })
}



/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */

function del(url, params = {}) {
    return request.delete(url, params)
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

function put(url, data = {}) {
    return request.put(url, data)
}


export default {
    baseURL,
    request,
    get,
    post,
    del,
    put
}