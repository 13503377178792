<template>
  <div class="GetUnvisitModal" ref="editRefModel">
    <a-modal
      v-model="isShow"
      title="未拜访门店列表"
      :maskClosable="false"
      :zIndex="11"
      centered
      width="720px"
      :getContainer="() => $refs.editRefModel"
      @cancel="handleCancel"
      cancelText="取消"
      okText="确定"
    >
      <a-table
        :columns="columns"
        :loading="loading"
        :data-source="unvisitList"
        :rowKey="(record) => record.id"
        :pagination="pagination"
        :scroll="{ y: '400px' }"
        bordered
      >
      </a-table>

      <template slot="footer">
        <a-button
          type="primary"
          style="background-color: #83ba7b; border-color: #83ba7b"
          @click.stop="handleCancel"
          >关闭</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import { getUnvisit } from "../common/api/group";
export default {
  name: "GetUnvisitModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: [
        {
          title: "客户/门店名称",
          dataIndex: "name",
          key: "name",
        },
      ],
      pagination: {
        position: "bottom",
        pageSize: 10,
        current: 1,
        total: 0,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["5", "10", "20"], // 每页数量选项
        showQuickJumper: true,
        // 显示总数
        showTotal: (total) => `共 ${total} 条`,
        // 改变每页数量时更新显示
        onShowSizeChange: (page, pageSize) => this.onSizeChange(page, pageSize),
        // 点击页码事件
        onChange: (page, pageSize) => this.onPageChange(page, pageSize),
      },
      form: {
        page: 0,
        size: 10,
        id: "",
      },
      loading: false,
      unvisitList: [],
      isShow: false,
    };
  },
  methods: {
    // 改变每页数量时更新显示
    onSizeChange(page, pageSize) {
      console.log("每页数量:", pageSize);
      this.pagination.current = 1;
      this.pagination.pageSize = pageSize;
      this.form.page = 0;
      this.form.size = pageSize;
      this.getUnvisitList();
    },
    // 点击页码事件
    onPageChange(page) {
      console.log("页码值:", page);
      this.pagination.current = page;
      this.form.page = page - 1;
      this.getUnvisitList();
    },
    async getUnvisitList() {
      console.log(this.form, 1111);
      this.loading = true;
      const res = await getUnvisit(this.form);
      this.loading = false;
      console.log(res, "未拜访门点");
      this.unvisitList = res.data.content;
      this.pagination.total = res.data.totalElements;
    },
    handleCancel() {
      this.isShow = false;
      this.$emit("cancel");
    },
  },

  watch: {
    visible(val) {
      this.isShow = val;
    },
  },
};
</script>

<style scoped lang="scss">
.ant-table-wrapper {
  width: 100%;
}
</style>
