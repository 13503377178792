<template>
  <div class="OrderReport">
    <div class="report-header">
      <div class="report-header-top">
        <module-title text="筛选项"></module-title>
      </div>
      <div class="report-header-bottom">
        <div class="report-header-bottom-left">
          <a-form-model
            :model="form"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :colon="false"
          >
            <a-form-model-item label="集团品牌">
              <searchSelect
                :bid="form.bid"
                :iptWidth="180"
                @updateParams="groupChange"
              ></searchSelect>
            </a-form-model-item>
            <!-- <a-form-model-item label="品牌名称">
              <a-select
                placeholder="请选择品牌名称"
                v-model="form.bid"
                allowClear
              >
                <a-select-option
                  :value="item.id"
                  v-for="(item, index) in brandList"
                  :key="index"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item> -->
            <a-form-model-item label="起止时间段" class="date">
              <a-date-picker
                v-model="form.starttime"
                :disabled-date="disabledStartDate"
                :show-time="false"
                valueFormat="YYYY-MM-DD"
                placeholder="请输入开始时间"
                @openChange="handleStartOpenChange"
              />
              <span> ── </span>
              <a-date-picker
                v-model="form.endtime"
                :disabled-date="disabledEndDate"
                :show-time="false"
                valueFormat="YYYY-MM-DD"
                placeholder="请输入结束时间"
                :open="endOpen"
                @openChange="handleEndOpenChange"
              />
            </a-form-model-item>
            <!-- <a-form-model-item label="单位">
              <a-select
                placeholder="请选择单位"
                v-model="form.periodId"
                allowClear
              >
                <a-select-option
                  :value="item.id"
                  v-for="item in periodList"
                  :key="item.id"
                >
                  {{ item.text }}
                </a-select-option>
              </a-select>
            </a-form-model-item> -->
          </a-form-model>
        </div>
        <div class="report-header-bottom-right" @click="getReportList">
          汇总
        </div>
      </div>
    </div>
    <div class="report-main">
      <div class="report-main-top">
        <module-title text="服务工单数据统计"></module-title>
      </div>
      <div class="report-main-bottom">
        <a-table
          :columns="columns"
          :data-source="reportList"
          :rowKey="(record) => record.id"
          :loading="loading"
          :pagination="pagination"
        >
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import SearchSelect from "../components/SearchSelect.vue";
import ModuleTitle from "../components/ModuleTitle";
import { getBrand } from "../common/api/group";
import { getOrderReport } from "../common/api/statistics";
import moment from "moment";

export default {
  name: "WorkReport",
  components: {
    SearchSelect,
    ModuleTitle,
  },
  data() {
    return {
      pagination: {
        position: "bottom",
        pageSize: 10,
        current: 1,
        total: 0,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["5", "10", "20"], // 每页数量选项
        showQuickJumper: true,
        // 显示总数
        showTotal: (total) => `共 ${total} 条`,
        // 改变每页数量时更新显示
        onShowSizeChange: (page, pageSize) => this.onSizeChange(page, pageSize),
        // 点击页码事件
        onChange: (page, pageSize) => this.onPageChange(page, pageSize),
      },
      loading: false,
      columns: [
        {
          title: "门店名称",
          dataIndex: "name",
          key: "name",
          align: "center",
        },
        {
          title: "次数",
          dataIndex: "num",
          key: "num",
          align: "center",
        },
        {
          title: "平均响应时间",
          dataIndex: "avghour",
          key: "avghour",
          align: "center",
        },
      ],
      reportList: [],
      endOpen: false,
      labelCol: { span: 8 },
      wrapperCol: { span: 20 },
      // brandList: [],
      form: {
        page: 0,
        size: 10,
        bid: undefined,
        starttime: null,
        endtime: null,
        periodId: undefined,
      },
      periodList: [
        {
          id: 1,
          text: "自然年",
        },
        {
          id: 2,
          text: "自然季度",
        },
        {
          id: 3,
          text: "自然月",
        },
        {
          id: 4,
          text: "半个自然年",
        },
      ],
    };
  },
  methods: {
    // 改变每页数量时更新显示
    onSizeChange(page, pageSize) {
      this.pagination.current = 1;
      this.pagination.pageSize = pageSize;
      this.form.page = 0;
      this.form.size = pageSize;
      this.getReportList();
    },
    // 点击页码事件
    onPageChange(page) {
      this.pagination.current = page;
      this.form.page = page - 1;
      this.getReportList();
    },
    async getReportList() {
      let obj = JSON.parse(JSON.stringify(this.form));
      obj.page = obj.page.toString();
      for (let k in obj) {
        if (!obj[k]) {
          delete obj[k];
        }
      }
      this.loading = true;
      const res = await getOrderReport(obj);
      this.loading = false;
      console.log(res);
      this.reportList = res.data.content;
      this.pagination.total = res.data.totalElements;
    },

    disabledStartDate(startValue) {
      if (this.form.endtime) {
        return (
          startValue >
          moment(this.form.endtime, "YYYY-MM-DD")
            .subtract(-1, "days")
            .startOf("day")
        );
      }
    },
    disabledEndDate(endValue) {
      if (this.form.starttime) {
        console.log(endValue.format("YYYY-MM-DD"));
        return (
          endValue <
          moment(this.form.starttime, "YYYY-MM-DD")
            .subtract(1, "days")
            .endOf("day")
        );
      }
    },
    handleStartOpenChange(open) {
      if (!open && !this.form.endtime) {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open;
    },

    // 获取品牌列表
    // async getBrandList() {
    //   const res = await getBrand({ page: 0, size: 1000 });
    //   console.log(res, "品牌");
    //   this.brandList = res.data.content;
    // },
    groupChange(e) {
      if (e == 0) {
        this.form.bid = 0;
      } else {
        this.form.bid = e;
      }
    },
  },
  created() {
    // this.getBrandList();
    this.getReportList();
  },
};
</script>

<style scoped lang="scss">
.OrderReport {
  .report-header {
    background: #ffffff;
    border-radius: 16px;
    padding: 20px;

    .report-header-bottom {
      display: flex;
      align-items: center;
      padding: 24px 50px 0 0;
      .report-header-bottom-left {
        margin-right: 50px;
        .ant-form {
          display: flex;
          align-items: center;

          .ant-row {
            margin-right: 50px;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            &.date {
              margin-right: 90px;
            }

            .ant-input,
            .ant-select {
              width: 180px;
              height: 30px;
            }
            .ant-calendar-picker {
              width: 150px;
            }
          }
        }
      }
      .report-header-bottom-right {
        width: 72px;
        height: 32px;
        background: #83ba7b;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .report-main {
    margin-top: 20px;
    background: #ffffff;
    border-radius: 16px;
    padding: 20px;
    .report-main-bottom {
      margin-top: 30px;
    }
  }
}
</style>
