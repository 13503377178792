<template>
  <div class="AlertModal">
    <!-- 操作提示框 -->
    <div class="alert-user-modal" ref="alertRefModel">
      <a-modal
        v-model="isShow"
        title="操作提示"
        :maskClosable="false"
        centered
        width="395px"
        :getContainer="() => $refs.alertRefModel"
        cancelText="关闭"
      >
        <div>
          <div v-if="isEdit">
            <span>已更改用户“{{ info.name }}”的密码。</span>
          </div>
          <div v-else>
            用户<span>“{{ info.name }}”</span>已被<span>“{{ text }}”</span>。
          </div>
        </div>
        <template slot="footer">
          <a-button @click.stop="handleCancel">关闭</a-button>
        </template>
      </a-modal>
    </div>
  </div>
</template>

<script>
export default {
  // 组件名称
  name: "AlertModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default() {
        return {};
      },
    },
    text: {
      type: String,
      default: "",
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 是否显示
      isShow: false,
    };
  },
  methods: {
    handleCancel() {
      this.isShow = false;
      this.$emit("cancel");
    },
  },
  watch: {
    visible(val) {
      this.isShow = val;
    },
  },
};
</script>

<style scoped lang="scss">
.alert-user-modal::v-deep {
  .ant-modal-header {
    .ant-modal-title {
      text-align: center !important;
    }
  }
  .ant-modal-body {
    text-align: center;
    span {
      color: #ec4e44;
    }
  }
  .ant-modal-footer {
    text-align: center;
    padding-bottom: 44px;
    border: 0;
    button {
      width: 72px;
      height: 32px;
      background: #83ba7b !important;
      border: 0 !important;
      color: #fff;
    }
  }
}
</style>
