<template>
  <div class="WorkReport">
    <div class="report-header">
      <div class="report-header-top">
        <module-title text="筛选项"></module-title>
      </div>
      <div class="report-header-bottom">
        <div class="report-header-bottom-left">
          <a-form-model
            :model="form"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :colon="false"
            :rules="rules"
            ref="ruleForm"
          >
            <a-form-model-item label="集团品牌">
              <searchSelect
                :bid="form.bid"
                :iptWidth="180"
                @updateParams="groupChange"
              ></searchSelect>
            </a-form-model-item>
            <!-- <a-form-model-item label="品牌名称">
              <a-select
                placeholder="请选择品牌名称"
                v-model="form.bid"
                allowClear
              >
                <a-select-option
                  :value="item.id"
                  v-for="(item, index) in brandList"
                  :key="index"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item> -->
            <a-form-model-item label="起止时间段" class="date">
              <a-date-picker
                v-model="form.starttime"
                :disabled-date="disabledStartDate"
                :show-time="false"
                valueFormat="YYYY-MM-DD"
                placeholder="请选择开始时间"
                @openChange="handleStartOpenChange"
              />
              <span> ── </span>
              <a-date-picker
                v-model="form.endtime"
                :disabled-date="disabledEndDate"
                :show-time="false"
                valueFormat="YYYY-MM-DD"
                placeholder="请选择结束时间"
                :open="endOpen"
                @openChange="handleEndOpenChange"
              />
            </a-form-model-item>
            <a-form-model-item label="单位" prop="periodId">
              <a-select
                placeholder="请选择单位"
                v-model="form.periodId"
                :disabled="disabled"
                allowClear
              >
                <a-select-option
                  :value="item.id"
                  v-for="item in periodList"
                  :key="item.id"
                >
                  {{ item.text }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </div>
        <div class="report-header-bottom-right" @click="getReport">汇总</div>
      </div>
    </div>
    <div class="report-main">
      <div class="report-main-left">
        <div class="serve">
          <div><module-title text="服务次数"></module-title></div>
          <div>
            <div id="serve" style="width: 977px; height: 360px"></div>
          </div>
        </div>
        <div class="rains">
          <div><module-title text="培训次数"></module-title></div>
          <div>
            <div id="rains" style="width: 977px; height: 360px"></div>
          </div>
        </div>
      </div>
      <div class="report-main-right">
        <div class="rains-ratio">
          <div><module-title text="培训所占比例"></module-title></div>
          <div class="chart-box">
            <div id="ratio" style="width: 90%; height: 360px"></div>
          </div>
        </div>
        <div class="score-ratio">
          <div>
            <module-title text="不同评分的问卷数量所占比例"></module-title>
          </div>
          <div class="chart-box">
            <div id="score" style="width: 90%; height: 360px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchSelect from "../components/SearchSelect.vue";
import ModuleTitle from "../components/ModuleTitle";
import { getWorkReport } from "../common/api/statistics";
import moment from "moment";

export default {
  name: "WorkReport",
  components: {
    SearchSelect,
    ModuleTitle,
  },
  data() {
    return {
      disabled: false,
      rules: {
        starttime: [
          {
            validator: this.validateStarttime,
            trigger: "blur",
          },
        ],
        endtime: [
          {
            validator: this.validateEndtime,
            trigger: "blur",
          },
        ],
        // { required: true, message: "请选择单位", trigger: "blur" }
        periodId: [],
      },
      color1: ["#d3d3d3"],
      color2: ["#d3d3d3"],
      report: {},
      endOpen: false,
      labelCol: { span: 8 },
      wrapperCol: { span: 20 },
      // brandList: [],
      form: {
        bid: undefined,
        starttime: null,
        endtime: null,
        periodId: undefined,
      },
      periodList: [
        {
          id: 3,
          text: "自然月",
        },
        {
          id: 2,
          text: "自然季度",
        },
        {
          id: 4,
          text: "半个自然年",
        },
        {
          id: 1,
          text: "自然年",
        },
      ],
    };
  },
  methods: {
    handlePeriodList(n) {
      if (n < 3) {
        this.periodList = [
          {
            id: 5,
            text: "自然日",
          },
        ];
        this.form.periodId = 5;
        this.disabled = true;
      } else if (n < 9) {
        this.periodList = [
          {
            id: 3,
            text: "自然月",
          },
        ];
        this.form.periodId = 3;
        this.disabled = true;
      } else if (n < 18) {
        this.periodList = [
          {
            id: 3,
            text: "自然月",
          },
          {
            id: 2,
            text: "自然季度",
          },
        ];
        this.form.periodId = 3;
        this.disabled = false;
      } else if (n < 36) {
        this.periodList = [
          {
            id: 3,
            text: "自然月",
          },
          {
            id: 2,
            text: "自然季度",
          },
          {
            id: 4,
            text: "半个自然年",
          },
        ];
        this.form.periodId = 3;
        this.disabled = false;
      } else {
        this.periodList = [
          {
            id: 2,
            text: "自然季度",
          },
          {
            id: 4,
            text: "半个自然年",
          },
          {
            id: 1,
            text: "自然年",
          },
        ];
        this.form.periodId = 2;
        this.disabled = false;
      }
    },
    validateStarttime(rule, value, callback) {
      if (!value && this.form.endtime) {
        callback(new Error("请选择开始日期"));
      } else {
        callback();
      }
    },
    validateEndtime(rule, value, callback) {
      if (!value && this.form.starttime) {
        callback(new Error("请选择结束日期"));
      } else {
        callback();
      }
    },
    // 判断结束时间是否当月最后一天
    isLastDateOfCurrentMonth(dateData) {
      if (!dateData) return false;
      const date = new Date(dateData);
      const next_date = new Date(
        date?.getTime() + 24 * 60 * 60 * 1000
      )?.getDate();
      if (next_date === 1) {
        return true;
      }
      return false;
    },
    getServeData() {
      let keyArr = [];
      let valueArr = [];
      if (JSON.stringify(this.report.SumOfTrains) !== "{}") {
        keyArr = Object.keys(this.report.CountsOfServices);
        valueArr = Object.values(this.report.CountsOfServices);
      }
      console.log(keyArr, valueArr, "服务次数");

      let endtime = "";
      if (this.form.endtime) {
        endtime = this.form.endtime;
      } else {
        endtime = new Date().toISOString().slice(0, 10);
      }
      const result = this.isLastDateOfCurrentMonth(endtime);
      if (!result) {
        if (!this.form.periodId) {
          keyArr[0] =
            keyArr[0] +
            " ( 至 " +
            endtime.split("-")[1] +
            "-" +
            endtime.split("-")[2] +
            " ) ";
        } else if (this.form.periodId == 3 || this.form.periodId == 1) {
          keyArr[keyArr.length - 1] =
            keyArr[keyArr.length - 1] +
            " ( 至 " +
            endtime.split("-")[1] +
            "-" +
            endtime.split("-")[2] +
            " ) ";
        }
      }
      let myChart = this.$echarts.getInstanceByDom(
        document.getElementById("serve")
      );
      if (myChart == null) {
        myChart = this.$echarts.init(document.getElementById("serve"));
      }

      const option = {
        tooltip: {
          // 开启提示框组件
          trigger: "axis", // 坐标轴触发
          axisPointer: {
            // 坐标轴指示器配置项
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        xAxis: {
          name: "时间",
          type: "category",
          data: keyArr,
          axisLine: {
            show: true,
            symbol: ["none", "arrow"],
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          name: "次数",
          type: "value",
          minInterval: 1,
          axisLine: {
            show: true,
            symbol: ["none", "arrow"],
          },
        },
        series: [
          {
            data: valueArr,
            type: "bar",
            showBackground: true,
            barWidth: "20%",
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
          },
        ],
      };

      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });

      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    getRainsData() {
      let keyArr = [];
      let valueArr = [];
      if (JSON.stringify(this.report.SumOfTrains) !== "{}") {
        keyArr = Object.keys(this.report.SumOfTrains);
        valueArr = Object.values(this.report.SumOfTrains);
      }
      console.log(keyArr, valueArr, "培训次数");
      let endtime = "";
      if (this.form.endtime) {
        endtime = this.form.endtime;
      } else {
        endtime = new Date().toISOString().slice(0, 10);
      }
      const result = this.isLastDateOfCurrentMonth(endtime);
      if (!result) {
        if (!this.form.periodId) {
          keyArr[0] =
            keyArr[0] +
            " ( 至 " +
            endtime.split("-")[1] +
            "-" +
            endtime.split("-")[2] +
            " ) ";
        } else if (this.form.periodId == 3 || this.form.periodId == 1) {
          keyArr[keyArr.length - 1] =
            keyArr[keyArr.length - 1] +
            " ( 至 " +
            endtime.split("-")[1] +
            "-" +
            endtime.split("-")[2] +
            " ) ";
        }
      }

      let myChart = this.$echarts.getInstanceByDom(
        document.getElementById("rains")
      );
      if (myChart == null) {
        myChart = this.$echarts.init(document.getElementById("rains"));
      }

      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          name: "时间",
          type: "category",
          data: keyArr,
          axisLine: {
            show: true,
            symbol: ["none", "arrow"],
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          name: "次数",
          type: "value",
          minInterval: 1,
          axisLine: {
            show: true,
            symbol: ["none", "arrow"],
          },
        },
        series: [
          {
            data: valueArr,
            type: "bar",
            showBackground: true,
            barWidth: "20%",
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
          },
        ],
      };

      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });

      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    getRatioData() {
      let data = [];
      if (JSON.stringify(this.report.typeOfTrains) !== "{}") {
        this.color1 = ["#2b56d9", "#60b538", "#dc3333"];
        for (let k in this.report.typeOfTrains) {
          let obj = {
            value: this.report.typeOfTrains[k],
            name: k,
          };
          data.push(obj);
        }
      } else {
        this.color1 = ["#d3d3d3"];
      }

      let myChart = this.$echarts.getInstanceByDom(
        document.getElementById("ratio")
      );
      if (myChart == null) {
        myChart = this.$echarts.init(document.getElementById("ratio"));
      }

      const option = {
        tooltip: {
          show: true,
          trigger: "item",
        },
        legend: {
          top: "bottom",
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
          },
        },
        series: [
          {
            name: "暂无数据",
            type: "pie",
            radius: [50, 100],
            center: ["50%", "50%"],
            roseType: "area",
            avoidLabelOverlap: false,
            color: this.color1,
            itemStyle: {
              borderRadius: 8,
            },

            data,
          },
        ],
      };

      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });

      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    getScoreData() {
      let data = [];
      if (JSON.stringify(this.report.scoreClassify) !== "{}") {
        this.color2 = ["#2b56d9", "#60b538", "#dc3333"];
        for (let k in this.report.scoreClassify) {
          let obj = {
            value: this.report.scoreClassify[k],
            name: k,
          };
          data.push(obj);
        }
      } else {
        this.color2 = ["d3d3d3"];
      }

      let myChart = this.$echarts.getInstanceByDom(
        document.getElementById("score")
      );
      if (myChart == null) {
        myChart = this.$echarts.init(document.getElementById("score"));
      }
      const option = {
        tooltip: {
          show: true,
          trigger: "item",
        },
        legend: {
          top: "bottom",
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
          },
        },

        series: [
          {
            name: "暂无数据",
            type: "pie",
            radius: [50, 100],
            center: ["50%", "50%"],
            roseType: "area",
            avoidLabelOverlap: false,
            color: this.color2,
            itemStyle: {
              borderRadius: 8,
            },

            data,
          },
        ],
      };

      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });

      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    async getReport() {
      if (this.form.starttime && !this.form.endtime) {
        this.$message.info("请选择结束时间");
        return;
      } else if (!this.form.starttime && this.form.endtime) {
        this.$message.info("请选择开始时间");
        return;
      } else if (
        this.form.starttime &&
        this.form.endtime &&
        !this.form.periodId
      ) {
        this.$message.info("请选择单位");
        return;
      }
      let obj = JSON.parse(JSON.stringify(this.form));

      for (let k in obj) {
        if (!obj[k]) {
          delete obj[k];
        }
      }
      const res = await getWorkReport(obj);
      console.log(res, "报告");
      this.report = res.data;
      console.log(this.report);
      this.getServeData();
      this.getRainsData();
      this.getRatioData();
      this.getScoreData();
    },
    // 计算两个日期之间相差的月份
    calculateMonthDifference(startDateString, endDateString) {
      var start = new Date(startDateString); // 将起始日期字符串转换为Date对象
      var end = new Date(endDateString); // 将结束日期字符串转换为Date对象

      var timeDiff = Math.abs(end.getTime() - start.getTime()); // 获取时间差（单位：毫秒）
      var monthDiff = Math.ceil(timeDiff / (30 * 24 * 60 * 60 * 1000)); // 根据每个月平均天数进行近似计算

      return monthDiff; // 返回月份差值
    },
    disabledStartDate(startValue) {
      if (this.form.endtime) {
        return (
          startValue >
          moment(this.form.endtime, "YYYY-MM-DD")
            .subtract(-1, "days")
            .startOf("day")
        );
      }
    },
    disabledEndDate(endValue) {
      if (this.form.starttime) {
        // console.log(endValue.format("YYYY-MM-DD"));
        return (
          endValue <
          moment(this.form.starttime, "YYYY-MM-DD")
            .subtract(1, "days")
            .endOf("day")
        );
      }
    },
    handleStartOpenChange(open) {
      if (!open && !this.form.endtime) {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open;
    },

    // 获取品牌列表
    // async getBrandList() {
    //   const res = await getBrand({ page: 0, size: 1000 });
    //   console.log(res, "品牌");
    //   this.brandList = res.data.content;
    // },
    groupChange(e) {
      if (e == 0) {
        this.form.bid = 0;
      } else {
        this.form.bid = e;
      }
    },
  },
  watch: {
    "form.starttime"(newValue) {
      if (newValue && this.form.endtime) {
        let n = this.calculateMonthDifference(newValue, this.form.endtime);

        this.handlePeriodList(n);
      } else if (!newValue && !this.form.endtime) {
        this.periodList = [
          {
            id: 3,
            text: "自然月",
          },
          {
            id: 2,
            text: "自然季度",
          },
          {
            id: 4,
            text: "半个自然年",
          },
          {
            id: 1,
            text: "自然年",
          },
        ];
        this.disabled = false;
        this.form.periodId = undefined;
      }
    },
    "form.endtime"(newValue) {
      if (newValue && this.form.starttime) {
        let n = this.calculateMonthDifference(this.form.starttime, newValue);
        this.handlePeriodList(n);
      } else if (!newValue && !this.form.starttime) {
        this.periodList = [
          {
            id: 3,
            text: "自然月",
          },
          {
            id: 2,
            text: "自然季度",
          },
          {
            id: 4,
            text: "半个自然年",
          },
          {
            id: 1,
            text: "自然年",
          },
        ];
        this.disabled = false;
        this.form.periodId = undefined;
      }
    },
  },
  created() {
    this.getReport();
  },
};
</script>

<style scoped lang="scss">
.WorkReport {
  .report-header {
    background: #ffffff;
    border-radius: 16px;
    padding: 24px 0 30px 24px;

    .report-header-bottom {
      display: flex;
      align-items: center;
      padding: 24px 50px 0 0;
      .report-header-bottom-left {
        margin-right: 50px;

        .ant-form::v-deep {
          display: flex;
          align-items: center;

          .ant-row {
            margin-right: 50px;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            &.date {
              margin-right: 90px;
            }

            .ant-input,
            .ant-select,
            .ant-calendar-picker {
              width: 150px;
            }

            .ant-calendar-picker:hover {
              .ant-input {
                border-color: #83ba7b !important;
              }
            }
          }
        }
      }
      .report-header-bottom-right {
        width: 72px;
        height: 32px;
        background: #83ba7b;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .report-main {
    display: flex;
    margin-top: 20px;
    .report-main-left {
      .serve,
      .rains {
        padding: 20px;
        background: #fff;
        box-sizing: border-box;
        border-radius: 16px;
      }
    }
    .report-main-right {
      margin-left: 20px;
      flex: 1;

      .rains-ratio,
      .score-ratio {
        padding: 20px;
        background: #fff;
        box-sizing: border-box;
        border-radius: 16px;
        .chart-box {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .rains,
    .score-ratio {
      margin-top: 20px;
    }
  }
}
</style>
