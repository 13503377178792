<template>
  <div class="Completeness">
    <div class="completeness-header">
      <div class="title">
        <div class="oblong"></div>
        <div>人员任务完成度汇总表</div>
      </div>
    </div>
    <div class="completeness-main">
      <a-table
        :columns="columns"
        :data-source="completenessList"
        :rowKey="(record) => record.id"
        :pagination="pagination"
        :loading="loading"
      >
        <!-- 性别筛选 -->
        <span slot="customSex">
          <a-select v-model="gender" @change="genderChange">
            <a-select-option value=""> 全部 </a-select-option>
            <a-select-option value="1"> 男 </a-select-option>
            <a-select-option value="2"> 女 </a-select-option>
          </a-select>
        </span>
        <!-- 职位筛选 -->
        <span slot="customJob">
          <a-select v-model="job" @change="jobChange">
            <a-select-option value=""> 全部 </a-select-option>
            <a-select-option value="1"> 管理员 </a-select-option>
            <a-select-option value="2"> 客服 </a-select-option>
            <a-select-option value="3"> 大区经理/工程服务经理 </a-select-option>
            <a-select-option value="4"> 区域经理/服务工程师 </a-select-option>
          </a-select>
        </span>
        <!-- 领导筛选 -->
        <span slot="customLeader">
          <a-select v-model="leader" @change="leaderChange">
            <a-select-option
              :value="item.id"
              v-for="(item, index) in leaderList"
              :key="index"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </span>
        <!-- 区域筛选 -->
        <!-- <span slot="customArea">
          <a-select v-model="area" @change="areaChange">
            <a-select-option
              :value="item.id"
              v-for="(item, index) in areaList"
              :key="index"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </span> -->
        <!-- 上月完成度 -->
        <span slot="lastCompleteness" slot-scope="item" class="completeness">
          <span
            :class="item.lastCompleteness >= 0.85 ? 'pass' : ''"
            @click="
              toCompletenessDetail(item.id, item.lastCompleteness, 'last')
            "
            >{{
              item.lastCompleteness.toString()
                ? (item.lastCompleteness * 100).toFixed(0) + "%"
                : "/"
            }}</span
          >
        </span>
        <!-- 本月完成度 -->
        <span slot="completeness" slot-scope="item" class="completeness">
          <span
            :class="item.completeness >= 0.85 ? 'pass' : ''"
            @click="toCompletenessDetail(item.id, item.completeness)"
            >{{
              item.completeness.toString()
                ? (item.completeness * 100).toFixed(0) + "%"
                : "/"
            }}</span
          >
        </span>
        <span slot="manager" slot-scope="manager">
          <span>{{ manager ? manager.name : "/" }}</span>
        </span>
        <span slot="region" slot-scope="region">
          <span>{{ region ? region : "/" }}</span>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import { getCompleteness } from "../common/api/statistics";
import { getUser, getArea } from "../common/api/user";

const columns = [
  {
    title: "上月完成度",
    // dataIndex: "lastCompleteness",
    key: "lastCompleteness",
    scopedSlots: { customRender: "lastCompleteness" },
    align: "center",
  },
  {
    title: "本月完成度",
    // dataIndex: "completeness",
    key: "completeness",
    scopedSlots: { customRender: "completeness" },
    align: "center",
  },
  {
    title: "姓名",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "电话",
    key: "mobile",
    dataIndex: "mobile",
    align: "center",
  },
  {
    key: "gender",
    dataIndex: "gender",
    slots: { title: "customSex" },
    align: "center",
  },
  {
    key: "mark",
    dataIndex: "mark",
    slots: { title: "customJob" },
    align: "center",
  },
  {
    key: "manager",
    dataIndex: "manager",
    slots: { title: "customLeader" },
    scopedSlots: { customRender: "manager" },
    align: "center",
  },
  // {
  //   key: "regionName",
  //   dataIndex: "regionName",
  //   slots: { title: "customArea" },
  //   scopedSlots: { customRender: "region" },
  //   align: "center",
  // },
];
export default {
  name: "Completeness",
  props: {},
  components: {},
  data() {
    return {
      loading: false,
      gender: "性别筛选",
      job: "职位筛选",
      leader: "领导筛选",
      area: "区域筛选",
      // 获取人员任务完成度请求参数
      params: {
        page: 0,
        size: 10,
        genderId: "",
        roleId: "",
      },
      columns,
      completenessList: [],
      // 分页配置对象
      pagination: {
        position: "bottom",
        pageSize: 10,
        current: 1,
        total: 0,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["5", "10", "20"], // 每页数量选项
        showQuickJumper: true,
        // 显示总数
        showTotal: (total) => `共 ${total} 条`,
        // 改变每页数量时更新显示
        onShowSizeChange: (page, pageSize) => this.onSizeChange(page, pageSize),
        // 点击页码事件
        onChange: (page, pageSize) => this.onPageChange(page, pageSize),
      },
      // 领导列表
      leaderList: [],
      // 区域列表
      areaList: [],
    };
  },
  methods: {
    // 查看完成度详情
    toCompletenessDetail(id, completeness, month = "current") {
      console.log(month);
      console.log("completeness:", completeness);
      if (month == "last") {
        this.$router.push({
          path: "/currenttasks",
          query: {
            id,
            completeness,
            month,
          },
        });
      } else {
        this.$router.push({
          path: "/currenttasks",
          query: {
            id,
            completeness,
          },
        });
      }
    },
    // 性别筛选
    genderChange(e) {
      if (e == "") {
        this.gender = "性别筛选";
      }
      this.params.genderId = e;

      this.pagination.current = 1;
      this.params.page = 0;
      this.getCompletenessList();
    },
    // 职位筛选
    jobChange(e) {
      if (e == "") {
        this.job = "职位筛选";
      }
      this.params.roleId = e;
      this.pagination.current = 1;
      this.params.page = 0;
      this.getCompletenessList();
    },
    // 领导筛选
    leaderChange(e) {
      if (e == "") {
        this.leader = "领导筛选";
      }

      this.params.sid = e;

      this.pagination.current = 1;
      this.params.page = 0;
      this.getCompletenessList();
    },
    //  区域筛选
    // areaChange(e) {
    //   if (e == "") {
    //     this.area = "区域筛选";
    //   }
    //   this.params.regionId = e;
    //   this.pagination.current = 1;
    //   this.params.page = 0;
    //   this.getCompletenessList();
    // },
    // 获取直属领导列表
    async getLeaderList() {
      const params = {
        page: 0,
        size: 999,
        roleId: 3,
      };
      this.loading = true;
      const res = await getUser(params);
      this.loading = false;

      this.leaderList = [
        {
          name: "全部",
          id: "",
        },
        ...res.data.content,
      ];
    },
    // 获取门店所在区域
    async getAreaList() {
      const res = await getArea();
      this.areaList = [
        {
          name: "全部",
          id: "",
        },
        ...res.data,
      ];
    },
    // 获取人员任务完成度
    async getCompletenessList() {
      const res = await getCompleteness(this.params);
      console.log(res);
      res.data.content.forEach((i) => {
        const min = Math.min.apply(
          Math,
          i.roles.map((v) => {
            return v.id;
          })
        );
        if (i.roles.length > 0) {
          const obj = i.roles.find((i) => {
            return min == i.id;
          });
          i.mark = obj.mark;
        } else {
          i.mark = "/";
        }
      });
      this.completenessList = res.data.content;
      this.pagination.total = res.data.totalElements;
    },
    // 改变每页数量时更新显示
    onSizeChange(page, pageSize) {
      console.log("每页数量:", pageSize);
      this.pagination.current = 1;
      this.pagination.pageSize = pageSize;
      this.params.page = 0;
      this.params.size = pageSize;
      this.getCompletenessList();
    },
    // 点击页码事件
    onPageChange(page) {
      console.log("页码值:", page);
      this.pagination.current = page;
      this.params.page = page - 1;
      this.getCompletenessList();
    },
  },
  created() {
    this.getCompletenessList();
    this.getLeaderList();
    // this.getAreaList();
  },
  activated() {
    console.log("走到这里了");
    let index = this.$store.state.refreshPath.findIndex((v) => {
      return v == "completeness";
    });
    console.log(index);
    if (index != -1) {
      console.log("触发了'activated'");
      this.getCompletenessList();
      this.getLeaderList();
      this.$store.commit("delRefreshPath", "completeness");
    }
  },
};
</script>

<style scoped lang="scss">
.Completeness {
  border-radius: 16px;
  background-color: #fff;
  padding: 24px;
  box-sizing: border-box;
  .completeness-header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .title {
      display: flex;
      align-items: center;
      font-weight: 500;
      color: #303030;
      .oblong {
        margin-right: 8px;
        width: 4px;
        height: 24px;
        background: #83ba7b;
      }
    }
  }
  .completeness-main {
    background-color: #fff;
    .completeness {
      color: #e93024;
      cursor: pointer;
      .pass {
        color: #1eb040;
      }
    }
  }
}
</style>
