<template>
  <div class="QuestionGroup">
    <div class="question-group-header">
      <div class="question-group-header-top">
        <div class="title">
          <div class="oblong"></div>
          <div>问题组列表</div>
        </div>
        <div class="btn" @click="createQuestionGroup">新增组名</div>
      </div>
      <!-- <div class="tag-header-bottom">
          <div class="tag-header-bottom-left">
            <a-form-model
              :model="form"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
              :colon="false"
            >
              <a-form-model-item label="">
                <a-input
                  v-model.trim="form.keyword"
                  placeholder="请输入问题关键词"
                  @pressEnter="find"
                />
              </a-form-model-item>
            </a-form-model>
          </div>
          <div class="tag-header-bottom-right" @click="find">查询</div>
        </div> -->
    </div>
    <div class="question-group-main">
      <a-table
        :columns="columns"
        :data-source="questionGroupList"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :pagination="pagination"
        :childrenColumnName="childrenColumnName"
        :loading="loading"
      >
        <template slot="name" slot-scope="text, record, i">
          <a-input
            placeholder="请输入"
            v-if="i === index && flag"
            v-model.trim="record.name"
            :maxLength="10"
            ref="inputRef"
          ></a-input>

          <span v-else class="name">{{ record.name }}</span>
        </template>

        <template slot="crux" slot-scope="text, record, i">
          <a-radio-group
            v-model="record.crux"
            :default-value="false"
            v-if="i === index && flag"
          >
            <a-radio :value="true"> 是 </a-radio>
            <a-radio :value="false"> 否 </a-radio>
          </a-radio-group>

          <span v-else class="name">{{ record.crux ? "是" : "否" }}</span>
        </template>

        <div slot="action" slot-scope="text, record, i" class="action">
          <template v-if="i === index && flag">
            <div class="save" @click="save(text)">保存</div>
            <div class="cancel" @click="cancel">取消</div>
          </template>
          <template v-else>
            <div class="edit" @click="editQuestionGroup(record, i)">修改</div>
            <div class="del" @click="delTag(record)">删除</div>
          </template>
        </div>
      </a-table>
    </div>
    <div class="handle-modal" ref="handleRefModel">
      <a-modal
        v-model="visible"
        title="操作确认"
        :maskClosable="false"
        centered
        width="395px"
        :getContainer="() => $refs.handleRefModel"
        @ok="handleOk"
        @cancel="handleCancel"
        cancelText="否"
        okText="是"
      >
        <div>此组包含的问题不会被删除，</div>
        <div>但会归类至“未分组问题”中。</div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import {
  addQuestionGroup,
  getQuestionGroup,
  editQuestionGroup,
  delQuestionGroup,
} from "../common/api/questions";
import { debounce } from "lodash";
import MyModal from "../components/MyModal.vue";
const columns = [
  {
    title: "问题组ID",
    dataIndex: "id",
    key: "id",
    align: "center",
  },
  {
    title: "问题组名称",
    dataIndex: "name",
    width: 600,
    key: "name",
    align: "center",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "超级关键项",
    dataIndex: "crux",
    align: "center",
    key: "crux",
    scopedSlots: { customRender: "crux" },
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: "center",
  },
];
export default {
  name: "Tag",
  props: {},
  components: {
    MyModal,
  },
  data() {
    return {
      loading: false,
      childrenColumnName: "",
      current: {},
      text: "",
      visible: false,
      index: null,
      columns,
      questionGroupList: [],
      flag: false,
      form: {
        page: 0,
        size: 10,
      },
      // 分页配置对象
      pagination: {
        position: "bottom",
        pageSize: 10,
        current: 1,
        total: 0,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["5", "10", "20"], // 每页数量选项
        showQuickJumper: true,
        // 显示总数
        showTotal: (total) => `共 ${total} 条`,
        // 改变每页数量时更新显示
        onShowSizeChange: (page, pageSize) => this.onSizeChange(page, pageSize),
        // 点击页码事件
        onChange: (page, pageSize) => this.onPageChange(page, pageSize),
      },
    };
  },
  methods: {
    // 选中项发生变化时的回调

    async handleOk() {
      const { id } = this.current;
      if (this.text === "删除") {
        const res = await delQuestionGroup({ id });
        console.log(res);

        this.$message.success(res.msg);
        if (this.questionGroupList.length === 1 && this.form.page !== 0) {
          this.form.page--;
        }
        this.$store.commit("updateRefreshPath", "question");
      }

      this.visible = false;
      this.getQuestionGroupList();
    },
    handleCancel() {
      this.current = {};
      this.visible = false;
    },
    // 点击页码事件
    onPageChange(page) {
      if (this.flag) {
        this.questionGroupList.splice(0, 1);
        this.flag = false;
      }
      this.pagination.current = page;
      this.form.page = page - 1;
      this.getQuestionGroupList();
    },
    // 改变每页数量时更新显示
    onSizeChange(page, pageSize) {
      console.log("每页数量:", pageSize);
      this.pagination.current = 1;
      this.pagination.pageSize = pageSize;
      this.form.page = 0;
      this.form.size = pageSize;
      this.getQuestionGroupList();
    },
    // 获取问题组列表
    async getQuestionGroupList() {
      this.loading = true;
      const res = await getQuestionGroup(this.form);
      this.loading = false;
      console.log(res);
      this.questionGroupList = res.data.content;
      this.pagination.total = res.data.totalElements;
    },
    save: debounce(async function (params) {
      if (!params.name) return this.$message.info("请输入问题组名称");

      params.enable = true;
      console.log(params);
      if (params.id === "/") {
        delete params.id;
        console.log(params);
        const res = await addQuestionGroup(params);
        this.$message.success(res.msg);
        this.$store.commit("updateRefreshPath", "question");
      } else {
        const obj = {};
        obj.id = params.id;
        obj.name = params.name;
        obj.crux = params.crux;
        obj.enable = params.enable;
        console.log(obj);
        const res = await editQuestionGroup(obj);
        console.log(res);
        this.$message.success(res.msg);
      }

      this.flag = false;
      this.getQuestionGroupList();
    }, 500),

    cancel() {
      this.flag = false;
      this.index = null;
      this.questionGroupList.splice(0, 1);
    },

    async createQuestionGroup() {
      this.form.page = 0;
      this.pagination.current = 1;
      await this.getQuestionGroupList();
      this.questionGroupList.unshift({ name: "", id: "/", crux: false });
      this.index = 0;
      this.flag = true;
      setTimeout(() => {
        this.$refs.inputRef.focus();
      }, 0);
    },
    editQuestionGroup(record, i) {
      console.log(record, i);
      this.index = i;
      this.flag = true;
    },
    async delTag(record) {
      this.text = "删除";
      this.current = record;
      this.visible = true;
    },
  },
  created() {
    this.getQuestionGroupList();
  },
};
</script>

<style scoped lang="scss">
.QuestionGroup {
  border-radius: 16px;
  background-color: #fff;
  padding: 24px;
  box-sizing: border-box;
  .question-group-header {
    .question-group-header-top {
      display: flex;
      align-items: center;
      .title {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #303030;
        .oblong {
          margin-right: 8px;
          width: 4px;
          height: 24px;
          background: #83ba7b;
        }
      }
      .btn {
        margin: 0 16px 0 30px;
        padding: 0 16px;
        height: 32px;
        background: #83ba7b;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .question-group-main {
    margin-top: 20px;
    .ant-table-wrapper::v-deep {
      .ant-table-tbody {
        tr {
          td {
            padding: 9px 16px;
          }
        }
      }
    }

    .ant-input {
      text-align: center;
      width: 344px;
    }
    .name {
      line-height: 32px;
    }
    .action {
      display: flex;
      justify-content: center;

      div {
        width: 64px;
        height: 24px;
        line-height: 22px;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid #60b538;
        color: #fff;
        cursor: pointer;
        &:nth-child(n + 2) {
          margin-left: 10px;
        }
        &.save {
          background: #60b538;
        }
        &.cancel {
          border: 1px solid #bfc1c6;
          background: #fff;
          color: #bfc1c6;
        }

        &.edit {
          border-color: #3298f1;
          color: #3298f1;
        }
        &.del {
          border-color: #dc3333;
          color: #dc3333;
        }
      }
    }
  }
  .handle-modal::v-deep {
    .ant-modal-header {
      .ant-modal-title {
        text-align: center !important;
      }
    }
    .ant-modal-body {
      text-align: center;
      span {
        color: #ec4e44;
      }
    }
    .ant-modal-footer {
      text-align: center;
      padding-bottom: 44px;
      border: 0;
      div button {
        width: 72px;
        height: 32px;
        &:nth-child(2) {
          margin-left: 80px;
          background: #83ba7b;
          border: 0;
        }
      }
    }
  }
}
</style>
