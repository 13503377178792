import $http from '../http'

// 获取集团列表
export const getGroup = data => {

    return $http.get(`/api/v1/admin/groups`, data)
}

// 获取集团详情
export const getGroupDetails = data => {

    return $http.get(`/api/v1/admin/groups/${data.id}`)
}

// 新增集团
export const createGroup = data => {

    return $http.post(`/api/v1/admin/groups`, data)
}

// 编辑集团
export const editGroup = data => {

    return $http.put(`/api/v1/admin/groups/${data.id}`, data)
}

// 删除集团
export const delGroup = data => {

    return $http.del(`/api/v1/admin/groups/${data.id}`)
}

// 编辑品牌
export const editBrand = data => {

    return $http.put(`/api/v1/admin/brands/${data.id}`, data)
}

// 删除品牌
export const delBrand = data => {

    return $http.del(`/api/v1/admin/brands/${data.id}`)
}

// 新建品牌
export const createBrand = data => {

    return $http.post(`/api/v1/admin/brands`, data)
}

// 获取品牌
export const getBrand = data => {
    return $http.get(`/api/v1/admin/brands`, data)
}


// 未拜访门店列表
export const getUnvisit = data => {
    return $http.get(`/api/v1/admin/groups/${data.id}/unvisit`, data)
}

