<template>
  <div class="Group">
    <div class="group-header">
      <div class="group-header-top">
        <module-title text="集团列表"></module-title>
        <div class="create" @click="createGroup">新增集团客户</div>
      </div>
      <div class="group-header-bottom">
        <div class="group-header-bottom-left">
          <a-form-model
            :model="form"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :colon="false"
          >
            <a-form-model-item>
              <a-input
                v-model.trim="form.name"
                placeholder="请输入集团名称"
                @pressEnter="search"
              />
            </a-form-model-item>
          </a-form-model>
          <div @click="search" class="search">查询</div>
        </div>
      </div>
    </div>
    <div class="group-main">
      <a-table
        :columns="columns"
        :pagination="pagination"
        :loading="loading"
        :data-source="groupList"
        :rowKey="(record) => record.id"
      >
        <span slot="storeCount" slot-scope="storeCount">
          {{ storeCount ? storeCount : "/" }}
        </span>
        <span slot="latestCompleteness" slot-scope="latestCompleteness">{{
          latestCompleteness || latestCompleteness === 0
            ? latestCompleteness + "%"
            : "/"
        }}</span>

        <div slot="action" slot-scope="text, record, index" class="handle">
          <div @click="lookGroup(record)">查看</div>
          <div @click="editGroup(record, index)">编辑</div>
          <!-- <div @click="delGroup(record.id)">删除</div> -->
        </div>
      </a-table>
    </div>
    <EditGroupModal
      ref="editGroupModal"
      :showIcon="showIcon"
      :visible="editGroupVisible"
      :title="editGroupTitle"
      :editGroupForm="groupInfo"
      @ok="editGroupOk"
      @cancel="editGroupCancel"
    />
    <div ref="handleRefModel" class="handle-modal">
      <a-modal
        v-model="delVisible"
        title="操作确认"
        @ok="handleOk"
        :maskClosable="false"
        centered
        width="395px"
        :getContainer="() => $refs.handleRefModel"
        cancelText="否"
        okText="是"
      >
        <div>确认删除所选集团？</div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import {
  getGroup,
  createGroup,
  getGroupDetails,
  editGroup,
  delGroup,
} from "../common/api/group";
import ModuleTitle from "../components/ModuleTitle";
import EditGroupModal from "../components/EditGroupModal.vue";

export default {
  name: "Group",
  components: {
    ModuleTitle,
    EditGroupModal,
  },
  data() {
    return {
      id: "",
      delVisible: false,
      showIcon: true,
      loading: false,
      groupList: [],
      // 分页配置对象
      pagination: {
        position: "bottom",
        pageSize: 10,
        current: 1,
        total: 0,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["5", "10", "20"], // 每页数量选项
        showQuickJumper: true,
        // 显示总数
        showTotal: (total) => `共 ${total} 条`,
        // 改变每页数量时更新显示
        onShowSizeChange: (page, pageSize) => this.onSizeChange(page, pageSize),
        // 点击页码事件
        onChange: (page, pageSize) => this.onPageChange(page, pageSize),
      },
      groupInfo: {},
      editGroupVisible: false,
      editGroupTitle: "",

      form: {
        page: 0,
        size: 10,
        name: "",
      },
      labelCol: { span: 8 },
      wrapperCol: { span: 15, offset: 1 },
      columns: [
        {
          title: "集团名称",
          align: "center",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "集团经理",
          align: "center",
          dataIndex: "contact",
          key: "contact",
        },
        {
          title: "门店数量",
          align: "center",
          dataIndex: "storeCount",
          key: "storeCount",
          scopedSlots: { customRender: "storeCount" },
        },
        {
          title: "服务目标数",
          align: "center",
          dataIndex: "number",
          key: "number",
        },
        {
          title: "当前服务完成率",
          align: "center",
          scopedSlots: { customRender: "latestCompleteness" },
          dataIndex: "latestCompleteness",
          key: "latestCompleteness",
        },
        {
          title: "操作",
          align: "center",
          key: "操作",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  methods: {
    async getGroupDetails(id) {
      const res = await getGroupDetails({ id });
      console.log(res);
      this.groupInfo = res.data;
    },
    // 确认删除
    async handleOk() {
      const res = await delGroup({ id: this.id });
      console.log(res, "1111");
    },
    // 查询
    search() {
      this.form.page = 0;
      this.pagination.current = 1;
      this.getGroupList();
    },
    // 改变每页数量时更新显示
    onSizeChange(page, pageSize) {
      console.log("每页数量:", pageSize);
      this.pagination.current = 1;
      this.pagination.pageSize = pageSize;
      this.form.page = 0;
      this.form.size = pageSize;
      this.getGroupList();
    },
    // 点击页码事件
    onPageChange(page) {
      console.log("页码值:", page);
      this.pagination.current = page;
      this.form.page = page - 1;
      this.getGroupList();
    },
    async getGroupList() {
      this.loading = true;
      const res = await getGroup(this.form);
      this.loading = false;
      console.log(res);
      this.groupList = res.data.content;
      this.pagination.total = res.data.totalElements;
    },
    async editGroupOk({ title, params }) {
      console.log(params, "params");
      if (title == "新增") {
        let brandLst = [];
        params.brandsSet.forEach((v) => {
          brandLst.push(v.name);
        });
        const { contact, name, number, periodId } = params;
        const res = await createGroup({
          contact,
          name,
          number,
          periodId,
          brandLst,
        });
        console.log(res);
        this.$message.success(res.msg);
        this.$refs.editGroupModal.activeIndex = null;
        this.form.name = "";
        this.form.page = 0;
        this.pagination.current = 1;
        this.getGroupList();
      } else {
        const { contact, name, number, periodId, id } = params;
        const res = await editGroup({ contact, name, number, periodId, id });
        console.log(res);
        this.$message.success(res.msg);
        this.getGroupList();
      }
      this.editGroupVisible = false;
      this.$store.commit("updateRefreshPath", "client");
    },
    editGroupCancel() {
      this.editGroupVisible = false;
      this.$refs.editGroupModal.activeIndex = null;
    },
    // 选择门店集团
    groupChange(e) {
      if (e == "不限") {
        this.form.name = "";
      } else {
        this.form.name = e;
      }
    },

    createGroup() {
      this.editGroupTitle = "新增";
      this.groupInfo = {
        brandsSet: [],
      };
      this.editGroupVisible = true;
    },
    // 查看
    lookGroup(info) {
      this.editGroupTitle = "";
      this.showIcon = false;
      this.getGroupDetails(info.id);
      this.editGroupVisible = true;
    },
    editGroup(info, index) {
      this.editGroupTitle = "编辑";
      this.showIcon = true;
      this.getGroupDetails(info.id);
      this.editGroupVisible = true;
    },
    delGroup(id) {
      console.log(id);
      this.id = id;
      this.delVisible = true;
    },
  },

  created() {
    this.getGroupList();
  },
};
</script>

<style scoped lang="scss">
.Group {
  border-radius: 16px;
  background-color: #fff;
  padding: 24px;
  box-sizing: border-box;
  .group-header {
    .group-header-top {
      display: flex;
      align-items: center;
      .create {
        margin: 0 16px 0 30px;
        padding: 0 16px;
        height: 32px;
        background: #83ba7b;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        color: #fff;
        cursor: pointer;
      }
    }
    .group-header-bottom {
      margin: 24px 0;
      display: flex;
      justify-content: space-between;
      padding-right: 100px;
      box-sizing: border-box;
      .group-header-bottom-left {
        display: flex;
        align-items: center;

        .ant-form {
          display: flex;
          align-items: center;

          .ant-row {
            margin-right: 25px;
            margin-bottom: 0;
            display: flex;
            align-items: center;

            .ant-input,
            .ant-select {
              width: 240px;
              height: 30px;
            }
          }
        }
        .search {
          width: 72px;
          height: 32px;
          background: #83ba7b;
          border-radius: 4px;
          text-align: center;
          line-height: 32px;
          color: #fff;
          cursor: pointer;
        }
      }
      .ques-header-bottom-right {
        display: flex;
        align-items: center;
        .del {
          margin-left: 16px;
          width: 72px;
          height: 32px;
          border-radius: 4px;
          text-align: center;
          line-height: 32px;
          font-size: 14px;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
  .group-main {
    .handle {
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        width: 64px;
        height: 24px;
        border: 1px solid #3298f1;
        color: #3298f1;
        font-size: 14px;
        box-sizing: border-box;
        border-radius: 4px;
        cursor: pointer;
        margin-right: 16px;
        &:nth-child(1) {
          color: #83ba7b;
          border-color: #83ba7b;
        }
        &:nth-child(3) {
          margin-right: 0;
          color: #dc3333;
          border-color: #dc3333;
        }
      }
    }
  }
  .handle-modal::v-deep {
    .ant-modal-header {
      .ant-modal-title {
        text-align: center !important;
      }
    }
    .ant-modal-body {
      text-align: center;
      span {
        color: #ec4e44;
      }
    }
    .ant-modal-footer {
      text-align: center;
      padding-bottom: 44px;
      border: 0;
      div button {
        width: 72px;
        height: 32px;
        &:nth-child(2) {
          margin-left: 80px;
          background: #83ba7b;
          border: 0;
        }
      }
    }
  }
}
</style>
