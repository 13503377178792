<template>
  <div class="OrderDetails">
    <div class="order-details-header">
      <div class="order-details-header-top">
        <div class="order-details-title">
          <div class="oblong"></div>
          <div>客户基本信息</div>
        </div>
      </div>
      <div class="order-details-header-bottom">
        <div class="order-info">
          <a-form-model
            :label-col="{ span: 12 }"
            :wrapper-col="{ span: 11, offset: 1 }"
            :colon="false"
          >
            <a-form-model-item
              label="客户ID"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              {{ storeDetails.id || "/" }}
            </a-form-model-item>

            <a-form-model-item label="已指派区域经理/服务工程师">
              <span>{{
                storeDetails.saleName ? storeDetails.saleName : "/"
              }}</span>
            </a-form-model-item>

            <a-form-model-item label="所属集团">
              <span>{{
                storeDetails.groupName ? storeDetails.groupName : "/"
              }}</span>
            </a-form-model-item>

            <a-form-model-item
              label="客户名称"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <span>{{ storeDetails.name || "/" }}</span>
            </a-form-model-item>

            <a-form-model-item label="大区经理/工程服务经理">
              <span>{{
                storeDetails.regionManager ? storeDetails.regionManager : "/"
              }}</span>
            </a-form-model-item>

            <a-form-model-item label="集团经理">
              <span>{{
                storeDetails.groupManager ? storeDetails.groupManager : "/"
              }}</span>
            </a-form-model-item>
            <a-form-model-item
              label="详细地址"
              class="hide"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <span :title="storeDetails.address ? storeDetails.address : ''">{{
                storeDetails.address ? storeDetails.address : "/"
              }}</span>
            </a-form-model-item>
            <!-- <a-form-model-item label="所属区域">
              <span>{{ storeDetails.region ? storeDetails.region : "/" }}</span>
            </a-form-model-item> -->

            <a-form-model-item label="上次拜访">
              <span>{{
                storeDetails.lastVistDate ? storeDetails.lastVistDate : "/"
              }}</span>
            </a-form-model-item>
            <a-form-model-item label="拜访间隔(天)">
              <span>{{ storeDetails.period ? storeDetails.period : "/" }}</span>
            </a-form-model-item>
            <a-form-model-item
              label="下次拜访截止日"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <span>{{
                storeDetails.nxtDealDate ? storeDetails.nxtDealDate : "/"
              }}</span>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="order-details-main">
      <div class="order-details-main-top">
        <div class="order-details-title">
          <div class="oblong"></div>
          <div>服务工单详情</div>
        </div>
        <div class="order-details-btn">
          <div class="edit common" v-show="flag" @click="editOrderInfo">
            编辑
          </div>
          <div class="btn" v-show="!flag">
            <div class="save common" @click="save">保存</div>
            <div class="cancel common" @click="cancel">取消</div>
          </div>
          <div class="finish common" @click="finish">完成</div>
          <div class="close common" @click="close">关闭</div>
        </div>
      </div>
      <div class="order-details-main-bottom">
        <a-form-model
          :model="form"
          :label-col="labelCol1"
          :wrapper-col="wrapperCol1"
          ref="ruleForm"
          :colon="false"
          :rules="rules"
        >
          <a-form-model-item
            label="工单ID"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            {{ orderDetails.code }}
          </a-form-model-item>
          <a-form-model-item label="工单来源">
            <span>
              {{ orderDetails.createBy ? orderDetails.createBy : "/" }}
            </span>
          </a-form-model-item>

          <a-form-model-item
            label="已指派区域经理/服务工程师"
            :label-col="labelCol1"
            :wrapper-col="wrapperCol1"
          >
            <span v-if="flag || isEdit">
              {{ orderDetails.assignedUser ? orderDetails.assignedUser : "/" }}
            </span>
            <a-select
              v-else
              placeholder="请选择区域经理/服务工程师"
              :value="form.uid"
              @change="uidChange"
            >
              <a-select-option
                :value="item.id"
                v-for="(item, index) in allSaleList"
                :key="index"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="工单状态">
            <span v-show="flag">{{
              orderDetails.status ? orderDetails.status : "/"
            }}</span>
            <a-select
              v-show="!flag"
              :value="form.statusId"
              @change="statusChange"
              placeholder="请选择工单状态"
            >
              <a-select-option
                :value="item.id"
                v-for="(item, index) in orderStatus"
                :key="index"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="客户联系人"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <span v-show="flag">{{
              orderDetails.contacts ? orderDetails.contacts : "/"
            }}</span>
            <a-input
              v-model="form.contacts"
              placeholder="请输入联系人"
              v-show="!flag"
            />
          </a-form-model-item>
          <a-form-model-item label="联系人电话" prop="mobile" class="mobile">
            <a-input
              v-model="form.mobile"
              placeholder="请输入电话"
              :maxLength="11"
              :disabled="flag"
            />
          </a-form-model-item>
          <a-form-model-item label="服务地址" class="hide">
            <span :title="orderDetails.address ? orderDetails.address : ''">{{
              orderDetails.address ? orderDetails.address : "/"
            }}</span>
          </a-form-model-item>
          <a-form-model-item label="工单类别" prop="typeId">
            <span v-show="flag">{{
              orderDetails.type ? orderDetails.type : "/"
            }}</span>
            <a-select
              v-show="!flag"
              v-model="form.typeId"
              placeholder="请选择工单类别"
            >
              <a-select-option
                :value="item.id"
                v-for="(item, index) in orderType"
                :key="index"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="创建时间"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <span>{{
              orderDetails.createTime ? orderDetails.createTime : "/"
            }}</span>
          </a-form-model-item>
          <a-form-model-item label="时限(天)">
            <span v-show="flag">{{
              orderDetails.gapdate ? orderDetails.gapdate + " 天" : "/"
            }}</span>
            <span v-show="!flag"
              ><a-input
                class="num"
                @change="timeChange"
                :value="gapdate"
              />天</span
            >
          </a-form-model-item>
          <a-form-model-item label="完成截止日">
            <span v-show="flag">{{
              orderDetails.dealLine ? orderDetails.dealLine : "/"
            }}</span>
            <a-date-picker
              v-show="!flag"
              @change="dateChange"
              :value="form.nextVistDay"
            />
          </a-form-model-item>
          <a-form-model-item
            label="服务要求概述"
            :label-col="otherLabelCol"
            :wrapper-col="otherWrapperCol"
            class="other"
            prop="subject"
          >
            <a-input
              v-model.trim="form.subject"
              placeholder="服务要求概述"
              :maxLength="50"
              :disabled="flag"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item
            label="服务要求详情"
            :label-col="otherLabelCol"
            :wrapper-col="otherWrapperCol"
            class="other"
          >
            <a-input
              v-model="form.content"
              type="textarea"
              :label-col="otherLabelCol"
              :wrapper-col="otherWrapperCol"
              placeholder="服务要求详情"
              :disabled="flag"
            />
          </a-form-model-item>
          <a-form-model-item
            label="附件图片"
            class="other"
            :label-col="otherLabelCol"
            :wrapper-col="otherWrapperCol"
          >
            <span v-if="fileList.length == 0 && flag">/</span>
            <a-upload
              list-type="picture-card"
              :file-list="fileList"
              :customRequest="uploadFiles"
              :remove="handleDownloadFileRemove"
              :showUploadList="{
                showRemoveIcon: !flag,
              }"
              :multiple="true"
              accept="image/*"
            >
              <div v-if="fileList.length < 9 && !flag">
                <div class="ant-upload-text">
                  <a-progress
                    v-if="isLoading"
                    :percent="percent"
                    size="small"
                  />
                  <img src="../assets/image/add.png" alt="" v-else />
                </div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
    <edit-status-modal
      :visible="editStatusVisible"
      :title="editStatusTitle"
      @ok="editStatusOk"
      @cancel="editStatusCancel"
    ></edit-status-modal>
  </div>
</template>

<script>
import { getOrderInfo, editOrder, editStatus } from "../common/api/order";
import { getUser } from "../common/api/user";
import { getStore } from "../common/api/client";
import $http from "../common/http";
import moment from "moment";
import EditStatusModal from "../components/EditStatusModal.vue";
import { deepClone } from "../common/utils";
export default {
  name: "OrderDetails",

  components: {
    EditStatusModal,
  },

  data() {
    return {
      percent: 0,
      isLoading: false,
      isEdit: false,
      editStatusVisible: false,
      editStatusTitle: "",
      form: {
        uid: undefined,
        statusId: undefined,
        contacts: "",
        mobile: "",
        strAddress: "",
        typeId: undefined,
        nextVistDay: null,
        subject: "",
        content: "",
        urlLists: [],
      },
      // 表单校验规则
      rules: {
        typeId: [
          { required: true, message: "请选择工单类型", trigger: "blur" },
        ],

        mobile: [
          {
            required: false,
            pattern: new RegExp(/^1[3456789]\d{9}$/, "g"),
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        subject: [
          { required: true, message: "请输入服务内容概述", trigger: "blur" },
        ],
      },
      previewVisible: false,
      previewImage: "",
      fileList: [],
      labelCol: { span: 7 },
      wrapperCol: { span: 12, offset: 1 },
      labelCol1: { span: 13 },
      wrapperCol1: { span: 10, offset: 1 },
      otherLabelCol: { span: 2 },
      otherWrapperCol: { span: 17 },
      flag: true,
      // 工单详情id
      id: "",
      // 工单详情
      orderDetails: {},
      // 业务员列表
      staffList: [],
      // 直属领导列表
      leaderList: [],
      allSaleList: [],
      // 客户门店详情
      storeDetails: {},
      // 工单状态
      orderStatus: [
        {
          id: 0,
          name: "未完成",
        },
        {
          id: 1,
          name: "排队中",
        },
        {
          id: 2,
          name: "处理中",
        },
        {
          id: 3,
          name: "已完成",
        },
        {
          id: 4,
          name: "已关闭",
        },
      ],
      // 工单类型
      orderType: [
        {
          id: 1,
          name: "产品咨询",
        },
        {
          id: 2,
          name: "故障报修",
        },
        {
          id: 3,
          name: "巡店计划",
        },
        {
          id: "0",
          name: "其他",
        },
      ],
      // 剩余天数
      gapdate: "",
      copyGapdate: "",
      copyForm: {},
      copyFileList: [],
      // 修改工单状态请求参数
      editStatusParams: {},
    };
  },
  methods: {
    uidChange(e) {
      console.log(e);
      console.log(this.orderDetails.assignedUserId);
      this.form.uid = e;
    },
    statusChange(e) {
      this.form.statusId = e;
      console.log(e);
      if (e == 3 || e == 4) {
        this.isEdit = true;
      } else {
        this.isEdit = false;
      }
    },
    // 确认修改工单状态
    async editStatusOk() {
      this.editStatusParams.ids = this.id;
      const res = await editStatus(this.editStatusParams);
      console.log(res);
      this.$message.success(res.msg);

      this.editStatusVisible = false;

      this.getOrderDetails();
    },
    // 完成
    finish() {
      if (!this.flag) return this.$message.info("请退出编辑状态");
      if (this.form.statusId == 3) return;
      this.editStatusTitle = "完成";
      this.editStatusParams.statusId = 3;
      this.editStatusVisible = true;
    },
    // 关闭
    close() {
      if (!this.flag) return this.$message.info("请退出编辑状态");
      if (this.form.statusId == 4) return;
      this.editStatusTitle = "关闭";
      this.editStatusParams.statusId = 4;
      this.editStatusVisible = true;
    },
    editStatusCancel() {
      this.editStatusVisible = false;
    },
    // 选择时间
    dateChange(date, dateStr) {
      console.log("dateStr", dateStr);
      this.gapdate = "";
      if (dateStr) {
        this.form.nextVistDay = moment(dateStr, "YYYY-MM-DD");
      } else {
        this.form.nextVistDay = null;
      }
    },
    // 输入天数
    timeChange(e) {
      var r = /^\+?[1-9][0-9]*$/;
      let flag = r.test(e.target.value);

      if (flag) {
        this.gapdate = e.target.value;
        let time = this.getLaterDate(this.gapdate);
        this.form.nextVistDay = moment(time, "YYYY-MM-DD");
      } else {
        this.gapdate = "";
      }
    },
    // 根据天数计算时间
    getLaterDate(dayNum, dateTime = null) {
      // 如果为null,则格式化当前时间为时间戳
      if (!dateTime) dateTime = +new Date();
      // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
      if (dateTime.toString().length == 10) dateTime *= 1000;
      const timestamp = +new Date(Number(dateTime));

      const one_day = 86400000; // 24 * 60 * 60 * 1000;
      const addVal = dayNum * one_day + timestamp;
      //x天后的日期
      const date = new Date(addVal);

      //格式化日期
      const filters = (n) => {
        return n < 10 ? (n = "0" + n) : n;
      };
      const month = filters(date.getMonth() + 1);
      const day = filters(date.getDate());
      const hours = filters(date.getHours());
      const minutes = filters(date.getMinutes());
      const seconds = filters(date.getSeconds());

      const lastTime = `${date.getFullYear()}-${month}-${day}`;

      return lastTime;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    // 删除文件
    handleDownloadFileRemove(file) {
      if (this.flag) return;
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    // 自定义上传方法
    uploadFiles(data) {
      if (this.isLoading) return;
      let formData = new FormData();
      formData.append("file", data.file);
      $http.request
        .post("/api/v1/users/imageUpload", formData, {
          onUploadProgress: (progressEvent) => {
            this.isLoading = true;
            console.log(progressEvent);
            // 这里可以获取上传进度信息
            const { loaded, total } = progressEvent;
            console.log(`上传进度: ${Math.round((loaded / total) * 100)}%`);
            this.percent = Math.round((loaded / total) * 100);
          },
        })
        .then((res) => {
          this.isLoading = false;
          let file = this.fileFormatter(res.data);
          if (this.fileList.length >= 9) {
            return this.$message.info("最多上传9张图片");
          } else {
            this.fileList.push(file);
          }
        });
    },
    // 对上传成功返回的数据进行格式化处理，格式化a-upload能显示在已上传列表中的格式
    fileFormatter(data) {
      let file = {
        uid: data.newFileName, // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
        name: data.newFileName, // 文件名
        status: "done", // 状态有：uploading done error removed
        url: `${$http.baseURL}/img${data.path}`,
      };
      return file;
    },
    // 获取客户门店详情
    async getStoreDetails() {
      const res = await getStore({
        id: this.orderDetails.storeId,
      });
      console.log("客户门店详情", res);
      this.storeDetails = res.data;
    },
    // 获取直属领导列表
    async getLeaderList() {
      const params = {
        page: 0,
        size: 999,
        roleId: 3,
      };
      const res = await getUser(params);
      this.leaderList = res.data.content;
      this.handleAllSale(this.staffList, this.leaderList);
    },
    // 获取业务员列表
    async getStaffList() {
      const params = {
        page: 0,
        size: 999,
        roleId: 4,
      };
      const res = await getUser(params);
      // console.log("业务员列表:", res);
      this.staffList = res.data.content;
      this.getLeaderList();
    },
    // 获取所有业务员列表
    handleAllSale(a, b) {
      this.allSaleList = [...a, ...b];
      // 这里对id属性进行去重
      const map = new Map();
      this.allSaleList = this.allSaleList.filter(
        (item) => !map.has(item.id) && map.set(item.id, 1)
      );
    },
    // 编辑客户信息
    editOrderInfo() {
      let copyMonent = null;
      if (this.form.nextVistDay) {
        copyMonent = moment(this.form.nextVistDay);
      }

      this.copyForm = deepClone(this.form);
      this.copyForm.nextVistDay = copyMonent;
      this.copyFileList = [...this.fileList];
      this.copyGapdate = this.gapdate;
      this.flag = false;
    },
    // 保存
    save() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          if (this.form.statusId == 2 && !this.form.uid) {
            return this.$message.info("请指派区域经理/服务工程师");
          } else {
            let params = JSON.parse(JSON.stringify(this.form));
            params.nextVistDay = "";
            if (this.form.nextVistDay) {
              params.nextVistDay = this.form.nextVistDay.format("YYYY-MM-DD");
            } else {
              delete params.nextVistDay;
            }
            console.log(this.form.nextVistDay, "11111");
            this.fileList.forEach((v) => {
              params.urlLists.push(v.url);
            });

            if (
              params.uid &&
              (params.statusId == 0 ||
                params.statusId == 1 ||
                params.statusId == 2)
            ) {
              params.statusId = 2;
            }
            params.id = this.id;
            const res = await editOrder(params);
            this.$message.success("编辑成功");
            this.$store.commit("updateRefreshPath", "order");
            this.getOrderDetails();
            this.flag = true;
          }
        }
      });
    },
    // 取消
    cancel() {
      console.log(this.copyForm);
      this.form = this.copyForm;
      this.gapdate = this.copyGapdate;
      this.fileList = this.copyFileList;
      console.log(this.form, "this.form");
      this.$refs["ruleForm"].clearValidate();
      this.flag = true;
    },
    // 获取工单详情
    async getOrderDetails() {
      this.fileList = [];
      const res = await getOrderInfo({
        id: this.id,
      });

      this.orderDetails = res.data;
      let info = this.orderDetails;
      if (info.assignedUserId) {
        this.form.uid = info.assignedUserId;
      }

      if (info.status) {
        this.orderStatus.forEach((v) => {
          if (v.name == info.status) {
            this.form.statusId = v.id;
            console.log(
              this.form.statusId == 3 || this.form.statusId == 4,
              2222
            );
            if (this.form.statusId == 3 || this.form.statusId == 4) {
              this.isEdit = true;
            } else {
              this.isEdit = false;
            }
          }
        });
      }
      this.form.contacts = info.contacts;
      if (info.mobile) {
        this.form.mobile = info.mobile;
      } else {
        this.form.mobile = "/";
      }

      this.form.strAddress = info.address;
      this.form.subject = info.subject;
      this.form.content = info.content;
      if (info.type) {
        this.orderType.forEach((v) => {
          if (v.name == info.type) {
            this.form.typeId = v.id;
          }
        });
      }
      if (info.dealLine) {
        this.form.nextVistDay = moment(info.dealLine, "YYYY-MM-DD");
      }
      this.gapdate = info.gapdate;
      this.orderDetails.urlLists.forEach((v) => {
        let file = {
          uid: v.id, // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
          name: v.id.toString(), // 文件名
          status: "done", // 状态有：uploading done error removed
          url: v.url,
        };
        this.fileList.push(file);
      });
    },
  },
  async created() {
    this.id = this.$route.query.id;
    await this.getOrderDetails();
    this.getStaffList();
    if (this.orderDetails.storeId) {
      this.getStoreDetails();
    }
  },
  watch: {
    flag(val) {
      console.log(val);
      console.log(this.form.mobile == "");
      if (this.form.mobile == "/" && !val) {
        this.form.mobile = "";
      } else if (this.form.mobile == "" && val) {
        this.form.mobile = "/";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.OrderDetails {
  box-sizing: border-box;
  .order-details-header {
    margin-bottom: 33px;
    padding: 24px 32px 47px 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 16px;
    .order-details-header-top {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .order-details-title {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #303030;
        .oblong {
          margin-right: 8px;
          width: 4px;
          height: 24px;
          background: #83ba7b;
        }
      }
    }
    .order-details-header-bottom {
      display: flex;
      align-items: center;
      margin-left: 13px;
      padding: 26px 0 39px 34px;
      box-sizing: border-box;
      //   height: 165px;
      border: 2px solid rgba(191, 193, 198, 0.5019607843137255);
      font-size: 14px;
      color: #303030;

      .order-info {
        margin-left: 38px;
        flex: 1;
        color: #303030;
        .ant-form {
          display: flex;
          flex-wrap: wrap;
          .ant-row {
            width: 27%;
            margin-bottom: 8px;
            &.hide {
              .ant-form-item-children {
                span {
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                  overflow: hidden;
                }
              }
            }

            &:nth-last-child(-n + 2) {
              margin-bottom: 0;
            }
            .ant-input {
              width: 110px;
              height: 30px;
            }
          }
        }
      }
    }
  }

  .order-details-main {
    background-color: #fff;
    border-radius: 16px;
    padding: 24px 32px 34px 20px;
    box-sizing: border-box;
    .order-details-main-top {
      margin-bottom: 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 80px;
      .order-details-title {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #303030;
        .oblong {
          margin-right: 8px;
          width: 4px;
          height: 24px;
          background: #83ba7b;
        }
      }
      .order-details-btn {
        display: flex;
        .common {
          width: 72px;
          height: 32px;
          line-height: 32px;
          color: #fff;
          text-align: center;
          border-radius: 4px;
          margin-left: 16px;
          cursor: pointer;
        }
        .finish {
          background: #1eb040;
        }
        .save,
        .edit {
          background: #3298f1;
        }
        .cancel {
          background: rgba(191, 193, 198, 0.5);
        }
        .close {
          background: #bfc1c6;
        }
        .btn {
          display: flex;
          align-items: center;
        }
      }
    }
    .order-details-main-bottom {
      margin-left: 9px;
      // height: 482px;
      border: 2px solid rgba(191, 193, 198, 0.5019607843137255);
      box-sizing: border-box;
      padding: 30px 126px 30px 30px;
      .ant-form {
        display: flex;
        flex-wrap: wrap;
        .ant-row {
          width: 25%;
          margin-bottom: 20px;
          &.hide {
            .ant-form-item-children {
              span {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              }
            }
          }
          .num {
            margin-right: 5px;
            width: 40px;
            height: 30px;
          }
          &.mobile {
            .ant-input[disabled] {
              color: #303030;
              border: none;
              padding: 0;
              background-color: #fff;
            }
          }
        }
        .other {
          width: 100%;
          .ant-col {
            padding-right: 12px !important;

            .ant-input[disabled] {
              background-color: #fff;
              color: #303030;
            }
          }
          .ant-upload-text {
            img {
              width: 26px;
              height: 26px;
            }
          }
          .ant-upload-picture-card-wrapper {
            width: 125%;
          }
        }
      }
    }
  }
}
</style>
