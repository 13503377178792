<template>
  <div class="User">
    <div class="user-header">
      <div class="user-header-top">
        <div class="title">
          <div class="oblong"></div>
          <div>用户管理</div>
        </div>
        <div
          class="creat-user"
          @click="cerateUser"
          v-if="$store.state.permission == 'a%q&22podll.1vz'"
        >
          新建用户
        </div>
        <div v-if="$store.state.permission == 'a%q&22podll.1vz'">
          <batch-import :url="userUrl"></batch-import>
        </div>
        <a
          v-if="$store.state.permission == 'a%q&22podll.1vz'"
          class="download"
          download
          :href="
            http.baseURL +
            '/template/%E7%94%A8%E6%88%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88.xlsx'
          "
          >模板下载</a
        >
      </div>
      <div class="user-header-bottom">
        <div class="user-header-bottom-left">
          <a-form-model
            :model="params"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :colon="false"
          >
            <a-form-model-item label="姓名">
              <a-input
                v-model.trim="params.name"
                placeholder="请输入用户姓名"
                @pressEnter="find"
              />
            </a-form-model-item>
          </a-form-model>
        </div>
        <div class="user-header-bottom-right" @click="find">查询</div>
      </div>
    </div>
    <div class="user-main">
      <a-table
        :columns="columns"
        :data-source="userList"
        :rowKey="(record) => record.id"
        :loading="loading"
        :pagination="pagination"
        :customRow="rowClick"
        @change="sorterChange"
      >
        <!-- 性别筛选 -->
        <span slot="customSex">
          <a-select v-model="gender" @change="genderChange">
            <a-select-option value=""> 全部 </a-select-option>
            <a-select-option value="1"> 男 </a-select-option>
            <a-select-option value="2"> 女 </a-select-option>
          </a-select>
        </span>

        <!-- 职位筛选 -->
        <span slot="customJob">
          <a-select v-model="job" @change="jobChange">
            <a-select-option value=""> 全部 </a-select-option>
            <a-select-option value="1"> 管理员 </a-select-option>
            <a-select-option value="2"> 客服 </a-select-option>
            <a-select-option value="3"> 大区经理/工程服务经理 </a-select-option>
            <a-select-option value="4"> 区域经理/服务工程师 </a-select-option>
          </a-select>
        </span>
        <!-- 领导筛选 -->
        <span slot="customLeader">
          <a-select v-model="leader" @change="leaderChange">
            <a-select-option
              :value="item.id"
              v-for="(item, index) in screenLeaderList"
              :key="index"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </span>
        <!-- 区域筛选 -->
        <!-- <span slot="customArea">
          <a-select v-model="area" @change="areaChange">
            <a-select-option
              :value="item.id"
              v-for="(item, index) in screenAreaList"
              :key="index"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </span> -->
        <!-- 是否禁用 -->
        <span slot="customBan">
          <a-select v-model="ban" @change="banChange">
            <a-select-option value="全部"> 全部 </a-select-option>
            <a-select-option value="1"> 已禁用 </a-select-option>
            <a-select-option value="2"> 使用中 </a-select-option>
          </a-select>
        </span>
        <!--领导 -->
        <span slot="leader" slot-scope="leader">{{
          leader ? leader.name : "/"
        }}</span>
        <!-- 区域 -->
        <span slot="area" slot-scope="area">{{ area ? area : "/" }}</span>
        <!-- 是否禁用 -->
        <span slot="isEnabled" slot-scope="enabled">
          <span>{{ enabled ? "使用中" : "已禁用" }}</span>
        </span>
        <!-- 操作 -->
        <div slot="action" class="handle" slot-scope="item, text, index">
          <div
            @click.stop="banUser(item, index)"
            :class="item.enabled ? '' : 'use'"
          >
            {{ item.enabled ? "禁用" : "恢复" }}
          </div>

          <div class="leave" @click.stop="leaveUser(item, index)">离职</div>

          <div class="delete" @click.stop="deleteUser(item, text, index)">
            删除
          </div>

          <div class="edit" @click.stop="editPwd(item, text, index)">
            修改密码
          </div>
        </div>
      </a-table>
    </div>

    <!-- 新建用户弹框 -->
    <div ref="createRefModel" class="create-user-modal">
      <a-modal
        v-model="createVisible"
        :maskClosable="false"
        title="新建用户"
        @ok="handleOk"
        centered
        width="513px"
        :getContainer="() => $refs.createRefModel"
        @cancel="handleCancel"
      >
        <a-form-model
          :model="createUserForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="ruleForm"
          :rules="rules"
          :colon="false"
        >
          <a-form-model-item label="姓名" prop="name">
            <a-input
              v-model="createUserForm.name"
              placeholder="请输入姓名"
              :maxLength="12"
            />
          </a-form-model-item>
          <a-form-model-item label="性别">
            <a-radio-group v-model="createUserForm.genderId">
              <a-radio value="1" :checked="createUserForm.genderId == 1">
                男
              </a-radio>
              <a-radio value="2"> 女 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="电话" prop="mobile">
            <a-input
              v-model="createUserForm.mobile"
              placeholder="请输入手机号码"
              :maxLength="11"
              type="phone"
            />
          </a-form-model-item>
          <!-- <a-form-model-item label="所在区域" prop="regionId">
            <a-select
              v-model="createUserForm.regionId"
              placeholder="请选择地区"
            >
              <a-select-option
                :value="item.id"
                v-for="(item, index) in areaList"
                :key="index"
                >{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item> -->
          <a-form-model-item label="职位" prop="roleId">
            <a-select v-model="createUserForm.roleId" placeholder="请选择职位">
              <a-select-option value="2"> 客服 </a-select-option>
              <a-select-option value="4"> 区域经理/服务工程师 </a-select-option>
              <a-select-option value="3">
                大区经理/工程服务经理
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="直属领导">
            <a-select
              v-model="createUserForm.sid"
              placeholder="请选择领导"
              allowClear
            >
              <a-select-option
                :value="item.id"
                v-for="(item, index) in leaderList"
                :key="index"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>

    <!-- 删除用户弹框 -->
    <my-modal
      :info="currentUserInfo"
      :visible="deleteVisible"
      :text="text"
      @ok="deleteHandleOk"
      @cancel="modalCancel"
    ></my-modal>

    <!-- 离职用户弹框 -->
    <my-modal
      :info="currentUserInfo"
      :visible="leaveVisible2"
      :text="text"
      @ok="leaveHandleOk({ id: 'service', value: '1' })"
      @cancel="modalCancel"
    ></my-modal>

    <!-- 禁用弹框 -->
    <my-modal
      :info="currentUserInfo"
      :visible="banVisible"
      :text="text"
      @ok="banHandleOk"
      @cancel="modalCancel"
    ></my-modal>

    <!-- 提示弹框 -->
    <alert-modal
      :info="currentUserInfo"
      :visible="alertVisible"
      :text="text"
      @cancel="alertModalCancel"
      :isEdit="isEdit"
    ></alert-modal>

    <!-- 离职弹框 -->
    <leave-modal
      ref="leaveModal"
      :currentId="currentUserInfo.id"
      :visible="leaveVisible"
      @cancel="leaveModalCancel"
      :appointList="appointList"
      @ok="leaveHandleOk($event)"
    ></leave-modal>

    <!-- 修改密码弹框 -->
    <pwd-modal
      :visible="pwdVisible"
      :info="currentUserInfo"
      @cancel="editModalCancel"
      :title="title"
      @ok="editHandleOk($event)"
      ref="pwdModal"
    ></pwd-modal>
    <div v-if="isLoading" class="progress-box">
      <a-progress
        type="circle"
        :width="100"
        strokeColor="#83ba7b"
        :percent="percent"
      />
    </div>
    <MyMask v-if="isLoading"></MyMask>
  </div>
</template>

<script>
import {
  getUser,
  getArea,
  addUser,
  delUser,
  editUser,
  leaveUser,
} from "../common/api/user";
import { debounce } from "lodash";
import MyMask from "../components/MyMask.vue";
import BatchImport from "../components/BatchImport.vue";
import AlertModal from "../components/AlertModal.vue";
import MyModal from "../components/MyModal.vue";
import LeaveModal from "../components/LeaveModal.vue";
import PwdModal from "../components/PwdModal.vue";
import { userUrl } from "../common/url";
import http from "../common/http";
export default {
  name: "User",
  components: {
    AlertModal,
    MyModal,
    LeaveModal,
    PwdModal,
    BatchImport,
    MyMask,
  },

  data() {
    // const token = window.sessionStorage.getItem("token");
    // const authorization = `Auth ${token}`;
    return {
      percent: 0,
      isLoading: false,
      http,
      labelCol: { span: 9 },
      wrapperCol: { span: 20, offset: 1 },
      userUrl,
      // 筛选性别
      gender: "性别筛选",
      // 筛选职位
      job: "职位筛选",
      // 筛选领导
      leader: "领导筛选",
      // 区域筛选
      // area: "区域筛选",
      // 是否禁用
      ban: "是否禁用",
      // 修改密码弹框标题
      title: "",
      // 修改密码弹框状态
      pwdVisible: false,
      // 离职弹框显示状态
      leaveVisible: false,
      // 提示弹框显示状态
      alertVisible: false,
      leaveVisible2: false,
      // 提示弹框文本内容
      text: "",
      // 新建用户表单数据
      labelCol: { span: 5 },
      wrapperCol: { span: 14, offset: 1 },
      // 新建用户请求参数
      createUserForm: {
        name: "",
        genderId: "1",
        mobile: "",
        // 区域Id
        // regionId: undefined,
        // 职位Id
        roleId: undefined,
        // 直属领导id
        sid: undefined,
      },
      // 新建用户表单校验规则
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],

        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            required: true,
            pattern: new RegExp(/^1[3456789]\d{9}$/, "g"),
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        // regionId: [{ required: true, message: "请选择地区", trigger: "blur" }],
        roleId: [{ required: true, message: "请选择职位", trigger: "blur" }],
      },

      // 加载状态
      loading: false,
      // 数据总条数
      total: 0,

      // 分页配置对象
      pagination: {
        position: "bottom",
        pageSize: 10,
        current: 1,
        total: 0,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["5", "10", "20"], // 每页数量选项
        showQuickJumper: true,
        // 显示总数
        showTotal: (total) => `共 ${total} 条`,
        // 改变每页数量时更新显示
        onShowSizeChange: (page, pageSize) => this.onSizeChange(page, pageSize),
        // 点击页码事件
        onChange: (page, pageSize) => this.onPageChange(page, pageSize),
      },
      columns: [
        {
          title: "用户ID",
          dataIndex: "id",
          key: "id",
          align: "center",
          sorter: true,
        },
        {
          title: "姓名",
          dataIndex: "name",
          key: "name",
          align: "center",
        },
        {
          title: "电话",
          dataIndex: "mobile",
          key: "mobile",
          align: "center",
        },
        {
          slots: { title: "customSex" },
          key: "gender",
          dataIndex: "gender",
          align: "center",
        },
        {
          slots: { title: "customJob" },
          key: "mark",
          dataIndex: "mark",
          align: "center",
        },
        {
          slots: { title: "customLeader" },
          dataIndex: "manager",
          key: "leader",
          scopedSlots: { customRender: "leader" },
          align: "center",
        },
        // {
        //   slots: { title: "customArea" },
        //   dataIndex: "regionName",
        //   key: "area",
        //   scopedSlots: { customRender: "area" },
        //   align: "center",
        // },
        {
          slots: { title: "customBan" },
          dataIndex: "enabled",
          key: "isEnabled",
          align: "center",
          scopedSlots: { customRender: "isEnabled" },
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],

      // 获取用户列表请求参数
      params: {
        page: 0,
        size: 10,
        name: "",
      },
      // 用户列表
      userList: [],
      // 新建用户对话框状态
      createVisible: false,
      // 删除用户对话框状态
      deleteVisible: false,
      // 禁用对话框状态
      banVisible: false,
      // 门店所在区域列表
      areaList: [],
      // 筛选区域列表
      screenAreaList: [],
      // 直属领导列表
      leaderList: [],
      // 筛选领导列表
      screenLeaderList: [],
      // 选中用户信息对象
      currentUserInfo: {},
      // 选中的用户索引
      currentIndex: null,
      // 弹框类型
      // modalType: "",
      // 业务员列表
      staffList: [],
      // 指派业务员列表
      appointList: [],
      // 是否修改密码弹框
      isEdit: false,
      // 点击表格行
      rowClick: (record) => ({
        // 事件
        on: {
          click: () => {
            console.log(record, "record");
            this.$router.push({
              path: "/userdetails",
              query: {
                id: record.id,
              },
            });
          },
        },
      }),
    };
  },
  methods: {
    // 查询用户
    find() {
      this.params.page = 0;
      this.pagination.current = 1;
      this.getUserList();
    },
    sorterChange(pagination, filters, sorter) {
      console.log(1111);
      console.log(sorter);
      if (sorter.order == "descend") {
        this.params.sort = "id,desc";
      } else if (sorter.order == "ascend") {
        this.params.sort = "id,asc";
      } else {
        delete this.params.sort;
      }
      this.getUserList();
    },
    // 性别筛选
    genderChange(e) {
      if (e == "") {
        this.gender = "性别筛选";
      }
      this.params.genderId = e;
      this.pagination.current = 1;
      this.params.page = 0;
      this.getUserList();
    },
    // 职位筛选
    jobChange(e) {
      if (e == "") {
        this.job = "职位筛选";
      }
      this.params.roleId = e;
      this.pagination.current = 1;
      this.params.page = 0;
      this.getUserList();
    },
    // 领导筛选
    leaderChange(e) {
      if (e == "") {
        this.leader = "领导筛选";
      }

      this.params.sid = e;

      this.pagination.current = 1;
      this.params.page = 0;
      this.getUserList();
    },
    //  区域筛选
    // areaChange(e) {
    //   if (e == "") {
    //     this.area = "区域筛选";
    //   }
    //   this.params.regionId = e;
    //   this.pagination.current = 1;
    //   this.params.page = 0;
    //   this.getUserList();
    // },
    // 禁用筛选
    banChange(e) {
      if (e == "全部") {
        this.ban = "是否禁用";
        this.params.enable = "";
      } else if (e == "1") {
        this.params.enable = false;
      } else if (e == "2") {
        this.params.enable = true;
      }
      this.pagination.current = 1;
      this.params.page = 0;
      this.getUserList();
    },
    // 确认修改密码
    async editHandleOk(e) {
      // console.log(e);
      const params = {
        id: this.currentUserInfo.id,
        password: e.confirmPwd,
      };
      const res = await editUser(params);
      this.pwdVisible = false;
      this.$refs.pwdModal.$refs.ruleForm.resetFields();
      this.alertVisible = true;
    },
    // 修改密码
    editPwd(item, text, index) {
      this.isEdit = true;
      this.currentUserInfo = item;
      this.title = `修改用户“${this.currentUserInfo.name}”的密码`;
      this.pwdVisible = true;
    },
    // 关闭修改密码弹框
    editModalCancel() {
      this.pwdVisible = false;
    },
    // 确认离职弹窗
    async leaveHandleOk(e) {
      console.log(e);
      if (e.value == "1") {
        if (!e.id) {
          return this.$message.info("请选择区域经理/服务工程师");
        } else {
          if (e.id == "service") {
            const res = await leaveUser({
              id: this.currentUserInfo.id,
            });
            this.leaveVisible2 = false;

            this.$message.success(res.msg);
          } else {
            const res = await leaveUser({
              uid: e.id,
              id: this.currentUserInfo.id,
            });
            this.leaveVisible = false;
            this.$refs.leaveModal.id = undefined;
            this.$message.success(res.msg);
          }
          this.userList[this.currentIndex].enabled = false;
        }
      } else if (e.value == "2") {
        const res = await leaveUser({
          id: this.currentUserInfo.id,
        });
        this.userList[this.currentIndex].enabled = false;
        this.$refs.leaveModal.value = 1;
        this.$refs.leaveModal.id = undefined;
        this.leaveVisible = false;
        this.$emit("userToClient", this.currentUserInfo.id);
        this.$store.commit("updateRefreshPath", "client");
      }
    },
    // 关闭离职弹窗
    leaveModalCancel() {
      this.leaveVisible = false;
    },
    // 离职用户
    leaveUser(item, index) {
      this.currentUserInfo = item;
      this.currentIndex = index;

      if (this.currentUserInfo.mark == "客服") {
        this.isEdit = false;
        // this.modalType = "leave";
        this.text = "离职";
        this.leaveVisible2 = true;
      } else {
        this.appointList = [...this.staffList, ...this.leaderList];
        // 这里对id属性进行去重
        const map = new Map();
        this.appointList = this.appointList.filter(
          (item) => !map.has(item.id) && map.set(item.id, 1)
        );
        // console.log("指派业务员列表：", this.appointList);
        this.leaveVisible = true;
      }
    },
    // 关闭确认弹框
    modalCancel() {
      this.deleteVisible = false;
      this.banVisible = false;
      this.leaveVisible2 = false;
      // if (this.modalType == "delete") {
      //   this.deleteVisible = false;
      // } else if (this.modalType == "ban") {
      //   this.banVisible = false;
      // }
    },
    // 确认禁用用户
    async banHandleOk() {
      const params = {
        id: this.currentUserInfo.id,
        enable: !this.currentUserInfo.enabled,
      };

      await editUser(params);

      this.userList[this.currentIndex].enabled =
        !this.userList[this.currentIndex].enabled;
      this.banVisible = false;
      this.alertVisible = true;
    },
    // 禁用用户
    banUser(item, index) {
      this.isEdit = false;
      // this.modalType = "ban";
      if (item.enabled) {
        this.text = "禁用";
      } else {
        this.text = "恢复";
      }

      this.banVisible = true;
      this.currentUserInfo = item;
      this.currentIndex = index;
    },
    // 关闭提示弹框
    alertModalCancel() {
      this.alertVisible = false;
    },
    // 删除用户
    deleteUser(item, text, index) {
      this.isEdit = false;
      // this.modalType = "delete";
      this.text = "删除";
      this.deleteVisible = true;
      this.currentUserInfo = item;
      this.currentIndex = index;
    },
    // 确认删除用户
    async deleteHandleOk() {
      await delUser({ id: this.currentUserInfo.id });
      if (this.userList.length === 1 && this.params.page !== 0) {
        this.params.page--;
        this.pagination.current--;
      }
      this.getUserList();
      this.deleteVisible = false;
      this.alertVisible = true;
      this.$store.commit("updateRefreshPath", "completeness");
    },
    // 获取门店所在区域
    async getAreaList() {
      const res = await getArea();
      this.areaList = res.data;
      this.screenAreaList = [
        {
          name: "全部",
          id: "",
        },
        ...this.areaList,
      ];
    },
    // 新建用户
    cerateUser() {
      this.createVisible = true;
    },
    // 新建用户
    handleOk: debounce(function () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const res = await addUser(this.createUserForm);
          console.log("新建用户：", res);
          this.createVisible = false;
          this.$message.success(res.msg);
          this.createUserForm.genderId = "1";
          this.$store.commit("updateRefreshPath", "client");
          this.$store.commit("updateRefreshPath", "order");
          this.$store.commit("updateRefreshPath", "completeness");

          // 清空表单
          this.$refs.ruleForm.resetFields();
          this.pagination.current = 1;
          this.params.page = 0;
          this.getUserList();
          this.getLeaderList();
        }
      });
    }, 500),
    // 关闭对话框
    handleCancel() {
      this.createUserForm.genderId = "1";
      // 清空表单
      this.$refs.ruleForm.resetFields();
    },

    // 改变每页数量时更新显示
    onSizeChange(page, pageSize) {
      console.log("每页数量:", pageSize);
      this.pagination.current = 1;
      this.pagination.pageSize = pageSize;
      this.params.page = 0;
      this.params.size = pageSize;
      this.getUserList();
    },
    // 点击页码事件
    onPageChange(page) {
      console.log("页码值:", page);
      this.pagination.current = page;
      this.params.page = page - 1;
      this.getUserList();
    },
    // 获取用户列表
    async getUserList() {
      // console.log("请求参数", this.params);
      this.loading = true;
      const res = await getUser(this.params);
      this.loading = false;
      // console.log(res);

      // console.log("res.data:", res.data.content);
      this.total = res.data.totalElements;
      this.pagination.total = res.data.totalElements;

      res.data.content.forEach((i) => {
        const min = Math.min.apply(
          Math,
          i.roles.map((v) => {
            return v.id;
          })
        );
        if (i.roles.length > 0) {
          const obj = i.roles.find((i) => {
            return min == i.id;
          });
          if (obj.id == 3) {
            i.mark = "大区经理/工程服务经理";
          } else if (obj.id == 4) {
            i.mark = "区域经理/服务工程师";
          } else {
            i.mark = obj.mark;
          }
        } else {
          i.mark = "/";
        }
      });
      this.userList = res.data.content;
    },
    // 获取直属领导列表
    async getLeaderList() {
      const params = {
        page: 0,
        size: 999,
        roleId: 3,
      };
      const res = await getUser(params);
      // console.log("领导列表:", res);
      this.leaderList = res.data.content;
      this.screenLeaderList = [
        {
          name: "全部",
          id: "",
        },
        ...this.leaderList,
      ];
    },
    // 获取业务员列表
    async getStaffList() {
      const params = {
        page: 0,
        size: 999,
        roleId: 4,
      };
      const res = await getUser(params);
      // console.log("业务员列表:", res);
      this.staffList = res.data.content;
    },
  },
  async created() {
    console.log(this.$router, 1111111111);
    await this.getUserList();
    await this.getAreaList();
    await this.getLeaderList();
    await this.getStaffList();
  },
  activated() {
    let index = this.$store.state.refreshPath.findIndex((v) => {
      return v == "user";
    });
    if (index != -1) {
      console.log("触发了'activated'");
      this.getUserList();
      this.$store.commit("delRefreshPath", "user");
    }
  },
};
</script>

<style scoped lang="scss">
.User {
  border-radius: 16px;
  background-color: #fff;
  padding: 24px;
  box-sizing: border-box;
  .user-header {
    margin-bottom: 32px;

    .user-header-top {
      display: flex;
      align-items: center;
      .title {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #303030;
        .oblong {
          margin-right: 8px;
          width: 4px;
          height: 24px;
          background: #83ba7b;
        }
      }
      .creat-user {
        cursor: pointer;
        margin: 0 16px 0 30px;
        width: 88px;
        height: 32px;
        background: #83ba7b;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        color: #fff;
      }
      .download {
        margin-left: 16px;
        color: #83ba7b;
        font-size: 14px;
      }
    }
    .user-header-bottom {
      display: flex;
      align-items: center;
      padding: 24px 12px 0;
      .user-header-bottom-left {
        margin-right: 45px;
        .ant-form {
          display: flex;
          align-items: center;
          // flex-wrap: wrap;
          .ant-row {
            margin-right: 25px;
            margin-bottom: 0;
            display: flex;
            align-items: center;

            .ant-input {
              width: 220px;
              height: 30px;
            }
          }
        }
      }
      .user-header-bottom-right {
        width: 72px;
        height: 32px;
        background: #83ba7b;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .user-main {
    .ant-select::v-deep {
      min-width: 130px;
      .ant-select-selection__rendered {
        display: flex;
        justify-content: center;
      }
    }
    .handle {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #9a9ea7;
      div {
        margin-left: 30px;
        cursor: pointer;
        &:first-child {
          margin-left: 0;
        }
      }
      .use {
        color: #1eb040;
      }
      .leave {
        color: #f68714;
      }
      .delete {
        color: #e93024;
      }
      .edit {
        color: #3298f1;
      }
    }
  }
  .create-user-modal::v-deep {
    .ant-modal-header {
      .ant-modal-title {
        text-align: center !important;
      }
    }
    .ant-modal-body {
      padding: 20px 78px 0;
      .ant-form-item {
        margin-bottom: 20px;
      }
    }
    .ant-modal-footer {
      text-align: center;
      padding-bottom: 44px;
      border: 0;
      div button {
        width: 72px;
        height: 32px;
        &:nth-child(2) {
          margin-left: 80px;
          background: #83ba7b;
          border: 0;
        }
      }
    }
  }
  .delete-user-modal::v-deep {
    .ant-modal-header {
      .ant-modal-title {
        text-align: center !important;
      }
    }
    .ant-modal-body {
      text-align: center;
      span {
        color: #ec4e44;
      }
    }
    .ant-modal-footer {
      text-align: center;
      padding-bottom: 44px;
      border: 0;
      div button {
        width: 72px;
        height: 32px;
        &:nth-child(2) {
          margin-left: 80px;
          background: #83ba7b;
          border: 0;
        }
      }
    }
  }
  .delete-user-modal::v-deep {
    .ant-modal-header {
      .ant-modal-title {
        text-align: center !important;
      }
    }
    .ant-modal-body {
      text-align: center;
      span {
        color: #ec4e44;
      }
    }
    .ant-modal-footer {
      text-align: center;
      padding-bottom: 44px;
      border: 0;
      div button {
        width: 72px;
        height: 32px;
        &:nth-child(2) {
          margin-left: 80px;
          background: #83ba7b;
          border: 0;
        }
      }
    }
  }
  .progress-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
}
</style>
