<template>
  <div class="MyMask"></div>
</template>

<script>
export default {
  name: "MyMask",

  components: {},
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<style scoped lang="scss">
.MyMask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 888;
}
</style>
