<template>
  <div class="UserDetails">
    <div class="user-details-header">
      <div class="user-details-header-top">
        <div class="user-details-title">
          <div class="oblong"></div>
          <div>用户详情</div>
        </div>
        <div class="user-details-btn">
          <div class="edit" @click="editInfo" v-show="flag">编辑</div>
          <div v-show="!flag" class="btn">
            <div @click="save">保存</div>
            <div class="cancel" @click="cancel">取消</div>
          </div>
        </div>
      </div>
      <div class="user-details-header-bottom">
        <div class="avatar">
          <img :src="userInfo.avatarPath || defaultAvatar" alt="" />
        </div>
        <div class="user-info">
          <a-form-model
            :model="form"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            ref="ruleForm"
            :rules="rules"
            :colon="false"
          >
            <a-form-model-item label="用户ID">
              {{ userInfo.id }}
            </a-form-model-item>
            <a-form-model-item label="性别">
              <span v-show="flag">{{ userInfo.gender }}</span>
              <a-select
                v-show="!flag"
                :value="form.genderId"
                placeholder="请选择性别"
                @change="genderChange"
              >
                <a-select-option value="0"> 未详 </a-select-option>
                <a-select-option value="1"> 男 </a-select-option>
                <a-select-option value="2"> 女 </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="账号状态">
              <a-select
                :value="form.enable"
                placeholder="请选择账号状态"
                v-if="!flag && $store.state.permission == 'a%q&22podll.1vz'"
                @change="enableChange"
              >
                <a-select-option value="1"> 使用中</a-select-option>
                <a-select-option value="2"> 已禁用 </a-select-option>
              </a-select>
              <span v-else>{{ userInfo.isEnabled }}</span>
            </a-form-model-item>
            <a-form-model-item label="姓名" prop="name">
              <a-input
                v-model="form.name"
                placeholder="请输入姓名"
                :maxLength="12"
                v-show="!flag"
              />
              <span v-show="flag" :title="form.name ? form.name : ''">{{
                form.name ? form.name : "/"
              }}</span>
            </a-form-model-item>
            <!-- <a-form-model-item label="所属区域">
              <span v-show="flag">{{
                userInfo.regionName ? userInfo.regionName : "/"
              }}</span>
              <a-select
                v-show="!flag"
                :value="form.regionId"
                placeholder="请选择所属区域"
                @change="areaChange"
              >
                <a-select-option
                  :value="item.name"
                  v-for="(item, index) in areaList"
                  :key="index"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item> -->
            <a-form-model-item label="电话" prop="mobile">
              <a-input
                v-model="form.mobile"
                placeholder="请输入电话"
                :maxLength="11"
                v-show="!flag"
              />
              <span v-show="flag" :title="form.mobile ? form.mobile : ''">{{
                form.mobile ? form.mobile : "/"
              }}</span>
            </a-form-model-item>
            <a-form-model-item label="职位">
              <span v-show="flag">{{ userInfo.mark }}</span>

              <a-select
                v-show="!flag"
                :value="form.roleId"
                placeholder="请选择职位"
                @change="roleIdChange"
                class="long"
              >
                <a-select-option value="2"> 客服 </a-select-option>
                <a-select-option value="4">
                  区域经理/服务工程师
                </a-select-option>
                <a-select-option value="3">
                  大区经理/工程服务经理
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="直属领导">
              <span v-show="flag">{{
                userInfo.manageName ? userInfo.manageName : "/"
              }}</span>
              <a-select
                :value="form.sid"
                placeholder="请选择直属领导"
                v-show="!flag"
                @change="sidChange"
              >
                <a-select-option
                  :value="item.id"
                  v-for="(item, index) in leaderList"
                  :key="index"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="user-details-main">
      <div class="user-details-main-top">
        <div class="user-details-title">
          <div class="oblong"></div>
          <div>负责的客户和近期回访问卷</div>
        </div>
        <div class="user-details-search">
          <div class="store">
            <span class="label">客户/门店名称</span>
            <a-input placeholder="请输入客户/门店名" @change="storesSearch" />
          </div>
          <div class="company">
            <span class="label">集团品牌</span>
            <searchSelect
              :bid="storesParams.bid"
              :iptWidth="180"
              @updateParams="groupChange"
            ></searchSelect>
          </div>
        </div>
      </div>
      <div class="user-details-main-bottom">
        <a-table
          :columns="columns"
          :data-source="storesList"
          :rowKey="(record) => record.id"
          :pagination="pagination"
        >
          <span
            :slot="'customLook' + item.id"
            v-for="(item, index) in completenessList"
            :key="index"
          >
            <span>{{ item.theMonth }}</span>
            <span
              :class="['fail', item.completeness >= 0.85 ? 'pass' : '']"
              v-if="item.completeness"
            >
              {{ " (" + (item.completeness * 100).toFixed(0) + "%)" }}</span
            >
          </span>

          <span
            slot="looks"
            slot-scope="item, text, index, column"
            @click="look(item, text, index, column)"
          >
            点击查看
          </span>
          <span slot="without">/</span>

          <span slot="more" @click="lookMore" class="more"> 更多历史记录</span>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import defaultAvatar from "../assets/image/avatar.png";
import SearchSelect from "../components/SearchSelect.vue";

import {
  getUserDetails,
  getArea,
  getUser,
  editUser,
  getCompleteness,
  getStores,
} from "../common/api/user";
import { getGroup } from "../common/api/order";
import { getTask } from "../common/api/ques";

export default {
  name: "UserDetails",

  components: {
    SearchSelect,
  },

  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 14, offset: 1 },
      form: {
        name: "",
        enable: "",
        genderId: "",
        mobile: "",
        // regionId: "",
        roleId: "",
        sid: "",
      },
      // 修改前的name
      oldName: "",
      // 修改前的mobile
      oldMobile: "",
      // 更新个人信息请求参数
      params: {},

      //默认头像
      defaultAvatar,
      // 门店所在区域列表
      areaList: [],
      // 直属领导列表
      leaderList: [],
      // 用户详情状态
      flag: true,
      // 用户详情
      userInfo: {},
      groupList: ["不限"],
      columns: [
        {
          title: "客户名称",
          dataIndex: "name",
          key: "name",
          align: "center",
        },
        {
          slots: { title: "more" },
        },
      ],
      // 分页配置对象
      pagination: {
        position: "bottom",
        pageSize: 5,
        current: 1,
        total: 0,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["5", "10", "20"], // 每页数量选项
        showQuickJumper: true,
        // 显示总数
        showTotal: (total) => `共 ${total} 条`,
        // 改变每页数量时更新显示
        onShowSizeChange: (page, pageSize) => this.onSizeChange(page, pageSize),
        // 点击页码事件
        onChange: (page, pageSize) => this.onPageChange(page, pageSize),
      },

      // 修改信息表单校验规则
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],

        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            required: true,
            pattern: new RegExp(/^1[3456789]\d{9}$/, "g"),
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
      },
      // 获取近期完成度请求参数
      completenessParams: {
        page: 0,
        size: 3,
      },
      // 完成度总条数
      completenessTotal: 0,
      // 近期完成度列表
      completenessList: [],
      // 获取负责客户请求参数
      storesParams: {
        page: 0,
        size: 5,
        bid: undefined,
      },
      //  负责客户列表
      storesList: [],
      // 用户id
      id: "",
      // 定时器id
      timer: null,
    };
  },
  methods: {
    // 获取门店集团
    async getGrouplist() {
      const res = await getGroup({ id: this.id });
      this.groupList = res.data;
    },
    // 查看更多历史记录
    lookMore() {
      if (
        (this.completenessParams.page + 1) * this.completenessParams.size >=
        this.completenessTotal
      ) {
        return this.$message.info("暂无更多记录");
      }
      this.completenessParams.page++;
      this.getRecentCompleteness();
    },
    // 改变每页数量时更新显示
    onSizeChange(page, pageSize) {
      console.log("每页数量:", pageSize);
      this.pagination.current = 1;
      this.pagination.pageSize = pageSize;
      this.storesParams.page = 0;
      this.storesParams.size = pageSize;
      this.getStoresList(this.storesParams);
    },
    // 点击页码事件
    onPageChange(page) {
      console.log("页码值:", page);
      this.pagination.current = page;
      this.storesParams.page = page - 1;
      this.getStoresList(this.storesParams);
    },
    storesSearch(e) {
      console.log(e.target.value);
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.storesParams.name = e.target.value;
        this.storesParams.page = 0;
        let obj = JSON.parse(JSON.stringify(this.storesParams));
        if (!obj.bid) {
          obj.bid = "";
        }
        this.getStoresList(obj);
      }, 300);
    },

    groupChange(e) {
      console.log(e);
      this.storesParams.bid = e;
      let obj = JSON.parse(JSON.stringify(this.storesParams));

      for (let k in obj) {
        if (!obj[k]) {
          delete obj[k];
        }
      }
      this.storesParams.page = 0;
      obj.page = 0;

      this.getStoresList(obj);
    },
    // 获取每个月的最后一天
    getMonthLastDate(month) {
      let dateObj = new Date(month);
      let nextMonth = dateObj.getMonth() + 1; //0-11，下一个月
      //设置当前日期为下个月的1号
      dateObj.setMonth(nextMonth);
      dateObj.setDate(1); //1-31
      let nextMonthFirstDayTime = dateObj.getTime(); //下个月一号对应毫秒
      let theMonthLastDayTime = nextMonthFirstDayTime - 24 * 60 * 60 * 1000; //下个月一号减去一天，正好是这个月最后一天
      let theMonthDay = new Date(theMonthLastDayTime).getDate();
      return month + "-" + theMonthDay;
    },
    // 点击查看
    async look(item, text, index, { theMonth }) {
      console.log(item.name, item.saleId, theMonth, 11111111);
      let starttime = theMonth + "-01";

      let endtime = this.getMonthLastDate(theMonth);
      console.log("endtime", endtime);
      const res = await getTask({
        name: item.name,
        uid: item.saleId,
        starttime,
        endtime,
      });

      console.log(res);
      if (res.data.content.length == 0) {
        this.$message.info("暂无数据");
      } else {
        this.$emit("userToQues", {
          name: item.name,
          month: theMonth,
          saleId: item.saleId,
        });
      }
    },
    // 获取近期完成度
    async getRecentCompleteness() {
      this.completenessParams.id = this.id;
      const res = await getCompleteness(this.completenessParams);

      console.log(this.completenessList);
      if (res.data.content.length == 0 && this.completenessParams.page == 0) {
        let myMonth = this.getMonth();
        console.log(myMonth);
        // this.completenessList.pop();
        myMonth.forEach((v, i) => {
          let obj = {
            id: i,
            theMonth: v,
          };
          let o = {
            slots: { title: "customLook" + i },
            scopedSlots: { customRender: "without" },
            key: v,
            align: "center",
          };
          this.completenessList.push(obj);
          this.columns.splice(this.columns.length - 1, 0, o);
        });
        console.log(this.completenessList);
      } else {
        this.completenessList = [...this.completenessList, ...res.data.content];
        this.completenessTotal = res.data.totalElements;
        res.data.content.forEach((v, i) => {
          let obj = {
            slots: { title: "customLook" + v.id },
            scopedSlots: { customRender: "looks" },
            key: v.id,
            theMonth: v.theMonth,
            // dataIndex: "id",
            align: "center",
          };
          console.log("obj", obj);
          this.columns.splice(this.columns.length - 1, 0, obj);
          console.log("this.columns:", this.columns);
        });
      }
    },
    // 获取负责的店铺
    async getStoresList(params) {
      // this.storesParams.id = this.id;
      const res = await getStores(params);
      console.log("回访报告：", res);
      res.data.content.forEach((v) => {
        v.look = "点击查看";
      });
      this.storesList = res.data.content;
      this.pagination.total = res.data.totalElements;
    },

    onSubmit() {
      console.log("submit!", this.form);
    },
    // 选择性别
    genderChange(e) {
      this.form.genderId = e;
    },
    // 选择账号状态
    enableChange(e) {
      this.form.enable = e;
    },
    // 选择所属区域
    // areaChange(e) {
    //   this.form.regionId = e;
    // },
    // 选择职位
    roleIdChange(e) {
      this.form.roleId = e;
    },
    // 选择直属领导
    sidChange(e) {
      console.log(e);
      this.form.sid = e;
    },
    // 获取直属领导列表
    async getLeaderList() {
      const params = {
        page: 0,
        size: 999,
        roleId: 3,
      };
      const res = await getUser(params);
      this.leaderList = res.data.content.filter((v) => {
        return this.userInfo.id != v.id;
      });

      this.leaderList.unshift({
        name: "无",
        id: "0",
      });
    },
    // 获取门店所在区域
    async getAreaList() {
      const res = await getArea();
      this.areaList = res.data;
    },
    // 编辑个人信息
    editInfo() {
      this.oldName = this.form.name;
      this.oldMobile = this.form.mobile;
      this.flag = false;
    },
    // 保存
    save() {
      this.$refs["ruleForm"].validate(async (valid) => {
        console.log(valid);
        if (valid) {
          console.log(this.form);
          this.params = JSON.parse(JSON.stringify(this.form));
          if (this.params.enable == "1") {
            this.params.enable = true;
          } else if (this.params.enable == "2") {
            this.params.enable = false;
          }
          if (this.params.sid == "/") {
            this.params.sid = "";
          }
          if (this.params.genderId == "未详") {
            this.params.genderId = "";
          }
          // this.areaList.forEach((v) => {
          //   if (v.name == this.params.regionId) {
          //     this.params.regionId = v.id;
          //   }
          // });
          console.log(this.params);
          this.params.id = this.id;
          const res = await editUser(this.params);
          this.$store.commit("updateRefreshPath", "user");
          this.$store.commit("updateRefreshPath", "completeness");
          // console.log(res);
          this.getUserInfo();
          this.flag = true;
        }
      });
    },
    // 取消
    cancel() {
      this.form.name = this.oldName;
      this.form.mobile = this.oldMobile;
      this.flag = true;
      //移除其中的某一项校验
      this.$refs["ruleForm"].clearValidate(["name", "mobile"]);
    },
    // 获取前三个月月份
    getMonth() {
      // 当前月份;
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      let eDate = year.toString() + "-" + month.toString();
      console.log("当前月份", eDate);

      // 前1个月
      var year, lastMonth;
      var date = new Date();
      var nowYear = date.getFullYear(); //当前年：四位数字
      var nowMonth = date.getMonth(); //当前月：0-11
      if (nowMonth == 0) {
        //如果是0，则说明是1月份，上一个月就是去年的12月
        year = nowYear - 1;
        lastMonth = 12;
      } else {
        //不是1月份，年份为当前年，月份本来是要减1的，但是由于`getMonth()`的月份本身就是少了1的，所以月份不用变。
        year = nowYear;
        lastMonth = nowMonth;
      }

      lastMonth = lastMonth < 10 ? "0" + lastMonth : lastMonth; //月份格式化：月份小于10则追加个0

      let oMonth = year + "-" + lastMonth;

      console.log("前1个月", oMonth);

      // 前2个月;
      var date2 = new Date();
      date2.setMonth(date2.getMonth() - 2);
      console.log("date2", date2);
      var year2 = date2.getFullYear();
      var month2 = date2.getMonth() + 1;
      month2 = month2 < 10 ? "0" + month2 : month2;
      let sDate = year2.toString() + "-" + month2.toString();
      console.log("前2个月", sDate);
      return [eDate, oMonth, sDate];
    },
    // 获取用户详情
    async getUserInfo() {
      const id = this.id;
      const res = await getUserDetails({
        id,
      });
      console.log(res);

      const min = Math.min.apply(
        Math,
        res.data.roles.map((v) => {
          return v.id;
        })
      );
      let obj = {};
      if (res.data.roles.length > 0) {
        obj = res.data.roles.find((i) => {
          return min == i.id;
        });
        if (obj.id == 3) {
          res.data.mark = "大区经理/工程服务经理";
        } else if (obj.id == 4) {
          res.data.mark = "区域经理/服务工程师";
        } else {
          res.data.mark = obj.mark;
        }
      } else {
        res.data.mark = "/";
      }
      if (!res.data.manager) {
        res.data.manageName = "/";
      } else {
        res.data.manageName = res.data.manager.name;
      }
      if (res.data.enabled) {
        res.data.isEnabled = "使用中";
        this.form.enable = "1";
      } else {
        res.data.isEnabled = "已禁用";
        this.form.enable = "2";
      }

      this.userInfo = res.data;
      this.form.name = this.userInfo.name;
      this.form.mobile = this.userInfo.mobile;
      console.log(obj, "obj");
      if (obj.id) {
        this.form.roleId = obj.id.toString();
      } else {
        this.form.roleId = "/";
      }
      // this.form.roleId = obj.id.toString();

      // if (this.userInfo.regionName) {
      //   this.form.regionId = this.userInfo.regionName;
      // } else {
      //   this.form.regionId = "/";
      // }
      if (this.userInfo.manager) {
        this.form.sid = this.userInfo.manager.id;
      } else {
        this.form.sid = "0";
      }
      if (this.userInfo.gender == "男") {
        this.form.genderId = "1";
      } else if (this.userInfo.gender == "女") {
        this.form.genderId = "2";
      } else {
        this.form.genderId = "未详";
      }
    },
  },
  async created() {
    this.id = this.$route.query.id;
    this.storesParams.id = this.id;
    this.getUserInfo();
    await this.getRecentCompleteness();
    let obj = JSON.parse(JSON.stringify(this.storesParams));

    delete obj.bid;

    this.getStoresList(obj);
    this.getLeaderList();
    this.getAreaList();
    this.getGrouplist();
  },
};
</script>

<style scoped lang="scss">
.UserDetails {
  box-sizing: border-box;
  .user-details-header {
    margin-bottom: 33px;
    padding: 24px 32px 47px 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 16px;
    .user-details-header-top {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .user-details-title {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #303030;
        .oblong {
          margin-right: 8px;
          width: 4px;
          height: 24px;
          background: #83ba7b;
        }
      }
      .user-details-btn {
        .edit {
          width: 72px;
          height: 32px;
          line-height: 32px;
          color: #fff;
          text-align: center;
          background: #3298f1;
          border-radius: 4px;
          cursor: pointer;
        }
        .btn {
          display: flex;
          div {
            width: 72px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            color: #fff;
            background: #3298f1;
            border-radius: 4px;
            cursor: pointer;
          }
          .cancel {
            margin-left: 16px;
            background: rgba(191, 193, 198, 0.5);
          }
        }
      }
    }
    .user-details-header-bottom {
      display: flex;
      align-items: center;
      margin-left: 13px;
      padding: 26px 0 39px 34px;
      box-sizing: border-box;
      //   height: 165px;
      border: 2px solid rgba(191, 193, 198, 0.5019607843137255);
      font-size: 14px;
      color: #303030;
      .avatar {
        img {
          width: 100px;
          height: 100px;
          opacity: 1;
          border-radius: 8px;
          object-fit: cover;
        }
      }
      .user-info {
        margin-left: 38px;
        flex: 1;
        color: #303030;
        .ant-form {
          display: flex;
          flex-wrap: wrap;
          .ant-row {
            width: 27%;
            margin-bottom: 8px;

            .ant-form-item-children {
              span {
                color: #303030;
              }
            }
            &:nth-last-child(-n + 2) {
              margin-bottom: 0;
            }

            .ant-input {
              width: 110px;
              height: 30px;
            }

            .ant-input[disabled] {
              color: #303030;
              border: none;
              padding: 0;
              background-color: #fff;
            }
          }
        }
      }
    }
  }
  .user-details-main {
    background-color: #fff;
    border-radius: 16px;
    padding: 24px 32px 47px 20px;
    box-sizing: border-box;
    .user-details-main-top {
      .user-details-title {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #303030;
        .oblong {
          margin-right: 8px;
          width: 4px;
          height: 24px;
          background: #83ba7b;
        }
      }
      .user-details-search {
        display: flex;
        color: #303030;
        margin-top: 24px;
        .store,
        .company {
          display: flex;
          align-items: center;
          .label {
            margin-right: 10px;
          }
          .ant-input,
          .ant-select {
            width: 240px;
            height: 30px;
            border: 1px solid #bfc1c6;
            border-radius: 4px;
          }
          // .ant-select {
          //   border-radius: 0;
          //   border: 0;
          // }
        }

        .store {
          margin-right: 232px;
        }
      }
    }
    .user-details-main-bottom {
      margin-top: 24px;
      .ant-table-tbody {
        span {
          cursor: pointer;
        }
      }
      .more {
        cursor: pointer;
      }
      .fail {
        color: #e93024;
      }
      .pass {
        color: #26c94c;
      }
    }
  }
}
</style>
