<template>
  <div class="CreateQuesModal">
    <div class="create-ques-modal" ref="createRefModel">
      <a-modal
        v-model="isShow"
        title="新增问题"
        centered
        width="920px"
        :maskClosable="false"
        :getContainer="() => $refs.createRefModel"
        :afterClose="() => $refs.ruleForm.resetFields()"
        @ok="handleOk"
        @cancel="handleCancel"
        cancelText="取消"
        okText="保存"
      >
        <a-form-model
          :model="createQuesForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="ruleForm"
          :rules="rules"
          :colon="false"
        >
          <a-form-model-item label="问题组名称" prop="itemGroupId">
            <a-select
              show-search
              placeholder="请选择问题组"
              :default-active-first-option="false"
              :filter-option="false"
              :not-found-content="null"
              notFoundContent="暂无数据"
              :value="createQuesForm.itemGroupId"
              class="hidden"
              @search="handleSearch"
              @select="handleChange"
              @blur="handleBlur"
            >
              <a-select-option
                v-for="item in questionGroupList"
                :key="item.id"
                :value="item.id"
                >{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="带有的Tag">
            <a-select
              allowClear
              mode="multiple"
              showSearch
              :filterOption="filterOption"
              :autoClearSearchValue="true"
              :maxTagCount="2"
              class="y_select"
              :maxTagTextLength="5"
              placeholder="请选择Tag"
              v-model="createQuesForm.tagsId"
            >
              <a-select-option
                v-for="item in tagList"
                :value="item.id"
                :key="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="问题概述" prop="name">
            <a-textarea
              placeholder="请输入"
              :auto-size="{ minRows: 4, maxRows: 8 }"
              :maxLength="500"
              style="width: 480px"
              v-model.trim="createQuesForm.name"
            />
          </a-form-model-item>
          <a-form-model-item label="问题说明">
            <a-textarea
              placeholder="请输入"
              :auto-size="{ minRows: 4, maxRows: 8 }"
              :maxLength="500"
              style="width: 480px"
              v-model.trim="createQuesForm.judgment"
            />
          </a-form-model-item>
          <a-form-model-item label="添加图片说明" class="img-box">
            <a-upload
              list-type="picture-card"
              :file-list="fileList"
              :customRequest="uploadFiles"
              :remove="handleDownloadFileRemove"
              :multiple="true"
              accept="image/*"
            >
              <div v-if="fileList.length < 5">
                <a-progress v-if="isLoading" :percent="percent" size="small" />
                <div class="ant-upload-text" v-else>
                  <img src="../assets/image/add.png" alt="" />
                </div>
              </div>
            </a-upload>
          </a-form-model-item>

          <a-form-model-item label="应答类型">
            <a-radio-group
              v-model="createQuesForm.typeId"
              @change="typeRadioChange"
            >
              <a-radio
                :value="item.val"
                v-for="item in typeList"
                :key="item.id"
              >
                {{ item.text }}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>

          <div v-if="typeShow">
            <a-form-model-item label="字符类型选择">
              <a-radio-group
                @change="charsRadioChange"
                v-model="createQuesForm.number"
              >
                <a-radio
                  :value="item.val"
                  v-for="(item, index) in charsList"
                  :key="index"
                >
                  {{ item.text }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item
              label="文本长度限制"
              v-if="!charsShow"
              prop="size"
            >
              <a-input
                allowClear
                placeholder="请输入"
                type="number"
                :maxLength="50"
                style="width: 120px"
                v-model.trim="createQuesForm.size"
              />
            </a-form-model-item>
            <div v-if="charsShow">
              <a-form-model-item label="数字最大值" prop="numberMax">
                <a-input
                  placeholder="请输入"
                  :maxLength="50"
                  type="number"
                  oninput="value=value.replace('.', '',).replace('-', '',)"
                  style="width: 120px"
                  v-model.trim="createQuesForm.numberMax"
                />
              </a-form-model-item>
              <a-form-model-item label="数字最小值" prop="numberMin">
                <a-input
                  placeholder="请输入"
                  :maxLength="50"
                  type="number"
                  oninput="value=value.replace('.', '',).replace('-', '',)"
                  style="width: 120px"
                  v-model.trim="createQuesForm.numberMin"
                />
              </a-form-model-item>

              <a-form-model-item label="单位" prop="unit">
                <a-input
                  placeholder="请输入"
                  :maxLength="50"
                  style="width: 120px"
                  v-model.trim="createQuesForm.unit"
                />
              </a-form-model-item>
            </div>
          </div>

          <a-form-model-item
            label="应答项和分值"
            class="opt-box"
            v-if="!typeShow"
            required
          >
            <div
              v-for="(item, index) in createQuesForm.optionsList"
              :key="index"
              class="opt-item"
            >
              <a-form-model-item
                :prop="'optionsList.' + index + '.val'"
                :rules="[
                  {
                    required: true,
                    message: '请输入内容',
                    trigger: 'blur',
                  },
                ]"
              >
                <a-input
                  placeholder="请输入内容"
                  :maxLength="50"
                  style="width: 120px"
                  v-model.trim="item.val"
                />
              </a-form-model-item>
              <span> ── </span>
              <a-form-model-item
                :prop="'scoresList.' + index + '.score'"
                :rules="[
                  {
                    required: true,
                    message: '请输入分数',
                    trigger: 'blur',
                  },
                ]"
              >
                <a-input
                  placeholder="请输入分数"
                  :maxLength="50"
                  style="width: 120px"
                  type="number"
                  oninput="value=value.replace('.', '',).replace('-', '',)"
                  v-model.trim="item.score"
                />
              </a-form-model-item>
              <div
                class="del"
                @click="del(index, 'option')"
                v-if="createQuesForm.optionsList.length > 1"
              >
                <img src="../assets/image/delete.png" alt="" />
              </div>
            </div>
            <div class="add" @click="add('option')">添加</div>
          </a-form-model-item>
          <a-form-model-item label="人工评分" required v-if="!typeShow">
            <a-radio-group
              v-model="createQuesForm.manual"
              @change="manualRadioChange"
            >
              <a-radio
                :value="item.val"
                v-for="(item, index) in manualList"
                :key="index"
              >
                {{ item.text }}
              </a-radio>
            </a-radio-group>
            <div class="manual-box" v-if="typeShow || manualShow">
              <span>可评最低分值</span>
              <a-form-model-item class="manual-item" prop="manualMin">
                <a-input
                  placeholder="请输入"
                  style="width: 100px"
                  type="number"
                  oninput="value=value.replace('.', '',).replace('-', '',)"
                  v-model.trim="createQuesForm.manualMin"
                />
              </a-form-model-item>
              <span class="label">本题满分分值</span>
              <a-form-model-item class="manual-item" prop="manualMax">
                <a-input
                  placeholder="请输入"
                  style="width: 100px"
                  type="number"
                  oninput="value=value.replace('.', '',).replace('-', '',)"
                  v-model.trim="createQuesForm.manualMax"
                  :disabled="getManualDisabled && createQuesForm.typeId === 2"
                />
              </a-form-model-item>
            </div>
          </a-form-model-item>

          <a-form-model-item label="可添加照片">
            <a-radio-group v-model="createQuesForm.imgNeed">
              <a-radio
                :value="item.val"
                v-for="(item, index) in imgNeedList"
                :key="index"
              >
                {{ item.text }}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="可添加备注" v-if="!typeShow">
            <a-radio-group v-model="createQuesForm.answerNeed">
              <a-radio
                :value="item.val"
                v-for="(item, index) in remarkList"
                :key="index"
              >
                {{ item.text }}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="是否添加不符合选项" v-if="!typeShow">
            <a-radio-group v-model="reasonShow">
              <a-radio
                :value="item.val"
                v-for="(item, index) in remarkList"
                :key="index"
              >
                {{ item.text }}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            label="可选理由"
            class="opt-box"
            v-if="!typeShow && reasonShow"
            required
          >
            <div
              v-for="(item, index) in createQuesForm.reasonList"
              :key="index"
              class="opt-item"
            >
              <a-form-model-item
                :prop="'reasonList.' + index + '.val'"
                :rules="[
                  {
                    required: true,
                    message: '请输入内容',
                    trigger: 'blur',
                  },
                ]"
              >
                <a-input
                  placeholder="请输入内容"
                  :maxLength="50"
                  style="width: 120px"
                  v-model.trim="item.val"
                />
              </a-form-model-item>
              <div
                class="del"
                @click="del(index)"
                v-if="createQuesForm.reasonList.length > 1"
              >
                <img src="../assets/image/delete.png" alt="" />
              </div>
            </div>
            <div class="add" @click="add">添加</div>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { getQuestionGroup } from "../common/api/questions";
import { getTag } from "../common/api/tag";
import { uploadImg } from "../common/api/order";
import { cloneDeep, debounce } from "lodash";
import $http from "../common/http";

export default {
  name: "CreateQuesModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
      percent: 0,
      manualShow: false,
      tagList: [],
      questionGroupList: [],
      groupList: ["餐饮", "门店卫生"],
      imgNeedList: [
        {
          text: "必须",
          val: 2,
        },
        {
          text: "允许",
          val: 1,
        },
        {
          text: "禁止",
          val: 0,
        },
      ],
      typeList: [
        {
          text: "单选",
          val: 2,
        },
        {
          text: "录入",
          val: 1,
        },
      ],
      manualList: [
        {
          text: "是",
          val: true,
        },
        {
          text: "否",
          val: false,
        },
      ],
      remarkList: [
        {
          text: "是",
          val: true,
        },
        {
          text: "否",
          val: false,
        },
      ],
      charsList: [
        {
          text: "文本",
          val: false,
        },
        {
          text: "数字",
          val: true,
        },
      ],
      isShow: false,
      createQuesForm: {
        itemGroupId: undefined,
        tagsId: [],
        name: "",
        judgment: "",
        imgsDemo: "",
        manual: false,
        imgNeed: 0,
        answerNeed: false,
        numberMax: "",
        numberMin: "",
        optionsList: [
          {
            val: "",
            score: "",
          },
        ],
        reasonList: [
          {
            val: "",
          },
        ],
        scoresList: [
          {
            val: "",
            score: "",
          },
        ],
        typeId: 2,
        size: "",
        unit: "",
        number: false,
        manualMax: "",
        manualMin: "",
      },
      labelCol: { span: 7 },
      wrapperCol: { span: 16, offset: 1 },
      rules: {
        itemGroupId: [
          { required: true, message: "请选择问题组名称", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入问题概述", trigger: "blur" }],

        size: [
          { required: true, message: "请输入文本长度限制", trigger: "blur" },
        ],
        numberMax: [
          { required: true, message: "请输入数字最大值", trigger: "blur" },
          {
            validator: this.validateNumberMax,
            trigger: "blur",
          },
        ],
        numberMin: [
          { required: true, message: "请输入数字最小值", trigger: "blur" },
          {
            validator: this.validateNumberMin,
            trigger: "blur",
          },
        ],
        unit: [{ required: true, message: "请输入单位", trigger: "blur" }],
        manualMin: [
          {
            validator: this.validateManualMin,
            trigger: "blur",
          },
        ],
        manualMax: [
          {
            validator: this.validateManualMax,
            trigger: "blur",
          },
        ],
      },
      fileList: [],
      reasonShow: false,
      typeShow: false,
      charsShow: false,
      params: {
        page: 0,
        size: 1000,
        name: "",
      },
    };
  },
  methods: {
    handleSearch: debounce(function (e) {
      this.params.name = e;
      this.getQuestionGroupList();
    }, 500),

    handleChange(e) {
      console.log("问题组：", e);
      this.createQuesForm.itemGroupId = e;

      this.$refs.ruleForm.clearValidate(["itemGroupId"]);
    },
    handleBlur() {
      this.params.name = "";
      this.getQuestionGroupList();
    },
    validateManualMin(rule, value, callback) {
      if (!value && this.createQuesForm.manualMax) {
        callback(new Error("请输入可评最低分值"));
      } else if (
        this.createQuesForm.manualMax &&
        value > this.createQuesForm.manualMax
      ) {
        callback(new Error("最低分值应不大于满分分值"));
      } else {
        callback();
      }
    },
    validateManualMax(rule, value, callback) {
      if (!value && this.createQuesForm.manualMin) {
        callback(new Error("请输入本题满分分值"));
      } else if (
        this.createQuesForm.manualMin &&
        value < this.createQuesForm.manualMin
      ) {
        callback(new Error("满分分值应不小于最低分值"));
      } else {
        callback();
      }
    },
    validateNumberMin(rule, value, callback) {
      if (
        this.createQuesForm.numberMax &&
        value >= this.createQuesForm.numberMax
      ) {
        callback(new Error("数字最小值应小于数字最大值"));
      } else {
        callback();
      }
    },
    validateNumberMax(rule, value, callback) {
      if (
        this.createQuesForm.numberMin &&
        value <= this.createQuesForm.numberMin
      ) {
        callback(new Error("数字最大值应大于数字最小值"));
      } else {
        callback();
      }
    },
    filterOption(value, option) {
      console.log("value", value);
      console.log("option", option);
      return option.componentOptions.children[0].text.indexOf(value) >= 0;
    },
    // 获取Tag列表
    async getTagList() {
      const res = await getTag({
        page: 0,
        size: 1000,
      });
      console.log("tag", res);
      this.tagList = res.data.content;
    },

    // 获取问题组列表
    async getQuestionGroupList() {
      const res = await getQuestionGroup(this.params);
      // console.log("getQuestionGroup", res);
      this.questionGroupList = res.data.content;
    },
    handleOk: debounce(function () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const params = cloneDeep(this.createQuesForm);
          // console.log(params);
          if (params.typeId == 1) {
            params.manual = false;
            params.manualMax = 0;
            params.manualMin = 0;
            params.answerNeed = false;
            delete params.optionsList;
            delete params.reasonList;
            delete params.scoresList;
            if (params.number) {
              delete params.size;
            } else {
              delete params.unit;
              delete params.numberMax;
              delete params.numberMin;
            }
          } else {
            delete params.number;
            delete params.numberMax;
            delete params.numberMin;
            delete params.size;
            delete params.unit;
            let optionsList = cloneDeep(params.optionsList);
            let scoresList = cloneDeep(params.scoresList);
            let reasonList = cloneDeep(params.reasonList);

            optionsList.sort(function (a, b) {
              return b.score - a.score;
            });
            scoresList.sort(function (a, b) {
              return b.score - a.score;
            });

            console.log(optionsList, "排序");

            params.optionsList = optionsList
              .map((item) => {
                return item.val;
              })
              .join(",");
            params.scoresList = scoresList
              .map((item) => {
                return item.score;
              })
              .join(",");
            if (this.reasonShow) {
              params.reasonList = reasonList
                .map((item) => {
                  return item.val;
                })
                .join(",");
            } else {
              delete params.reasonList;
            }
          }
          console.log(params, "params");
          this.$emit("ok", params);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }, 500),
    handleCancel() {
      this.$emit("cancel");
      this.$refs.ruleForm.resetFields();
      this.manualShow = false;
      this.reasonShow = false;
      this.typeShow = false;
      this.charsShow = false;
      this.createQuesForm = {
        itemGroupId: undefined,
        tagsId: [],
        name: "",
        judgment: "",
        imgsDemo: "",
        manual: false,
        imgNeed: 0,
        answerNeed: false,
        numberMax: "",
        numberMin: "",
        optionsList: [
          {
            val: "",
            score: "",
          },
        ],
        reasonList: [
          {
            val: "",
          },
        ],
        scoresList: [
          {
            val: "",
            score: "",
          },
        ],
        typeId: 2,
        size: "",
        unit: "",
        number: false,
        manualMax: "",
        manualMin: "",
      };
      this.fileList = [];
    },

    // 自定义上传方法
    uploadFiles(data) {
      if (this.isLoading) return;
      let formData = new FormData();
      formData.append("file", data.file);
      // 调用公共上传方法
      // uploadImg(formData).then((res) => {
      //   console.log(res.data);
      // });
      $http.request
        .post("/api/v1/users/imageUpload", formData, {
          onUploadProgress: (progressEvent) => {
            this.isLoading = true;
            console.log(progressEvent);
            // 这里可以获取上传进度信息
            const { loaded, total } = progressEvent;
            console.log(`上传进度: ${Math.round((loaded / total) * 100)}%`);
            this.percent = Math.round((loaded / total) * 100);
          },
        })
        .then((res) => {
          this.isLoading = false;
          let file = this.fileFormatter(res.data);
          if (this.fileList.length >= 5) {
            return this.$message.info("最多添加5张图片");
          } else {
            this.fileList.push(file);
          }
          let urlList = [];
          this.fileList.forEach((v) => {
            urlList = [...urlList, v.url];
          });

          this.createQuesForm.imgsDemo = urlList.join(",");
        })
        .catch((err) => {
          this.$message.error("图片上传失败");
        });
    },
    // 对上传成功返回的数据进行格式化处理，格式化a-upload能显示在已上传列表中的格式
    fileFormatter(data) {
      let file = {
        uid: data.newFileName, // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
        name: data.newFileName, // 文件名
        status: "done", // 状态有：uploading done error removed
        url: `${$http.baseURL}/img${data.path}`,
      };
      return file;
    },
    // 删除文件
    handleDownloadFileRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      const arr = this.fileList.map((v) => {
        return v.url;
      });
      console.log(arr, "arr");
      this.createQuesForm.imgsDemo = arr.join(",");
    },

    // 添加不符合候选项
    add(type) {
      console.log(type);
      if (type === "option") {
        this.createQuesForm.optionsList.push({ val: "", score: "" });
        this.createQuesForm.scoresList.push({ val: "", score: "" });
      } else {
        this.createQuesForm.reasonList.push({ val: "" });
      }
    },
    // 删除不符合候选项
    del(index, type) {
      if (type) {
        this.createQuesForm.optionsList.splice(index, 1);
        this.createQuesForm.scoresList.splice(index, 1);
      } else {
        this.createQuesForm.reasonList.splice(index, 1);
      }
    },
    typeRadioChange(e) {
      console.log(e.target.value);
      if (e.target.value == 1) {
        this.typeShow = true;

        this.rules.manualMin = [];
        this.rules.manualMax = [];
      } else {
        this.typeShow = false;
        // this.createQuesForm.manual = false;
        // this.manualShow = false;
        if (this.createQuesForm.optionsList.length) {
          let newArray = this.createQuesForm.optionsList.filter(
            (item) => item.val && item.score
          );
          newArray.sort(function (a, b) {
            return b.score - a.score;
          });
          console.log(newArray, "newArray");
          if (newArray.length) {
            this.createQuesForm.manualMax = newArray[0].score;
          }
        } else {
          this.createQuesForm.optionsList = [{ val: "", score: "" }];
        }

        this.rules.manualMin = [
          {
            validator: this.validateManualMin,
            trigger: "blur",
          },
        ];
        this.rules.manualMax = [
          {
            validator: this.validateManualMax,
            trigger: "blur",
          },
        ];
        this.$refs["ruleForm"].clearValidate(["manualMin", "manualMax"]);
      }
    },
    charsRadioChange(e) {
      console.log(e.target.value);
      if (e.target.value) {
        this.charsShow = true;
      } else {
        this.charsShow = false;
      }
    },
    manualRadioChange(e) {
      console.log(e.target.value);
      if (e.target.value) {
        this.manualShow = true;
      } else {
        this.manualShow = false;
      }
    },
  },
  computed: {
    getManualDisabled() {
      const result = this.createQuesForm.optionsList.find(
        (v) => v.val && v.score
      );

      if (result) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    visible(newVal) {
      this.isShow = newVal;
    },
    "createQuesForm.optionsList": {
      handler(newVal) {
        // console.log(newVal, "newVal");
        this.createQuesForm.scoresList = [...newVal];
        let newArray = newVal.filter((item) => item.val && item.score);
        // console.log(newArray);
        if (newArray.length) {
          newArray.sort(function (a, b) {
            return b.score - a.score;
          });
          console.log(newArray, "newArray");
          this.createQuesForm.manualMax = newArray[0].score;
        } else {
          this.createQuesForm.manualMax = "";
        }
      },
      deep: true,
    },
  },
  created() {
    this.getTagList();
    this.getQuestionGroupList();
  },
};
</script>

<style scoped lang="scss">
.create-ques-modal::v-deep {
  .ant-modal-header {
    .ant-modal-title {
      text-align: center !important;
      color: #303030;
    }
  }
  .ant-modal-body {
    max-height: 650px;
    overflow: auto;

    .ant-form-item {
      margin-bottom: 10px;
      &.img-box {
        margin-bottom: 0;
      }

      &.opt-box {
        .ant-form-item-children {
          display: flex;
          flex-wrap: wrap;
          .opt-item {
            margin-right: 20px;
            position: relative;
            .ant-form-item,
            .ant-form-item-control-wrapper,
            .ant-form-item-control {
              display: inline-block;
            }
            .ant-input {
              margin-top: 5px;
            }
            .del {
              position: absolute;
              top: -18px;
              right: -10px;
              cursor: pointer;
              img {
                width: 10px;
                height: 10px;
              }
            }
          }

          .add {
            color: #3298f1;
            cursor: pointer;
          }
        }
      }

      .manual-box {
        display: flex;
        .manual-item,
        .label {
          margin-left: 20px;
        }
      }

      .ant-select {
        width: 350px;
        .ant-select-search__field__wrap {
          width: 60px;
        }
      }

      .ant-upload-text img {
        width: 26px;
        height: 26px;
      }
      .ant-upload.ant-upload-select-picture-card {
        margin: 0;
      }
    }
  }
  .ant-modal-footer {
    text-align: center;
    padding-bottom: 44px;
    border: 0;
    div button {
      width: 72px;
      height: 32px;
      &:nth-child(2) {
        margin-left: 80px;
        background: #83ba7b;
        color: #fff;
        border: 0;
      }
    }
  }
}
</style>
