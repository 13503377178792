import { render, staticRenderFns } from "./BatchImport.vue?vue&type=template&id=9cb9b558&scoped=true&"
import script from "./BatchImport.vue?vue&type=script&lang=js&"
export * from "./BatchImport.vue?vue&type=script&lang=js&"
import style0 from "./BatchImport.vue?vue&type=style&index=0&id=9cb9b558&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9cb9b558",
  null
  
)

export default component.exports