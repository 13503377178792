<template>
  <div class="QuestionDetails">
    <div class="question-details-header">
      <div class="question-details-header-top">
        <div class="question-details-title">
          <div class="oblong"></div>
          <div>问题详情</div>
        </div>
        <div class="question-details-option">
          <div v-show="flag" class="edit common" @click="editQuesInfo">
            编辑
          </div>
          <div class="btn" v-show="!flag">
            <div class="save common" @click="save">保存</div>
            <div class="cancel common" @click="cancelQuesInfo">取消</div>
          </div>
        </div>
      </div>
      <div class="question-details-header-bottom">
        <div class="question-info">
          <a-form-model
            :model="createQuesForm"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            ref="ruleForm"
            :rules="rules"
            :colon="false"
            :afterClose="() => $refs.ruleForm.resetFields()"
          >
            <a-form-model-item label="问题ID">
              {{ questionDetails.id }}
            </a-form-model-item>
            <a-form-model-item label="问题组名称" prop="itemGroupId">
              <div v-if="flag">{{ questionDetails.groupName }}</div>
              <a-select
                v-else
                allowClear
                placeholder="请选择问题组"
                v-model="createQuesForm.itemGroupId"
              >
                <a-select-option
                  v-for="item in questionGroupList"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="带有的Tag">
              <div v-if="flag" class="tags">
                <template
                  v-if="questionDetails.tags && questionDetails.tags.length"
                >
                  <div
                    v-for="item in questionDetails.tags"
                    :key="item.id"
                    :style="{ color: item.color }"
                    class="tag"
                  >
                    {{ item.name }}
                  </div>
                </template>

                <div v-else>/</div>
              </div>
              <a-select
                v-else
                allowClear
                mode="multiple"
                showSearch
                :filterOption="filterOption"
                :autoClearSearchValue="true"
                :maxTagCount="2"
                class="y_select"
                :maxTagTextLength="5"
                placeholder="请选择Tag"
                v-model="createQuesForm.tagsId"
              >
                <a-select-option
                  v-for="item in tagList"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="问题概述" prop="name">
              <div v-if="flag">{{ questionDetails.name }}</div>
              <a-textarea
                v-else
                placeholder="请输入"
                :auto-size="{ minRows: 4, maxRows: 8 }"
                :maxLength="500"
                style="width: 480px"
                v-model.trim="createQuesForm.name"
              />
            </a-form-model-item>
            <a-form-model-item label="问题说明">
              <div v-if="flag">
                {{ questionDetails.judgment ? questionDetails.judgment : "/" }}
              </div>
              <a-textarea
                v-else
                placeholder="请输入"
                :auto-size="{ minRows: 4, maxRows: 8 }"
                :maxLength="500"
                style="width: 480px"
                v-model.trim="createQuesForm.judgment"
              />
            </a-form-model-item>
            <a-form-model-item
              label="添加图片说明"
              class="img-box"
              v-if="fileList.length || !flag"
            >
              <div v-if="flag && !fileList.length">/</div>
              <a-upload
                v-else
                list-type="picture-card"
                :file-list="fileList"
                :customRequest="uploadFiles"
                :remove="handleDownloadFileRemove"
                :showUploadList="{
                  showRemoveIcon: !flag,
                }"
                :multiple="true"
                accept="image/*"
              >
                <div v-if="fileList.length < 5 && !flag">
                  <a-progress
                    v-if="isLoading"
                    :percent="percent"
                    size="small"
                  />
                  <div class="ant-upload-text" v-else>
                    <img src="../assets/image/add.png" alt="" />
                  </div>
                </div>
              </a-upload>
            </a-form-model-item>

            <a-form-model-item label="应答类型">
              <div v-if="flag">
                {{ questionDetails.type == "SELECT" ? "单选" : "录入" }}
              </div>
              <a-radio-group
                v-else
                v-model="createQuesForm.typeId"
                @change="typeRadioChange"
              >
                <a-radio
                  :value="item.val"
                  v-for="item in typeList"
                  :key="item.id"
                >
                  {{ item.text }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>

            <div v-if="typeShow">
              <a-form-model-item label="字符类型选择">
                <div v-if="flag">
                  {{ createQuesForm.number ? "数字" : "文本" }}
                </div>
                <a-radio-group
                  v-else
                  @change="charsRadioChange"
                  v-model="createQuesForm.number"
                >
                  <a-radio
                    :value="item.val"
                    v-for="(item, index) in charsList"
                    :key="index"
                  >
                    {{ item.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item
                label="文本长度限制"
                v-if="!charsShow"
                prop="size"
              >
                <div v-if="flag">
                  {{ createQuesForm.size ? createQuesForm.size : "/" }}
                </div>
                <a-input
                  v-else
                  allowClear
                  placeholder="请输入"
                  type="number"
                  :maxLength="50"
                  style="width: 120px"
                  v-model.trim="createQuesForm.size"
                />
              </a-form-model-item>
              <div v-if="charsShow">
                <a-form-model-item label="数字最大值" prop="numberMax">
                  <div v-if="flag">{{ createQuesForm.numberMax }}</div>
                  <a-input
                    v-else
                    placeholder="请输入"
                    :maxLength="50"
                    type="number"
                    style="width: 120px"
                    v-model.trim="createQuesForm.numberMax"
                  />
                </a-form-model-item>
                <a-form-model-item label="数字最小值" prop="numberMin">
                  <div v-if="flag">{{ createQuesForm.numberMin }}</div>
                  <a-input
                    v-else
                    placeholder="请输入"
                    :maxLength="50"
                    type="number"
                    style="width: 120px"
                    v-model.trim="createQuesForm.numberMin"
                  />
                </a-form-model-item>

                <a-form-model-item label="单位" prop="unit">
                  <div v-if="flag">{{ createQuesForm.unit }}</div>
                  <a-input
                    v-else
                    placeholder="请输入"
                    :maxLength="50"
                    style="width: 120px"
                    v-model.trim="createQuesForm.unit"
                  />
                </a-form-model-item>
              </div>
            </div>

            <a-form-model-item
              label="应答项和分值"
              class="opt-box"
              v-if="!typeShow"
              required
            >
              <div v-if="flag">
                <div
                  v-for="(item, index) in questionDetails.optionsList"
                  :key="index"
                  v-show="item.val"
                >
                  <span>{{ item.val }}</span>
                  <span> ── </span>
                  <span>{{ item.score }}</span>
                </div>
              </div>
              <div
                v-else
                v-for="(item, index) in createQuesForm.optionsList"
                :key="index"
                class="opt-item"
              >
                <a-form-model-item
                  :prop="'optionsList.' + index + '.val'"
                  :rules="[
                    {
                      required: true,
                      message: '请输入内容',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <a-input
                    placeholder="请输入内容"
                    :maxLength="50"
                    style="width: 120px"
                    v-model.trim="item.val"
                  />
                </a-form-model-item>
                <span> ── </span>
                <a-form-model-item
                  :prop="'scoresList.' + index + '.score'"
                  :rules="[
                    {
                      required: true,
                      message: '请输入分数',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <a-input
                    placeholder="请输入分数"
                    :maxLength="50"
                    style="width: 120px"
                    type="number"
                    oninput="value=value.replace('.', '',).replace('-', '',)"
                    v-model.trim="item.score"
                  />
                </a-form-model-item>
                <div
                  class="del"
                  @click="del(index, 'option')"
                  v-if="createQuesForm.optionsList.length > 1"
                >
                  <img src="../assets/image/delete.png" alt="" />
                </div>
              </div>
              <div class="add" @click="add('option')" v-if="!flag">添加</div>
            </a-form-model-item>
            <a-form-model-item label="人工评分" required v-if="!typeShow">
              <div v-if="flag && createQuesForm.typeId == 2">
                {{ questionDetails.manual ? "是" : "否" }}
              </div>
              <a-radio-group
                v-if="!flag"
                v-model="createQuesForm.manual"
                @change="manualRadioChange"
              >
                <a-radio
                  :value="item.val"
                  v-for="(item, index) in manualList"
                  :key="index"
                >
                  {{ item.text }}
                </a-radio>
              </a-radio-group>
              <div class="manual-box" v-if="typeShow || manualShow">
                <span>可评最低分值</span>
                <a-form-model-item class="manual-item" prop="manualMin">
                  <a-input
                    placeholder="请输入"
                    style="width: 100px"
                    type="number"
                    :disabled="flag"
                    oninput="value=value.replace('.', '',).replace('-', '',)"
                    v-model.trim="createQuesForm.manualMin"
                  />
                </a-form-model-item>
                <span class="label">本题满分分值</span>
                <a-form-model-item class="manual-item" prop="manualMax">
                  <a-input
                    placeholder="请输入"
                    style="width: 100px"
                    type="number"
                    oninput="value=value.replace('.', '',).replace('-', '',)"
                    v-model.trim="createQuesForm.manualMax"
                    :disabled="flag || getManualDisabled"
                  />
                </a-form-model-item>
              </div>
            </a-form-model-item>

            <a-form-model-item label="可添加照片">
              <div v-if="flag">
                <span v-show="questionDetails.imgNeed == 0">禁止</span>
                <span v-show="questionDetails.imgNeed == 1">允许</span
                ><span v-show="questionDetails.imgNeed == 2">必须</span>
              </div>
              <a-radio-group v-model="createQuesForm.imgNeed" v-else>
                <a-radio
                  :value="item.val"
                  v-for="(item, index) in imgNeedList"
                  :key="index"
                >
                  {{ item.text }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="可添加备注" v-if="!typeShow">
              <div v-if="flag">
                {{ questionDetails.answerNeed ? "是" : "否" }}
              </div>
              <a-radio-group v-model="createQuesForm.answerNeed" v-else>
                <a-radio
                  :value="item.val"
                  v-for="(item, index) in remarkList"
                  :key="index"
                >
                  {{ item.text }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="是否添加不符合选项" v-if="!typeShow">
              <div v-if="flag">{{ reasonShow ? "是" : "否" }}</div>
              <a-radio-group v-model="reasonShow" v-else>
                <a-radio
                  :value="item.val"
                  v-for="(item, index) in remarkList"
                  :key="index"
                >
                  {{ item.text }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item
              label="可选理由"
              class="opt-box"
              v-if="!typeShow && reasonShow"
              required
            >
              <div v-if="flag">
                <div
                  v-for="(item, index) in questionDetails.reasonList"
                  :key="index"
                  v-show="item.val"
                  class="opt-item"
                >
                  {{ item.val }}
                </div>
              </div>
              <div
                v-else
                v-for="(item, index) in createQuesForm.reasonList"
                :key="index"
                class="opt-item"
              >
                <a-form-model-item
                  :prop="'reasonList.' + index + '.val'"
                  :rules="[
                    {
                      required: true,
                      message: '请输入内容',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <a-input
                    placeholder="请输入内容"
                    :maxLength="50"
                    style="width: 120px"
                    v-model.trim="item.val"
                  />
                </a-form-model-item>
                <div
                  class="del"
                  @click="del(index)"
                  v-if="createQuesForm.reasonList.length > 1"
                >
                  <img src="../assets/image/delete.png" alt="" />
                </div>
              </div>
              <div class="add" @click="add" v-if="!flag">添加</div>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="question-details-body">
      <div class="question-details-body-top">
        <div class="question-details-title">
          <div class="oblong"></div>
          <div>已应用该问题的问卷模板</div>
        </div>
      </div>

      <div class="question-details-body-bottom">
        <div class="question-info">
          <a-table
            :columns="columns"
            :data-source="tempList"
            :rowKey="(record) => record.id"
            :loading="loading"
            :pagination="pagination"
            :customRow="rowClick"
          >
          </a-table>
        </div>
      </div>
    </div>
    <div ref="handleRefModel" class="handle-modal">
      <a-modal
        v-model="saveVisible"
        title="提示"
        @ok="handleOk"
        :maskClosable="false"
        centered
        width="450px"
        :getContainer="() => $refs.handleRefModel"
        cancelText="否"
        okText="是"
      >
        <div>
          更改此问题的名称和内容，同时应用于问卷模板里面的问题也会进行相应的更改！！其他设置不变，需手动更改！
        </div>
        <span>是否继续保存？</span>
      </a-modal>
    </div>
  </div>
</template>

<script>
import {
  getQuestionGroup,
  getQuestionDetails,
  editQuestion,
  getTemplateById,
} from "../common/api/questions";
import { getTag } from "../common/api/tag";
import $http from "../common/http";
import { cloneDeep } from "lodash";
const columns = [
  {
    title: "问卷模板名称",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "更新时间",
    dataIndex: "updateTime",
    key: "updateTime",
    align: "center",
  },
];
export default {
  name: "QuestionDetails",
  props: {},
  components: {},
  data() {
    return {
      percent: 0,
      isLoading: false,
      saveVisible: false,
      manualShow: false,
      tagList: [],
      questionGroupList: [],
      groupList: ["餐饮", "门店卫生"],
      imgNeedList: [
        {
          text: "必须",
          val: 2,
        },
        {
          text: "允许",
          val: 1,
        },
        {
          text: "禁止",
          val: 0,
        },
      ],
      typeList: [
        {
          text: "单选",
          val: 2,
        },
        {
          text: "录入",
          val: 1,
        },
      ],
      manualList: [
        {
          text: "是",
          val: true,
        },
        {
          text: "否",
          val: false,
        },
      ],
      remarkList: [
        {
          text: "是",
          val: true,
        },
        {
          text: "否",
          val: false,
        },
      ],
      charsList: [
        {
          text: "文本",
          val: false,
        },
        {
          text: "数字",
          val: true,
        },
      ],
      createQuesForm: {
        itemGroupId: undefined,
        tagsId: [],
        name: "",
        judgment: "",
        imgsDemo: "",
        manual: false,
        imgNeed: 0,
        answerNeed: false,
        numberMax: "",
        numberMin: "",
        optionsList: [],
        reasonList: [
          {
            val: "",
          },
        ],
        scoresList: [
          {
            val: "",
            score: "",
          },
        ],
        typeId: 2,
        size: "",
        unit: "",
        number: false,
        manualMax: "",
        manualMin: "",
      },
      labelCol: { span: 5 },
      wrapperCol: { span: 12, offset: 1 },
      rules: {
        itemGroupId: [
          { required: true, message: "请选择问题组名称", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入问题概述", trigger: "blur" }],

        size: [
          { required: true, message: "请输入文本长度限制", trigger: "blur" },
        ],
        numberMax: [
          { required: true, message: "请输入数字最大值", trigger: "blur" },
          {
            validator: this.validateNumberMax,
            trigger: "blur",
          },
        ],
        numberMin: [
          { required: true, message: "请输入数字最小值", trigger: "blur" },
          {
            validator: this.validateNumberMin,
            trigger: "blur",
          },
        ],
        unit: [{ required: true, message: "请输入单位", trigger: "blur" }],
        manualMin: [
          {
            validator: this.validateManualMin,
            trigger: "blur",
          },
        ],
        manualMax: [
          {
            validator: this.validateManualMax,
            trigger: "blur",
          },
        ],
      },
      fileList: [],
      reasonShow: false,
      typeShow: false,
      charsShow: false,
      //问题id
      id: "",
      // 点击表格行
      rowClick: (record) => ({
        // 事件
        on: {
          click: () => {
            // console.log(record, "record");
            // this.$router.push({
            //   path: "/orderdetails",
            //   query: {
            //     id: record.id,
            //   },
            // });
          },
        },
      }),
      tempList: [],
      columns,
      loading: false,
      // 分页配置对象
      pagination: {
        position: "bottom",
        pageSize: 5,
        current: 1,
        total: 0,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["5", "10", "20"], // 每页数量选项
        showQuickJumper: true,
        // 显示总数
        showTotal: (total) => `共 ${total} 条`,
        // 改变每页数量时更新显示
        onShowSizeChange: (page, pageSize) => this.onSizeChange(page, pageSize),
        // 点击页码事件
        onChange: (page, pageSize) => this.onPageChange(page, pageSize),
      },
      questionDetails: {},
      form: {},
      flag: true,
      labelCol: { span: 5 },
      wrapperCol: { span: 8, offset: 1 },
    };
  },
  methods: {
    async handleOk() {
      const params = cloneDeep(this.createQuesForm);
      // console.log(params);
      if (params.typeId == 1) {
        params.manual = false;
        params.manualMax = 0;
        params.manualMin = 0;
        params.answerNeed = false;
        delete params.optionsList;
        delete params.reasonList;
        delete params.scoresList;
        if (params.number) {
          delete params.size;
        } else {
          delete params.unit;
          delete params.numberMax;
          delete params.numberMin;
        }
      } else {
        delete params.number;
        delete params.numberMax;
        delete params.numberMin;
        delete params.size;
        delete params.unit;
        let optionsList = cloneDeep(params.optionsList);
        let scoresList = cloneDeep(params.scoresList);
        let reasonList = cloneDeep(params.reasonList);

        optionsList.sort(function (a, b) {
          return b.score - a.score;
        });
        scoresList.sort(function (a, b) {
          return b.score - a.score;
        });

        console.log(optionsList, "排序");

        params.optionsList = optionsList
          .map((item) => {
            return item.val;
          })
          .join(",");
        params.scoresList = scoresList
          .map((item) => {
            return item.score;
          })
          .join(",");
        if (this.reasonShow) {
          params.reasonList = reasonList
            .map((item) => {
              return item.val;
            })
            .join(",");
        } else {
          delete params.reasonList;
        }
      }
      params.idx = this.questionDetails.id;
      console.log(params, "params");
      const res = await editQuestion(params);
      console.log(res);
      this.$message.success(res.msg);
      this.$store.commit("updateRefreshPath", "question");
      this.flag = true;
      this.saveVisible = false;
      this.getQuestionDetails();
    },
    // 改变每页数量时更新显示
    onSizeChange(page, pageSize) {
      this.pagination.current = 1;
      this.pagination.pageSize = pageSize;
      this.form.page = 0;
      this.form.size = pageSize;
      this.getTemplateById();
    },
    // 点击页码事件
    onPageChange(page) {
      this.pagination.current = page;
      this.form.page = page - 1;
      this.getTemplateById();
    },
    async getTemplateById() {
      this.loading = true;
      const res = await getTemplateById({ id: this.id });
      this.loading = false;
      console.log(res, "模板");
      this.tempList = res.data.content;
      this.pagination.total = res.data.totalElements;
    },
    validateManualMin(rule, value, callback) {
      if (!value.toString() && this.createQuesForm.manualMax) {
        callback(new Error("请输入可评最低分值"));
      } else if (
        this.createQuesForm.manualMax &&
        value > this.createQuesForm.manualMax
      ) {
        callback(new Error("最低分值应小于满分分值"));
      } else {
        callback();
      }
    },
    validateManualMax(rule, value, callback) {
      if (!value.toString() && this.createQuesForm.manualMin) {
        callback(new Error("请输入本题满分分值"));
      } else if (
        this.createQuesForm.manualMin &&
        value < this.createQuesForm.manualMin
      ) {
        callback(new Error("满分分值应大于最低分值"));
      } else {
        callback();
      }
    },
    validateNumberMin(rule, value, callback) {
      if (
        this.createQuesForm.numberMax &&
        value >= this.createQuesForm.numberMax
      ) {
        callback(new Error("数字最小值应小于数字最大值"));
      } else {
        callback();
      }
    },
    validateNumberMax(rule, value, callback) {
      if (
        this.createQuesForm.numberMin &&
        value <= this.createQuesForm.numberMin
      ) {
        callback(new Error("数字最大值应大于数字最小值"));
      } else {
        callback();
      }
    },
    filterOption(value, option) {
      console.log("value", value);
      console.log("option", option);
      return option.componentOptions.children[0].text.indexOf(value) >= 0;
    },
    // 获取Tag列表
    async getTagList() {
      const res = await getTag({
        page: 0,
        size: 100,
      });
      console.log("tag", res);
      this.tagList = res.data.content;
    },

    // 获取问题组列表
    async getQuestionGroupList() {
      const res = await getQuestionGroup({
        page: 0,
        size: 1000,
      });
      console.log("getQuestionGroup", res);
      this.questionGroupList = res.data.content;
    },
    save() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.saveVisible = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // 自定义上传方法
    uploadFiles(data) {
      if (this.isLoading) return;
      let formData = new FormData();
      formData.append("file", data.file);

      $http.request
        .post("/api/v1/users/imageUpload", formData, {
          onUploadProgress: (progressEvent) => {
            this.isLoading = true;
            console.log(progressEvent);
            // 这里可以获取上传进度信息
            const { loaded, total } = progressEvent;
            console.log(`上传进度: ${Math.round((loaded / total) * 100)}%`);
            this.percent = Math.round((loaded / total) * 100);
          },
        })
        .then((res) => {
          this.isLoading = false;
          let file = this.fileFormatter(res.data);
          if (this.fileList.length >= 5) {
            return this.$message.info("最多添加5张图片");
          } else {
            this.fileList.push(file);
          }
          let urlList = [];
          this.fileList.forEach((v) => {
            urlList = [...urlList, v.url];
          });

          this.createQuesForm.imgsDemo = urlList.join(",");
        });
    },
    // 对上传成功返回的数据进行格式化处理，格式化a-upload能显示在已上传列表中的格式
    fileFormatter(data) {
      let file = {
        uid: data.newFileName, // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
        name: data.newFileName, // 文件名
        status: "done", // 状态有：uploading done error removed
        url: `${$http.baseURL}/img${data.path}`,
      };
      return file;
    },
    // 删除文件
    handleDownloadFileRemove(file) {
      console.log(file, "file");
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      console.log(newFileList, "newFileList");
      this.fileList = newFileList;
      const arr = this.fileList.map((v) => {
        return v.url;
      });
      this.createQuesForm.imgsDemo = arr.join(",");
    },

    // 添加不符合候选项
    add(type) {
      console.log(type);
      if (type === "option") {
        this.createQuesForm.optionsList.push({ val: "", score: "" });
        this.createQuesForm.scoresList.push({ val: "", score: "" });
      } else {
        this.createQuesForm.reasonList.push({ val: "" });
      }
    },
    // 删除不符合候选项
    del(index, type) {
      if (type) {
        this.createQuesForm.optionsList.splice(index, 1);
        this.createQuesForm.scoresList.splice(index, 1);
      } else {
        this.createQuesForm.reasonList.splice(index, 1);
      }
    },
    typeRadioChange(e) {
      console.log(e.target.value);
      if (e.target.value == 1) {
        this.typeShow = true;

        this.rules.manualMin = [];
        this.rules.manualMax = [];
      } else {
        this.typeShow = false;
        // this.createQuesForm.manual = false;
        // this.manualShow = false;
        if (this.createQuesForm.optionsList.length) {
          let newArray = this.createQuesForm.optionsList.filter(
            (item) => item.val && item.score
          );
          newArray.sort(function (a, b) {
            return b.score - a.score;
          });
          console.log(newArray, "newArray");
          if (newArray.length) {
            this.createQuesForm.manualMax = newArray[0].score;
          }
        } else {
          this.createQuesForm.optionsList = [{ val: "", score: "" }];
        }

        this.rules.manualMin = [
          {
            validator: this.validateManualMin,
            trigger: "blur",
          },
        ];
        this.rules.manualMax = [
          {
            validator: this.validateManualMax,
            trigger: "blur",
          },
        ];
        this.$refs["ruleForm"].clearValidate(["manualMin", "manualMax"]);
      }
    },
    charsRadioChange(e) {
      console.log(e.target.value);
      if (e.target.value) {
        this.charsShow = true;
      } else {
        this.charsShow = false;
      }
    },
    manualRadioChange(e) {
      console.log(e.target.value);
      if (e.target.value) {
        this.manualShow = true;
      } else {
        this.manualShow = false;
      }
    },
    handleCreateQuesForm() {
      Object.keys(this.createQuesForm).forEach((key) => {
        this.createQuesForm[key] = this.questionDetails[key];
      });

      if (this.questionDetails.type === "TEXT") {
        this.createQuesForm.typeId = 1;
        this.createQuesForm.manualMax = "";
        this.createQuesForm.manualMin = "";
        this.typeShow = true;

        this.charsShow = this.questionDetails.number;
      } else if (this.questionDetails.type === "SELECT") {
        this.createQuesForm.typeId = 2;
        this.typeShow = false;
      }

      this.createQuesForm.itemGroupId = this.questionDetails.groupId;
      if (this.questionDetails.tags.length) {
        this.createQuesForm.tagsId = this.questionDetails.tags.map(
          (obj) => obj.id
        );
      }
      if (this.questionDetails.imgsDemo) {
        const newArray = this.questionDetails.imgsDemo.split(",");
        this.fileList = [];
        newArray.forEach((url, index) => {
          let file = {
            uid: index, // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
            name: index.toString(), // 文件名
            status: "done", // 状态有：uploading done error removed
            url,
          };
          this.fileList.push(file);
        });
      } else {
        this.fileList = [];
      }

      let arr = [];
      this.questionDetails.optionsList.forEach((val, index) => {
        let obj = {
          val,
          score: this.createQuesForm.scoresList[index],
        };

        arr.push(obj);
      });
      this.createQuesForm.optionsList = arr;

      this.questionDetails.optionsList = this.createQuesForm.optionsList;
      this.questionDetails.scoresList = this.createQuesForm.optionsList;

      if (this.questionDetails.reasonList.length) {
        this.createQuesForm.reasonList = [];
        this.questionDetails.reasonList.forEach((val) => {
          let obj = {
            val,
          };
          this.createQuesForm.reasonList.push(obj);
        });
        this.reasonShow = true;
      } else {
        this.createQuesForm.reasonList = [{ val: "" }];
        this.reasonShow = false;
      }
      this.questionDetails.reasonList = this.createQuesForm.reasonList;
    },

    // 获取问题详情
    async getQuestionDetails() {
      const res = await getQuestionDetails({ id: this.id });
      console.log(res);
      this.questionDetails = res.data;
      this.handleCreateQuesForm();
    },

    cancelQuesInfo() {
      this.flag = true;
      this.getQuestionDetails();
    },
    editQuesInfo() {
      this.flag = false;
    },
  },
  computed: {
    getManualDisabled() {
      const result = this.createQuesForm.optionsList.find(
        (v) => v.val && v.score
      );

      if (result) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    "createQuesForm.optionsList": {
      handler(newVal) {
        console.log(newVal, "newVal");
        this.createQuesForm.scoresList = [...newVal];
        let newArray = newVal.filter((item) => item.val && item.score);
        // console.log(newArray);
        if (newArray.length && this.createQuesForm.typeId == 2) {
          newArray.sort(function (a, b) {
            return b.score - a.score;
          });
          console.log(newArray, "newArray");
          this.createQuesForm.manualMax = newArray[0].score;
        } else if (this.createQuesForm.typeId == 2) {
          this.createQuesForm.manualMax = "";
        }
      },
      deep: true,
    },
    "createQuesForm.reasonList": {
      handler(newVal) {
        console.log(newVal, "原因");
        if (newVal.length == 1 && !newVal[0].val) {
          this.reasonShow = false;
        } else {
          this.reasonShow = true;
        }
      },
      deep: true,
      immediate: true,
    },
    "createQuesForm.manual": {
      handler(newVal) {
        if (newVal) {
          this.manualShow = true;
        } else {
          this.manualShow = false;
        }
      },
      deep: true,
    },
  },
  async created() {
    console.log(this.$route.query.id);
    this.id = this.$route.query.id;
    await this.getTagList();
    await this.getQuestionGroupList();
    this.getQuestionDetails();
    this.getTemplateById();
  },
};
</script>

<style scoped lang="scss">
.QuestionDetails {
  box-sizing: border-box;
  .question-details-header,
  .question-details-body,
  .question-details-footer {
    margin-bottom: 33px;
    padding: 24px 32px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 16px;
    .question-details-header-top {
      margin-bottom: 20px;
      padding-right: 35px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .question-details-title {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #303030;
        .oblong {
          margin-right: 8px;
          width: 4px;
          height: 24px;
          background: #83ba7b;
        }
      }
      .question-details-option {
        display: flex;
        .common {
          margin-left: 16px;
          width: 72px;
          height: 32px;
          background: #3298f1;
          text-align: center;
          line-height: 32px;
          color: #fff;
          border-radius: 4px;
          cursor: pointer;
        }
        .btn {
          display: flex;
          .cancel {
            background: rgba(191, 193, 198, 0.5);
          }
        }
        .delete {
          background: #e93024;
        }
      }
    }
    .question-details-header-bottom {
      margin-left: 13px;
      padding: 18px 18px;
      box-sizing: border-box;
      border: 2px solid rgba(191, 193, 198, 0.5019607843137255);
      font-size: 14px;
      color: #303030;

      .question-info {
        color: #303030;
        .ant-form-item::v-deep {
          margin-bottom: 4px;

          .tags {
            display: flex;
            align-items: center;

            .tag:nth-child(n + 2) {
              margin-left: 5px;
            }
          }
          &.img-box {
            margin-bottom: 0;
            img {
              width: 86px;
              height: 86px;
            }
          }
          &.opt-box {
            .ant-form-item-children {
              display: flex;
              flex-wrap: wrap;
              .opt-item {
                margin-right: 20px;
                position: relative;
                .ant-form-item,
                .ant-form-item-control-wrapper,
                .ant-form-item-control {
                  display: inline-block;
                }
                .ant-input {
                  margin-top: 5px;
                }
                .del {
                  position: absolute;
                  top: -18px;
                  right: -10px;
                  cursor: pointer;
                  img {
                    width: 10px;
                    height: 10px;
                  }
                }
              }

              .add {
                color: #3298f1;
                cursor: pointer;
              }
            }
          }

          .manual-box {
            display: flex;
            .manual-item,
            .label {
              margin-left: 20px;
            }
            .ant-select-disabled.ant-select:not(.ant-select-customize-input)
              .ant-select-selector,
            .ant-input-disabled,
            .ant-input-number-disabled {
              color: rgba(0, 0, 0, 0.65) !important;
              background: #fff !important;
              cursor: not-allowed !important;
              border: none;
            }
          }

          .ant-select {
            width: 350px;
            .ant-select-search__field__wrap {
              width: 60px;
            }
          }

          .ant-upload-text img {
            width: 26px;
            height: 26px;
          }
          .ant-upload.ant-upload-select-picture-card {
            margin: 0;
          }
        }
        /* .ant-form::v-deep {
          .ant-row {
            margin-bottom: 0;
            color: #303030;
            .ant-form-item-label,
            .ant-form-item-control {
              line-height: 32px;
            }
            

            &.hide {
              .ant-form-item-children {
                span {
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                  overflow: hidden;
                }
              }
            }

            &:nth-last-child(-n + 2) {
              margin-bottom: 0;
            }
            .ant-input {
              width: 130px;
              height: 30px;
              &.name {
                padding-left: 0;
              }
              &.num {
                margin-right: 5px;
                width: 52px;
                height: 30px;
              }
            }
            .ant-input[disabled] {
              background: #fff;
              color: #303030;
              border: 0;
            }
          }
        } */
      }
    }
    .question-details-body-bottom {
      margin-top: 20px;
    }
  }
  .handle-modal::v-deep {
    .ant-modal-header {
      .ant-modal-title {
        text-align: center !important;
      }
    }
    .ant-modal-body {
      line-height: 24px;
      span {
        color: #dc3333;
      }
    }
    .ant-modal-footer {
      text-align: center;
      padding-bottom: 44px;
      border: 0;
      div button {
        width: 72px;
        height: 32px;
        &:nth-child(2) {
          margin-left: 80px;
          background: #83ba7b;
          border: 0;
        }
      }
    }
  }
}
</style>
