<template>
  <div class="CreateTemplate">
    <div class="create-template-header">
      <div class="create-template-header-top">
        <div class="create-template-title">
          <div class="oblong"></div>
          <div>问卷模板列表</div>
        </div>
        <div class="create-template-btn">
          <div v-if="flag" class="top">
            <div @click="save" class="save">保存</div>
            <div class="cancel" @click="cancel">取消</div>
          </div>
          <div v-else @click="edit" class="edit">编辑</div>
        </div>
      </div>
      <div class="create-template-header-bottom">
        <a-form-model
          :model="form"
          :label-col="labelCol"
          :rules="rules"
          ref="ruleForm"
          :wrapper-col="wrapperCol"
          :colon="false"
        >
          <a-form-model-item label="问卷模板名称" prop="name">
            <a-input :disabled="!flag" v-model.trim="form.name" class="ipt" />
          </a-form-model-item>
          <a-form-model-item
            label="问题列表"
            v-if="questionList.length"
            class="question-list"
          >
            <div class="change" v-show="flag" @click="toQuestion">变更已选</div>
            <div
              class="question-item"
              v-for="(item, index) in questionList"
              :key="item.id"
            >
              <div class="header">
                <div>问题组名称</div>
                <div class="handle" v-show="flag">
                  <div @click="toTop(item, index)" v-show="index !== 0">
                    置顶
                  </div>
                  <div @click="moveUp(item, index)" v-show="index !== 0">
                    上移
                  </div>
                  <div
                    @click="moveDown(item, index)"
                    v-show="index !== questionList.length - 1"
                  >
                    下移
                  </div>
                  <div
                    @click="toBottom(item, index)"
                    v-show="index !== questionList.length - 1"
                  >
                    置底
                  </div>
                </div>
              </div>
              <div class="footer">
                <a-input
                  :disabled="!flag"
                  v-model.trim="item.name"
                  class="ipt"
                />
              </div>
              <a-table
                :columns="columns"
                :data-source="item.items"
                :rowKey="(record) => record.id"
                :pagination="false"
              >
                <div slot="name" slot-scope="name, record">
                  <a-input
                    :class="[flag ? 'white' : 'green']"
                    v-if="flag"
                    v-model.trim="record.name"
                    class="ipt"
                  />
                  <span v-else>{{ record.name }}</span>
                </div>
                <div slot="required" slot-scope="required, record, i">
                  <a-select
                    v-if="flag"
                    :value="record.required ? 1 : 2"
                    style="width: 100px"
                    @change="requiredChange($event, i, index)"
                  >
                    <a-select-option
                      v-for="item in requiredList"
                      :key="item.val"
                      :value="item.val"
                    >
                      {{ item.text }}
                    </a-select-option>
                  </a-select>
                  <div v-else>
                    {{ required ? "是" : "否" }}
                  </div>
                </div>
                <div slot="type" slot-scope="type, record">
                  <a-select
                    v-if="flag"
                    @change="typeChange($event, record)"
                    v-model="record.type"
                    style="width: 100px"
                  >
                    <a-select-option
                      v-for="item in typeList"
                      :key="item.val"
                      :value="item.val"
                    >
                      {{ item.text }}
                    </a-select-option>
                  </a-select>
                  <div v-else>{{ type === "SELECT" ? "单选" : "录入" }}</div>
                </div>

                <div slot="imgNeed" slot-scope="imgNeed, record">
                  <a-select
                    v-if="flag"
                    v-model="record.imgNeed"
                    style="width: 100px"
                  >
                    <a-select-option
                      v-for="item in imgNeedList"
                      :key="item.val"
                      :value="item.val"
                    >
                      {{ item.text }}
                    </a-select-option>
                  </a-select>
                  <div v-else>
                    <span v-if="imgNeed == 0">禁止</span>
                    <span v-if="imgNeed == 1">允许</span>
                    <span v-if="imgNeed == 2">必须</span>
                  </div>
                </div>
                <span slot="scoreStr" slot-scope="scoreStr, record">
                  <a-input
                    :class="[flag ? 'white' : 'green', 'ipt']"
                    :disabled="!flag || record.type == 'TEXT'"
                    v-model.trim="record.scoreStr"
                    :style="{
                      'text-align': 'center',
                      width: '100px',
                      'border-color': record.validate ? '#d9d9d9' : '#f5222d',
                    }"
                  />
                </span>
                <span slot="optionsStr" slot-scope="optionsStr, record">
                  <a-input
                    :class="[flag ? 'white' : 'green', 'ipt']"
                    :disabled="!flag || record.type == 'TEXT'"
                    v-model.trim="record.optionsStr"
                    :style="{
                      'text-align': 'center',
                      width: '100px',
                      'border-color': record.validate ? '#d9d9d9' : '#f5222d',
                    }"
                  />
                </span>

                <div slot="manual" slot-scope="manual, record, i">
                  <div v-if="flag">
                    <a-select
                      :value="record.manual ? 1 : 2"
                      v-if="record.type == 'SELECT'"
                      style="width: 100px"
                      @change="manualChange($event, i, index)"
                    >
                      <a-select-option
                        v-for="item in manualList"
                        :key="item.val"
                        :value="item.val"
                      >
                        {{ item.text }}
                      </a-select-option>
                    </a-select>

                    <a-input
                      v-else
                      :class="[flag ? 'white' : 'green', 'ipt']"
                      :style="{
                        'text-align': 'center',
                        width: '100px',
                      }"
                      value="/"
                      :disabled="true"
                    ></a-input>
                  </div>

                  <span v-else>
                    <span v-if="record.type == 'SELECT'">{{
                      manual ? "手动" : "自动"
                    }}</span>
                    <span v-else>/</span>
                  </span>
                </div>
              </a-table>
            </div>
          </a-form-model-item>
          <a-form-model-item label="添加问题" v-else>
            <div class="textarea-box">
              <a-input
                type="textarea"
                class="textarea"
                placeholder="点击转入问题库"
                :disabled="true"
              />
              <div class="mask" @click="toQuestion"></div>
            </div>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
import {
  createTemplate,
  getTemplateById,
  editTemplate,
} from "../common/api/template";
import { cloneDeep, debounce } from "lodash";

export default {
  name: "CreateTemplate",

  data() {
    return {
      copyData: [],
      id: "",
      requiredList: [
        {
          text: "是",
          val: 1,
        },
        {
          text: "否",
          val: 2,
        },
      ],
      imgNeedList: [
        {
          text: "必须",
          val: 2,
        },
        {
          text: "允许",
          val: 1,
        },
        {
          text: "禁止",
          val: 0,
        },
      ],
      typeList: [
        {
          text: "单选",
          val: "SELECT",
        },
        {
          text: "录入",
          val: "TEXT",
        },
      ],
      manualList: [
        {
          text: "手动",
          val: 1,
        },
        {
          text: "自动",
          val: 2,
        },
      ],
      type: "",
      flag: true,
      rules: {
        name: [
          { required: true, message: "请输入问卷模板名称", trigger: "blur" },
        ],
      },
      questionList: [],
      template: {},
      form: {
        name: "",
        template: [],
      },
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      columns: [
        {
          title: "问题名称",
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
          width: 500,
        },
        {
          title: "是否为必填项",
          dataIndex: "required",
          key: "required",
          scopedSlots: { customRender: "required" },
        },
        {
          title: "应答类型",
          dataIndex: "type",
          key: "type",
          scopedSlots: { customRender: "type" },
          align: "center",
        },
        {
          title: "是否添加图片",
          dataIndex: "imgNeed",
          key: "imgNeed",
          scopedSlots: { customRender: "imgNeed" },
          align: "center",
        },
        {
          title: "分值",
          dataIndex: "scoreStr",
          key: "scoreStr",
          scopedSlots: { customRender: "scoreStr" },
          align: "center",
        },
        {
          title: "应答设置",
          dataIndex: "optionsStr",
          key: "optionsStr",
          scopedSlots: { customRender: "optionsStr" },
          align: "center",
          width: 200,
        },
        {
          title: "评分方式",
          dataIndex: "manual",
          key: "manual",
          scopedSlots: { customRender: "manual" },
          align: "center",
        },
      ],
    };
  },
  methods: {
    typeChange(e, record) {
      console.log(e, record);
      if (e == "TEXT") {
        record.scoresList = [];
        record.optionsList = [];
        record.optionsStr = "/";
        record.scoreStr = "/";
      } else {
        record.manual = false;
        record.manualMax = "";
        record.manualMin = "";
        record.optionsStr = "";
        record.scoreStr = "";
      }
    },
    requiredChange(e, i, index) {
      if (e === 1) {
        this.questionList[index].items[i].required = true;
      } else {
        this.questionList[index].items[i].required = false;
      }
    },
    manualChange(e, i, index) {
      if (e === 1) {
        this.questionList[index].items[i].manual = true;
      } else {
        this.questionList[index].items[i].manual = false;
      }
    },
    edit() {
      localStorage.setItem("toQuestionType", "edit");
      if (this.form.name) {
        localStorage.setItem("tempName", this.form.name);
      }
      this.flag = true;
    },
    toTop(item, index) {
      console.log(index);
      this.questionList.splice(index, 1);
      this.questionList.unshift(item);
    },
    moveUp(item, index) {
      this.questionList.splice(index, 1);
      this.questionList.splice(index - 1, 0, item);
    },
    moveDown(item, index) {
      this.questionList.splice(index, 1);
      this.questionList.splice(index + 1, 0, item);
    },
    toBottom(item, index) {
      this.questionList.splice(index, 1);
      this.questionList.push(item);
    },
    // 判断数组中的元素是否可以转换为整数
    isInteger(arr) {
      return arr.every((element) => {
        return Number.isInteger(Number(element));
      });
    },

    save: debounce(function () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          console.log(this.questionList);

          this.questionList.forEach((value) => {
            value.items.forEach((item) => {
              console.log(item);
              if (item.type === "SELECT") {
                if (item.scoreStr) {
                  const scoresArr = item.scoreStr.split(/,|，/);
                  console.log(scoresArr, "scoresArr");
                  if (scoresArr.includes("")) {
                    item.validate = false;
                  } else {
                    const result = this.isInteger(scoresArr);
                    console.log(result, "result");
                    if (result) {
                      const scoresList = scoresArr
                        .map(Number)
                        .sort(function (a, b) {
                          return b - a;
                        });
                      console.log(scoresList, "scoresList");
                      const optionsArr = item.optionsStr.split(/,|，/);
                      if (optionsArr.includes("")) {
                        item.validate = false;
                      } else {
                        const obj = {};
                        scoresArr.forEach((v, i) => {
                          if (!obj[v]) {
                            obj[v] = [];
                          }
                          obj[v].push(optionsArr[i]);
                        });

                        if (
                          optionsArr.length !== scoresList.length ||
                          optionsArr.includes("")
                        ) {
                          item.validate = false;
                        } else {
                          item.validate = true;

                          const arr = Array.from(new Set(scoresList));
                          console.log(arr, "arrarrarrarr");
                          let optionsList = [];
                          arr.forEach((i) => {
                            optionsList = [...optionsList, ...obj[i]];
                          });

                          item.optionsList = optionsList;
                          item.scoresList = scoresList;
                          if (item.manual) {
                            item.manualMax = item.scoresList[0];
                            item.manualMin =
                              item.scoresList[item.scoresList.length - 1];
                          } else {
                            item.manualMax = "";
                            item.manualMin = "";
                          }
                        }
                      }
                    } else {
                      item.validate = false;
                    }
                  }
                } else {
                  item.validate = false;
                }
              } else {
                item.manual = "";
                item.manualMax = 0;
                item.manualMin = 0;
              }
            });
          });

          this.form.template = cloneDeep(this.questionList);
          let validate = true;
          this.form.template.forEach((item) => {
            item.items.forEach((value) => {
              if (!value.validate) {
                validate = false;
              }
              delete value.optionsStr;
              delete value.scoreStr;
              delete value.validate;
            });
          });
          if (!validate) return this.$message.warn("输入格式不正确");
          console.log(this.form.template);
          if (localStorage.getItem("toQuestionType") == "edit") {
            this.form.id = localStorage.getItem("tempId");
            const res = await editTemplate(this.form);
            this.$message.success(res.data);
          } else {
            const res = await createTemplate(this.form);
            console.log(res);
            this.$message.success(res.msg);
          }

          this.$router.push("/template");
          this.$store.commit("updateTempQuestions", []);
        }
      });
    }, 500),
    cancel() {
      if (!this.type && localStorage.getItem("toQuestionType") == "create") {
        this.$store.commit("updateTempQuestions", []);
        this.questionList = [];
      } else {
        this.flag = false;
        this.form.name = localStorage.getItem("tempName");
        this.questionList = cloneDeep(this.copyData);
      }
      this.$refs.ruleForm.clearValidate();
    },
    toQuestion() {
      if (this.form.name) {
        localStorage.setItem("tempName", this.form.name);
      }
      this.$emit("templateToQuestion");
      this.$store.commit("updateRefreshPath", "question");
    },
  },
  async created() {
    console.log(this.$route.query.id, "路由列表");
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      localStorage.setItem("tempId", this.id);
      this.flag = false;

      const res = await getTemplateById({ id: this.$route.query.id });
      console.log(res, "模板详情");
      let arr = [];
      res.data.itemGroups.forEach((v) => {
        arr = [...arr, ...v.items];
      });
      console.log(arr, "模板详情");
      this.$store.commit("updateTempQuestions", arr);
      this.form.name = res.data.name;
      this.questionList = [...res.data.itemGroups];
    } else if (this.$store.state.tempQuestions.length) {
      const arr = this.$store.state.tempQuestions;
      let obj = {};
      for (let item of arr) {
        if (!obj[`${item.groupId}`]) {
          obj[`${item.groupId}`] = {
            id: item.groupId,
            name: item.groupName,
            answer: null,
            idx: null,
            createTime: null,
            updateTime: null,
            crux: item.crux,
            enable: false,
            multi: false,
            children: [],
            items: [item],
          };
        } else {
          obj[`${item.groupId}`]["items"].push(item);
        }
      }
      this.questionList = Object.values(obj);
    } else {
      this.flag = true;
      this.questionList = [];
    }

    let tempName = localStorage.getItem("tempName");
    if (tempName) {
      this.form.name = tempName;
    }

    this.questionList.forEach((v) => {
      v.items.forEach((i) => {
        this.$set(i, "validate", true);
        if (i.type == "SELECT") {
          this.$set(i, "scoreStr", i.scoresList.join(","));
          this.$set(i, "optionsStr", i.optionsList.join(","));
        } else {
          this.$set(i, "scoreStr", "/");
          this.$set(i, "optionsStr", "/");
        }
      });
    });
    console.log(this.questionList);
    this.copyData = cloneDeep(this.questionList);
  },
  computed: {
    getScore() {
      return (record) => {
        if (record.type == "SELECT") {
          return record.scoresList.join(",");
        } else {
          return record.manualMax + "," + record.manualMin;
        }
      };
    },
    handeleType() {
      return (val) => {
        if (val === "SELECT") {
          return 2;
        } else if (val === "TEXT") {
          return 1;
        }
      };
    },
  },

  beforeRouteLeave(to, from, next) {
    next((vm) => {
      console.log("离开之前", to);
    });
  },
};
</script>

<style scoped lang="scss">
.CreateTemplate {
  height: 100%;
  .create-template-header {
    padding: 24px 32px;
    height: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 16px;
    height: 100%;
    .create-template-header-top {
      margin-bottom: 20px;
      padding-right: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .create-template-title {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #303030;
        .oblong {
          margin-right: 8px;
          width: 4px;
          height: 24px;
          background: #83ba7b;
        }
      }
      .create-template-btn {
        .top {
          display: flex;
          align-items: center;
        }
        .save,
        .cancel,
        .edit {
          width: 72px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          color: #fff;
          background: #3298f1;
          border-radius: 4px;
          cursor: pointer;
        }
        .cancel {
          margin-left: 16px;
          background: rgba(191, 193, 198, 0.5);
        }
      }
    }
    .create-template-header-bottom {
      margin-left: 13px;
      // display: flex;
      // flex-direction: column;
      box-sizing: border-box;
      font-size: 14px;
      color: #303030;
      .question-list {
        // flex: 1;
        // height: 550px;
        // overflow: auto;
        position: relative;
        .change {
          position: absolute;
          top: 60px;
          line-height: 30px;
          left: -70px;
          padding: 0 5px;
          color: #fff;
          background: #3298f1;
          border-radius: 4px;
          cursor: pointer;
        }
        .question-item {
          margin-bottom: 30px;
          .header {
            display: flex;
            justify-content: space-between;
            background: rgba(131, 186, 123, 0.5);
            padding: 0 30px 0 15px;
            color: #303030;

            .handle {
              display: flex;
              color: #303030;
              div {
                cursor: pointer;
                &:nth-child(n + 2) {
                  margin-left: 10px;
                }
                &:hover {
                  color: #83ba7b;
                }
              }
            }
          }
          .footer {
            color: rgba(0, 0, 0, 0.65);
            font-weight: 600;
            padding: 10px 30px 10px 16px;
          }
          .ant-table-tbody
            tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
            td {
            .green {
              background: #f3fdf2 !important;
              &:hover,
              &:focus {
                background: #f3fdf2 !important;
              }
            }
            .white {
              background: #fff !important;
              &:hover,
              &:focus {
                background: #fff !important;
              }
            }
            .white[disabled] {
              background: #f3fdf2 !important;
              &:hover,
              &:focus {
                background: #f3fdf2 !important;
              }
            }
          }
        }
      }
      .ipt {
        width: 240px;
        &.ant-input-disabled {
          cursor: not-allowed !important;
          border: none;
        }
      }
      .textarea-box {
        position: relative;
        .textarea {
          width: 900px;
          height: 328px;
        }
        .mask {
          width: 900px;
          height: 328px;
          position: absolute;
          top: 0;
          left: 0;
          cursor: pointer;
        }
      }

      .ant-select-disabled.ant-select:not(.ant-select-customize-input)
        .ant-select-selector,
      .ant-input-disabled,
      .ant-input-number-disabled {
        color: rgba(0, 0, 0, 0.65) !important;
        background: #fff !important;
      }
    }
  }
}
</style>
