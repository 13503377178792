<template>
  <div class="Client">
    <div class="client-header">
      <div class="client-header-top">
        <module-title text="门店搜索"></module-title>
      </div>
      <div class="client-header-bottom">
        <div class="client-header-bottom-left">
          <a-form-model
            :model="form"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :colon="false"
          >
            <a-form-model-item label="门店名称">
              <a-input
                placeholder="请输入门店名称"
                v-model.trim="form.name"
                @pressEnter="inquire"
              />
            </a-form-model-item>
            <a-form-model-item label="集团品牌">
              <searchSelect
                :key="componentKey"
                :bid="form.bid"
                :iptWidth="220"
                @updateParams="groupChange"
              ></searchSelect>
            </a-form-model-item>

            <a-form-model-item label="区域经理/服务工程师">
              <a-select v-model="form.saleId">
                <a-select-option
                  v-for="(item, index) in allSaleList"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <!-- <a-form-model-item label="区域">
              <a-select
                @change="areaChange"
                placeholder="请选择区域"
                defaultValue="不限"
              >
                <a-select-option
                  :value="item"
                  v-for="(item, index) in areaList"
                  :key="index"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-model-item> -->
            <a-form-model-item label="业务员">
              <a-radio-group :value="form.hasAssign" @change="assignTypeChange">
                <a-radio
                  v-for="(item, index) in assignType"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-form-model>
        </div>
        <div class="client-header-bottom-right" @click="inquire">查询</div>
      </div>
    </div>
    <div class="client-main">
      <div class="client-main-header">
        <div class="client-main-header-left">
          <module-title text="门店列表"></module-title>
          <div class="creat-user" @click="createClient">新建门店</div>
          <div>
            <batch-import
              :isLoading="isLoading"
              :percent="percent"
              :url="storeUrl"
            ></batch-import>
          </div>
          <a
            class="download"
            download
            :href="
              http.baseURL +
              '/template/%E9%97%A8%E5%BA%97%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88.xlsx'
            "
            >模板下载</a
          >
        </div>
        <div class="client-main-header-right">
          <div class="check-box">
            <div>全选</div>
            <div>
              <a-checkbox
                :checked="isChecked"
                @change="checkBoxChange"
              ></a-checkbox>
            </div>
          </div>
          <div class="assign-box">
            <div>批量操作</div>
            <div
              :class="['assign', active ? 'active' : '']"
              @click="batchAssign"
            >
              指派
            </div>
            <div
              :class="['delete', active ? 'active' : '']"
              v-if="$store.state.permission == 'a%q&22podll.1vz'"
              @click="batchDel"
            >
              删除
            </div>
          </div>
        </div>
      </div>
      <div class="client-main-footer">
        <a-table
          :columns="columns"
          :data-source="clientList"
          :loading="loading"
          :row-selection="rowSelection"
          :rowKey="(record) => record.id"
          :pagination="pagination"
          :customRow="rowClick"
        >
          <span slot="group" slot-scope="groupName">
            <span>{{ groupName ? groupName : "/" }}</span>
          </span>
          <!-- <span slot="area" slot-scope="area">
            <span>{{ area ? area : "/" }}</span>
          </span> -->
          <span slot="sale" slot-scope="sale">
            <span>{{ sale ? sale : "/" }}</span>
          </span>
          <span slot="regionManager" slot-scope="regionManager">
            <span>{{ regionManager ? regionManager : "/" }}</span>
          </span>
          <span slot="groupManager" slot-scope="groupManager">
            <span>{{ groupManager ? groupManager : "/" }}</span>
          </span>
          <div slot="action" class="action" slot-scope="item, text, index">
            <div
              :class="[
                'assign',
                activeIndex == index && activeType == 'assign' ? 'active' : '',
              ]"
              @click.stop="assignSale(item, text, index)"
            >
              指派
            </div>
            <div
              :class="[
                'edit',
                activeIndex == index && activeType == 'edit' ? 'active' : '',
              ]"
              @click.stop="editClient(item, text, index)"
            >
              编辑
            </div>
            <div
              :class="[
                'delete',
                activeIndex == index && activeType == 'delete' ? 'active' : '',
              ]"
              v-if="$store.state.permission == 'a%q&22podll.1vz'"
              @click.stop="removeClient(item, text, index)"
            >
              删除
            </div>
          </div>
        </a-table>
      </div>
    </div>
    <create-client-modal
      :key="componentKey"
      :visible="createVisible"
      :title="title"
      @cancel="createModalCancel"
      :areaList="otherAreaList"
      :staffList="otherAllSaleList"
      :clientInfo="editClientInfo"
      @createClient="addClient($event)"
      ref="createClient"
    ></create-client-modal>
    <delete-client-modal
      :visible="deleteClientVisible"
      @ok="deleteClientOk"
      @cancel="deleteClientCancel"
    ></delete-client-modal>
    <assign-sale-modal
      :visible="assignSaleVisible"
      :allSaleList="otherAllSaleList"
      @cancel="assignSaleCancel"
      :clientInfo="assignClientInfo"
      @ok="assignSaleOk"
      ref="assignSale"
    ></assign-sale-modal>
    <batch-assign-sale
      :visible="batchAssignSaleVisible"
      @cancel="batchAssignSaleCancel"
      @ok="batchAssignSaleOk"
      :allSaleList="otherAllSaleList"
      ref="batchAssignSale"
    ></batch-assign-sale>
    <assign-confirm-modal
      :visible="assignConfirmVisible"
      :selectedRows="alreadyAssignList"
      @cancel="assignConfirmCancel"
      :assignConfirmInfo="assignConfirmParams"
      @ok="assignConfirmOk"
      ref="assignConfirm"
    ></assign-confirm-modal>

    <div v-if="isLoading" class="progress-box">
      <a-progress
        type="circle"
        :width="100"
        strokeColor="#83ba7b"
        :percent="percent"
      />
    </div>
    <MyMask v-if="isLoading"></MyMask>
  </div>
</template>

<script>
import MyMask from "../components/MyMask.vue";
import ModuleTitle from "../components/ModuleTitle";
import DeleteClientModal from "../components/DeleteClientModal.vue";
import { getUser } from "../common/api/user";
import {
  getRegion,
  getClient,
  createStore,
  editStore,
  delStore,
  assignSale,
} from "../common/api/client";
import BatchImport from "../components/BatchImport.vue";
import { storeUrl } from "../common/url";
import CreateClientModal from "../components/CreateClientModal.vue";
import { deepClone } from "../common/utils";
import AssignSaleModal from "../components/AssignSaleModal.vue";
import AssignConfirmModal from "../components/AssignConfirmModal.vue";
import BatchAssignSale from "../components/BatchAssignSale.vue";
import SearchSelect from "../components/SearchSelect.vue";
import http from "../common/http";
const columns = [
  {
    title: "门店名称",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "集团名称",
    dataIndex: "groupName",
    scopedSlots: { customRender: "group" },
    key: "groupName",
    align: "center",
  },
  {
    title: "集团品牌",
    dataIndex: "brandName",
    key: "brandName",
    align: "center",
  },

  {
    title: "区域经理/服务工程师",
    key: "saleName",
    dataIndex: "saleName",
    scopedSlots: { customRender: "sale" },
    align: "center",
  },

  {
    title: "大区经理/工程服务经理",
    key: "regionManager",
    dataIndex: "regionManager",
    scopedSlots: { customRender: "regionManager" },
    align: "center",
  },
  {
    title: "集团经理",
    key: "groupManager",
    dataIndex: "groupManager",
    scopedSlots: { customRender: "groupManager" },
    align: "center",
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: "center",
  },
];

export default {
  name: "Client",
  components: {
    ModuleTitle,
    BatchImport,
    CreateClientModal,
    DeleteClientModal,
    AssignSaleModal,
    AssignConfirmModal,
    BatchAssignSale,
    SearchSelect,
    MyMask,
  },

  data() {
    return {
      percent: 0,
      isLoading: false,
      componentKey: 0,
      ids: "",
      http,
      batchAssignSaleVisible: false,
      activeIndex: null,
      activeType: "",
      // 批量指派确认弹框状态
      assignConfirmVisible: false,

      // 指派业务员弹窗状态
      assignSaleVisible: false,
      // 删除业务员弹窗状态
      deleteClientVisible: false,
      // 是否全选
      isChecked: false,
      // 新建门店弹框显示状态
      createVisible: false,
      storeUrl,
      columns,
      labelCol: { span: 8 },
      wrapperCol: { span: 15, offset: 1 },
      form: {
        page: 0,
        size: 10,
        name: "",
        code: "",
        region: "不限",
        bid: undefined,
        saleId: "",
        hasAssign: 0,
      },
      // 新建，编辑门店弹窗标题
      title: "",
      loading: false,

      // 表格选中项id
      selectedRowKeys: [],
      // 表格选中项
      selectedRows: [],
      // 分页配置对象
      pagination: {
        position: "bottom",
        pageSize: 10,
        current: 1,
        total: 0,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["5", "10", "20"], // 每页数量选项
        showQuickJumper: true,
        // 显示总数
        showTotal: (total) => `共 ${total} 条`,
        // 改变每页数量时更新显示
        onShowSizeChange: (page, pageSize) => this.onSizeChange(page, pageSize),
        // 点击页码事件
        onChange: (page, pageSize) => this.onPageChange(page, pageSize),
      },
      // 点击表格行
      rowClick: (record) => ({
        // 事件
        on: {
          click: () => {
            console.log(record, "record");
            this.$router.push({
              path: "/clientdetails",
              query: {
                id: record.id,
              },
            });
          },
        },
      }),
      // 改变每页数量时更新显示
      onSizeChange(page, pageSize) {
        console.log("每页数量:", pageSize);
        this.pagination.current = 1;
        this.pagination.pageSize = pageSize;
        this.form.page = 0;
        this.form.size = pageSize;
        this.getClientList();
      },
      // 点击页码事件
      onPageChange(page) {
        console.log("页码值:", page);
        this.pagination.current = page;
        this.form.page = page - 1;
        this.getClientList();
      },
      // 业务员列表
      staffList: [],

      // 直属领导列表
      leaderList: [],
      allSaleList: [],
      otherAllSaleList: [],
      // 门店区域列表
      areaList: [],
      otherAreaList: [],
      // 门店列表
      clientList: [],

      // 指派类型
      assignType: [
        {
          id: 0,
          name: "不限",
        },
        {
          id: 1,
          name: "已指派",
        },
        {
          id: 2,
          name: "未指派",
        },
      ],
      // 删除门店请求参数
      deleteParams: {},
      active: false,
      // 编辑门店信息
      editClientInfo: {},
      // 指派门店信息
      assignClientInfo: {},
      // 批量指派业务员请求参数
      assignConfirmParams: {},

      // 已指派业务员或者设置下次拜访截止日列表
      alreadyAssignList: [],
      // 总页数
      totalPages: 0,
      // 单个删除还是批量删除
      delType: "",
    };
  },
  methods: {
    // 将未设置拜访截止日期的分组
    getSaleId(data) {
      let arr = [];
      data.forEach((v) => {
        if (arr.length == 0) {
          let obj = {
            ids: [v.id],
            saleId: v.saleId,
          };
          arr.push(obj);
        } else {
          const index = arr.findIndex((i) => {
            return i.saleId == v.saleId;
          });
          console.log(index);
          if (index != -1) {
            arr[index].ids.push(v.id);
          } else {
            arr.push({
              ids: [v.id],
              saleId: v.saleId,
            });
          }
        }
      });
      return arr;
    },
    // 获取未设置截止时间业务员id
    getNoDeadlineId() {
      let arr = [];
      this.selectedRows.forEach((v) => {
        if (!v.nxtDealDate) {
          arr.push(v.id);
        }
      });
      return arr;
    },
    // 确认批量指派业务员确认弹框
    async assignConfirmOk({ value1, value2 }) {
      console.log(value1, value2);
      console.log(this.assignConfirmParams, 1111);
      console.log(this.selectedRows);
      if (value1 == 1 && value2 == 1) {
        const res = await assignSale(this.assignConfirmParams);
        console.log(res);
        this.$message.success(res.msg);
        this.getClientList();
      } else if (value1 == 2 && value2 == 1) {
        let saleArr = [];
        this.selectedRows.forEach((v) => {
          if (!v.saleId) {
            saleArr.push(v.id);
          }
        });
        console.log(saleArr, "未设置区域经理/服务工程师的id");
        let saleParams = {};
        if (saleArr.length != 0) {
          saleParams.ids = saleArr.join(",");
          saleParams.uid = this.assignConfirmParams.uid;
          const res1 = await assignSale(saleParams);
          console.log(res1, "res1");
          this.selectedRows.forEach((v) => {
            if (!v.saleId) {
              v.saleId = this.assignConfirmParams.uid;
            }
          });
          console.log(this.selectedRows, "33333333333");
        }

        if (this.assignConfirmParams.nextVistDay) {
          let saleId = this.getSaleId(this.selectedRows);
          console.log(saleId, "saleId");

          saleId.forEach(async (v, i) => {
            let params = {
              ids: v.ids.join(","),
              uid: v.saleId,
              nextVistDay: this.assignConfirmParams.nextVistDay,
            };
            const res2 = await assignSale(params);
            console.log(res2);
            if (i == saleId.length - 1) {
              console.log(i, "iiiiiiiiiiii");
              this.$message.success(res2.msg);
              this.getClientList();
            }
          });
        } else {
          this.$message.success("修改成功");
          this.getClientList();
        }
      } else if (value1 == 1 && value2 == 2) {
        let copyAssignConfirmParams = deepClone(this.assignConfirmParams);
        delete copyAssignConfirmParams.saleName;
        delete copyAssignConfirmParams.nextVistDay;
        const res1 = await assignSale(copyAssignConfirmParams);
        console.log(res1);
        let saleArr = this.getNoDeadlineId();
        console.log(saleArr, "saleArr");
        console.log(saleArr.join(","));
        if (saleArr.length > 0) {
          const params = {
            ids: saleArr.join(","),
            uid: this.assignConfirmParams.uid,
            nextVistDay: this.assignConfirmParams.nextVistDay,
          };
          const res2 = await assignSale(params);
          console.log(res2, "res2");
          this.$message.success(res2.msg);
        }
        this.getClientList();
      } else {
        let saleArr = [];
        this.selectedRows.forEach((v) => {
          if (!v.saleId) {
            saleArr.push(v.id);
          }
        });
        console.log(saleArr, "未设置业务员的id");
        let saleParams = {};
        if (saleArr.length != 0) {
          saleParams.ids = saleArr.join(",");
          saleParams.uid = this.assignConfirmParams.uid;
          const res1 = await assignSale(saleParams);
          console.log(res1, "res1");
          this.selectedRows.forEach((v) => {
            if (!v.saleId) {
              v.saleId = this.assignConfirmParams.uid;
            }
          });
          console.log(this.selectedRows, "33333333333");
        }
        let noNextVistDay = [];
        this.selectedRows.forEach((v) => {
          if (!v.nxtDealDate) {
            noNextVistDay.push(v);
          }
        });
        console.log(noNextVistDay, "999999999");
        let saleId = this.getSaleId(noNextVistDay);
        console.log(saleId, "saleId");
        saleId.forEach(async (v, i) => {
          let params = {
            ids: v.ids.join(","),
            uid: v.saleId,
            nextVistDay: this.assignConfirmParams.nextVistDay,
          };
          const res2 = await assignSale(params);
          console.log(res2);
          if (i == saleId.length - 1) {
            console.log(i, "iiiiiiiiiiii");
            this.$message.success(res2.msg);
            this.getClientList();
          }
        });
      }
      this.assignConfirmVisible = false;
      this.isChecked = false;
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.$refs.assignConfirm.value1 = 1;
      this.$refs.assignConfirm.value2 = 1;
    },
    // 关闭批量指派业务员确认弹框
    assignConfirmCancel() {
      this.assignConfirmVisible = false;
    },
    // 确认批量指派业务员
    async batchAssignSaleOk(data) {
      let params = JSON.parse(JSON.stringify(data));
      params.nextVistDay = "";
      if (data.nextVistDay) {
        params.nextVistDay = data.nextVistDay.format("YYYY-MM-DD");
      } else {
        delete params.nextVistDay;
      }
      params.ids = this.selectedRowKeys.join(",");
      console.log(params);
      this.assignConfirmParams = params;

      if (this.alreadyAssignList.length > 0) {
        this.allSaleList.forEach((v) => {
          if (v.id == params.uid) {
            this.assignConfirmParams.saleName = v.name;
          }
        });
        this.assignConfirmVisible = true;
      } else {
        const res = await assignSale(this.assignConfirmParams);
        console.log(res, 1111);
        this.$message.success(res.msg);
        this.selectedRowKeys = [];
        this.selectedRows = [];
        this.getClientList();
      }
      this.batchAssignSaleVisible = false;
      this.$refs.batchAssignSale.assignForm.nextVistDay = null;
      this.$refs.batchAssignSale.assignForm.uid = undefined;
    },
    // 确认指派业务员
    async assignSaleOk(data) {
      let params = JSON.parse(JSON.stringify(data));
      params.nextVistDay = "";
      if (data.nextVistDay) {
        params.nextVistDay = data.nextVistDay.format("YYYY-MM-DD");
      } else {
        delete params.nextVistDay;
      }

      params.ids = this.assignClientInfo.id;
      const res = await assignSale(params);

      this.assignSaleVisible = false;
      this.activeIndex = null;
      this.activeType = "";
      this.$message.success(res.msg);

      this.getClientList();

      this.assignClientInfo = {};
      this.$refs.assignSale.assignForm.nextVistDay = null;
      this.$refs.assignSale.assignForm.uid = undefined;
    },
    // 关闭指派业务员弹窗
    assignSaleCancel() {
      this.activeIndex = null;
      this.assignClientInfo = {};
      this.activeType = "";
      this.assignSaleVisible = false;
    },
    // 关闭批量指派业务员弹窗
    batchAssignSaleCancel() {
      this.batchAssignSaleVisible = false;
    },
    // 指派业务员
    assignSale(item, text, index) {
      this.activeIndex = index;
      this.activeType = "assign";
      this.assignClientInfo = item;
      this.assignSaleVisible = true;
      // console.log(item);
    },
    // 编辑单个门店
    editClient(item, text, index) {
      item.bid = item.brand.id;
      console.log(item, "编辑门店信息");

      this.title = "编辑门店";
      this.activeIndex = index;
      this.activeType = "edit";
      this.createVisible = true;
      this.editClientInfo = item;
      console.log(this.editClientInfo, 22222);
    },
    // 批量指派
    batchAssign() {
      if (this.selectedRowKeys.length == 0) {
        return this.$message.info("请选择门店");
      }
      this.batchAssignSaleVisible = true;
    },

    // 批量删除
    batchDel() {
      console.log(this.ids, "见鬼了");
      if (this.selectedRowKeys.length == 0) {
        return this.$message.info("请选择门店");
      }
      this.delType = "batchDel";
      this.deleteClientVisible = true;

      this.ids = this.selectedRowKeys.join(",");
      console.log(this.ids, "见鬼了");
    },
    // 删除门店
    removeClient(item, text, index) {
      this.delType = "del";
      this.activeIndex = index;
      this.activeType = "delete";
      this.deleteClientVisible = true;
      console.log(item);
      this.ids = item.id;
    },

    deleteClientCancel() {
      this.activeIndex = null;
      this.activeType = "";
      this.deleteClientVisible = false;
    },
    // 确定删除门店
    async deleteClientOk() {
      const res = await delStore({
        ids: this.ids,
      });
      this.activeIndex = null;
      this.activeType = "";
      this.deleteClientVisible = false;
      this.$message.success(res.data);

      if (this.selectedRowKeys.length) {
        this.form.page = 0;
        this.pagination.current = 1;
      } else {
        if (this.clientList.length === 1 && this.form.page !== 0) {
          this.form.page--;
          this.pagination.current--;
        }
      }
      this.getClientList();

      if (this.delType == "del") {
        const index1 = this.selectedRowKeys.findIndex((v) => {
          return v == this.ids;
        });
        console.log(index1);
        if (index1 !== 1) {
          this.selectedRowKeys.splice(index1, 1);
          this.selectedRows.splice(index1, 1);
        }
        console.log(this.selectedRowKeys, this.selectedRows);
      } else {
        this.selectedRowKeys = [];
        this.selectedRows = [];
      }
    },
    // 全选
    async checkBoxChange(e) {
      console.log(e.target.checked);
      let params = deepClone(this.form);
      if (params.hasAssign == 0) {
        delete params.hasAssign;
      } else if (params.hasAssign == 1) {
        params.hasAssign = true;
      } else if (params.hasAssign == 2) {
        params.hasAssign = false;
      }

      if (!params.code) {
        delete params.code;
      }
      if (!params.bid) {
        delete params.bid;
      }
      if (!params.region || params.region == "不限") {
        delete params.region;
      }
      if (!params.saleId) {
        delete params.saleId;
      }
      if (!params.name) {
        delete params.name;
      }
      params.page = 0;
      params.size = this.form.size * this.totalPages;

      const res = await getClient(params);
      console.log(res);
      this.selectedRows = res.data.content;
      this.selectedRowKeys = res.data.content.map((v) => {
        return v.id;
      });

      if (e.target.checked) {
        this.isChecked = true;
      } else {
        this.isChecked = false;
        this.selectedRowKeys = [];
        this.selectedRows = [];
      }
    },
    // 确定新建门店
    async addClient(data) {
      let params = deepClone(data);
      params.nextVistDay = "";
      if (data.nextVistDay) {
        params.nextVistDay = data.nextVistDay.format("YYYY-MM-DD");
      } else {
        delete params.nextVistDay;
      }
      console.log(params, "params");

      if (params.id) {
        const res = await editStore(params);
        this.$message.success(res.data);
      } else {
        const res = await createStore(params);
        this.$message.success(res.msg);
        this.form.page = 0;
        this.pagination.current = 1;
      }
      this.activeIndex = null;
      this.activeType = "";
      this.createVisible = false;
      this.$refs.createClient.gapdate = "";
      this.editClientInfo = {};
      this.$refs.createClient.createClientForm = {
        name: "",
        bid: undefined,
        groupManager: "",
        address: "",
        region: undefined,
        uid: undefined,
        nextVistDay: null,
      };

      this.isChecked = false;
      this.getClientList();
    },
    // 关闭新建门店弹框
    createModalCancel() {
      this.activeIndex = null;
      this.activeType = "";
      this.createVisible = false;
      this.editClientInfo = {};
    },
    //  查询
    inquire() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.isChecked = false;
      this.form.page = 0;
      this.pagination.current = 1;
      this.getClientList();
    },

    // 选中项发生变化时的回调
    onRowChange(selectedRowKeys, selectedRows) {
      // this.selectedRowKeys = selectedRowKeys;
      // this.selectedRows = selectedRows;
      this.selectedRowKeys = selectedRowKeys;
      // let n = 0;
      // this.selectedRowKeys.forEach((v) => {
      //   const result = selectedRows.some((i) => {
      //     return v == i.id;
      //   });
      //   if (result) {
      //     n++;
      //   }
      // });
      // if (n == this.form.size) {
      //   this.isChecked = true;
      // } else {
      //   this.isChecked = false;
      // }
      if (selectedRowKeys.length === selectedRows.length) {
        this.selectedRows = selectedRows;
      } else {
        this.selectedRows = selectedRowKeys.map(
          (key) =>
            this.selectedRows.find((row) => row.id === key) ||
            selectedRows.find((row) => row.id === key) //必在两者之中
        );
      }

      console.log(this.selectedRowKeys, this.selectedRows, 999999999);
      if (selectedRowKeys.length == this.pagination.total) {
        this.isChecked = true;
      } else {
        this.isChecked = false;
      }
    },
    // 新建门店
    createClient() {
      this.title = "新建门店";

      this.createVisible = true;
    },
    // 获取门店列表
    async getClientList() {
      let params = deepClone(this.form);
      if (params.hasAssign == 0) {
        delete params.hasAssign;
      } else if (params.hasAssign == 1) {
        params.hasAssign = true;
      } else if (params.hasAssign == 2) {
        params.hasAssign = false;
      }

      if (!params.code) {
        delete params.code;
      }
      if (!params.bid) {
        delete params.bid;
      }
      if (!params.region || params.region == "不限") {
        delete params.region;
      }
      if (!params.saleId) {
        delete params.saleId;
      }
      if (!params.name) {
        delete params.name;
      }
      console.log(params);
      this.loading = true;
      const res = await getClient(params);
      this.loading = false;
      console.log(res);
      this.clientList = res.data.content;
      this.pagination.total = res.data.totalElements;
      this.totalPages = res.data.totalPages;
      // if (this.selectedRowKeys.length > 0) {
      //   let n = 0;
      //   this.selectedRowKeys.forEach((v) => {
      //     const result = this.clientList.some((i) => {
      //       return v == i.id;
      //     });
      //     if (result) {
      //       n++;
      //     }
      //   });
      //   if (n == this.form.size) {
      //     this.isChecked = true;
      //   } else {
      //     this.isChecked = false;
      //   }
      // } else {
      //   this.isChecked = false;
      // }
    },
    // 获取门店区域
    async getAreaList() {
      const res = await getRegion();
      // console.log(res);
      this.otherAreaList = res.data;
      this.areaList = deepClone(res.data);
      this.areaList.unshift("不限");
    },
    // 选择指派类型
    assignTypeChange(e) {
      this.form.hasAssign = e.target.value;
    },
    // 选择区域
    areaChange(e) {
      // console.log(e);
      this.form.region = e;
    },
    // 获取直属领导列表
    async getLeaderList() {
      const params = {
        page: 0,
        size: 999,
        roleId: 3,
      };
      const res = await getUser(params);
      this.leaderList = res.data.content;
      this.handleAllSale(this.staffList, this.leaderList);
    },
    // // 选择业务员
    // staffChange(e) {
    //   this.form.saleId = e;
    // },
    // 选择集团品牌
    groupChange(e) {
      console.log(e, "eeeee");
      if (e == 0) {
        this.form.bid = 0;
      } else {
        this.form.bid = e;
      }
    },
    // 获取业务员列表
    async getStaffList() {
      const params = {
        page: 0,
        size: 999,
        roleId: 4,
      };
      const res = await getUser(params);
      // console.log("业务员列表:", res);
      this.staffList = res.data.content;

      this.getLeaderList();
    },
    // 获取所有业务员列表
    handleAllSale(a, b) {
      this.allSaleList = [...a, ...b];
      // 这里对id属性进行去重
      const map = new Map();
      this.allSaleList = this.allSaleList.filter(
        (item) => !map.has(item.id) && map.set(item.id, 1)
      );
      this.otherAllSaleList = deepClone(this.allSaleList);
      this.allSaleList.unshift({
        id: "",
        name: "不限",
      });
    },
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        // onSelectAll: this.onSelectAll,
        onChange: this.onRowChange,
        // getCheckboxProps: this.getCheckboxProps,
      };
    },
  },
  created() {
    console.log("permission", this.$store.state.permission);
    console.log("触发了'created'");
    this.$store.commit("updateIsKeepAlive", false);
    console.log(this.$route.query.id, "id");
    if (this.$route.query.id) {
      this.form.saleId = parseInt(this.$route.query.id);
    }
    this.getClientList();
    this.getStaffList();
    this.getAreaList();
  },
  activated() {
    let index = this.$store.state.refreshPath.findIndex((v) => {
      return v == "client";
    });
    if (index != -1) {
      console.log("触发了'activated'");
      if (this.$route.query.id) {
        this.form.saleId = parseInt(this.$route.query.id);
      }
      this.componentKey += 1;
      this.getClientList();
      this.getStaffList();
      this.$store.commit("delRefreshPath", "client");
    }
  },
  watch: {
    selectedRowKeys(val) {
      if (val.length > 0) {
        this.active = true;
      } else {
        this.active = false;
      }
    },
    selectedRows(val) {
      console.log("selectedRows变化了");
      this.alreadyAssignList = [];
      val.forEach((v) => {
        if (v.saleId || v.nextVistDay) {
          this.alreadyAssignList.push(v);
        }
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let isKeepAlive = vm.$store.state.isKeepAlive;
      console.log(isKeepAlive, "isKeepAlive");
      if (from.name == "User" && isKeepAlive) {
        to.meta.keepAlive = false;
      } else {
        to.meta.keepAlive = true;
      }
    });
  },
};
</script>

<style scoped lang="scss">
.Client {
  box-sizing: border-box;
  .client-header {
    background: #ffffff;
    border-radius: 16px;
    padding: 24px 0 30px 24px;
    .client-header-bottom {
      margin-top: 24px;
      // padding-left: 20px;
      display: flex;
      justify-content: space-between;
      padding-right: 100px;
      box-sizing: border-box;
      .client-header-bottom-left {
        flex: 1;
        .ant-form {
          display: flex;
          flex-wrap: wrap;
          .ant-row {
            width: 30%;
            margin-right: 32px;
            margin-bottom: 0;
            margin-top: 24px;
            display: flex;
            align-items: center;
            &:nth-child(-n + 3) {
              margin-top: 0;
            }
            &:nth-child(3) {
              margin-right: 0;
            }
            .ant-input {
              width: 220px;
              height: 30px;
            }
            .ant-select {
              width: 220px;
              height: 30px;
            }
            .ant-radio-group {
              display: flex;
            }
          }
        }
      }
      .client-header-bottom-right {
        margin-top: 5px;
        width: 72px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        color: #ffffff;
        background: #83ba7b;
        opacity: 1;
        border-radius: 4px;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
  .client-main {
    margin-top: 33px;
    background: #ffffff;
    border-radius: 16px;
    padding: 24px 32px 40px 24px;
    box-sizing: border-box;
    .client-main-header {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .client-main-header-left {
        display: flex;
        align-items: center;
        .title {
          display: flex;
          align-items: center;
          font-weight: 500;
          color: #303030;
          .oblong {
            margin-right: 8px;
            width: 4px;
            height: 24px;
            background: #83ba7b;
          }
        }
        .creat-user {
          margin: 0 16px 0 30px;
          width: 88px;
          height: 32px;
          background: #83ba7b;
          border-radius: 4px;
          text-align: center;
          line-height: 32px;
          color: #fff;
          cursor: pointer;
        }
        .download {
          margin-left: 16px;
          color: #83ba7b;
          font-size: 14px;
        }
      }
      .client-main-header-right {
        margin-right: 158px;
        display: flex;
        align-items: center;
        .check-box {
          display: flex;
          align-items: center;
          div:nth-child(1) {
            margin-right: 13px;
          }
        }
        .assign-box {
          margin-left: 85px;
          display: flex;
          align-items: center;
          div:nth-child(n + 2) {
            margin-left: 16px;
            width: 72px;
            height: 32px;
            border-radius: 4px;
            text-align: center;
            line-height: 32px;
            font-size: 14px;
            color: #ffffff;
          }
          .assign {
            background: rgba(50, 152, 241, 0.5);
            cursor: pointer;
            &.active {
              background: #3298f1;
            }
          }
          .delete {
            background: rgba(233, 48, 36, 0.5);
            cursor: pointer;
            &.active {
              background: #e93024;
            }
          }
        }
      }
    }
    .client-main-footer {
      .action {
        display: flex;
        align-items: center;
        justify-content: center;

        div {
          width: 64px;
          height: 24px;
          line-height: 24px;
          color: #fff;
          cursor: pointer;
          opacity: 1;
          border-radius: 4px;
        }
        .assign {
          border: 1px solid #83ba7b;
          background-color: #fff;
          color: #83ba7b;
          box-sizing: border-box;
          line-height: 22px;
          &.active {
            color: #fff;
            border: 0;
            line-height: 24px;
            background: #83ba7b;
          }
        }
        .edit {
          margin: 0 16px;
          background-color: #fff;
          color: #3298f1;
          border: 1px solid #3298f1;
          box-sizing: border-box;
          line-height: 22px;
          &.active {
            color: #fff;
            border: 0;
            background-color: #3298f1;
            line-height: 24px;
          }
        }
        .delete {
          box-sizing: border-box;
          color: #e93024;
          border: 1px solid #e93024;
          background-color: #fff;
          line-height: 22px;
          &.active {
            color: #fff;
            border: 0;
            background-color: #e93024;
            line-height: 24px;
          }
        }
      }
    }
  }
  .progress-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
}
</style>
