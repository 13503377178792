<template>
  <div class="CreateOrderModal">
    <!-- 新建工单弹框 -->
    <div ref="createRefModel" class="create-order-modal">
      <a-modal
        v-model="isShow"
        :title="title"
        :maskClosable="false"
        @ok="handleOk"
        centered
        width="800px"
        :getContainer="() => $refs.createRefModel"
        @cancel="handleCancel"
      >
        <a-form-model
          :model="createOrderForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="ruleForm"
          :rules="rules"
          :colon="false"
        >
          <a-form-model-item label="工单编号" v-if="orderInfo.code">
            <span>{{ orderInfo.code }}</span>
          </a-form-model-item>
          <a-form-model-item label="创建时间">
            <span>{{
              orderInfo.createTime
                ? orderInfo.createTime.slice(0, 10)
                : getCurrentDay
            }}</span>
          </a-form-model-item>
          <a-form-model-item label="工单类型" prop="typeId">
            <a-select
              placeholder="请选择工单类型"
              v-model="createOrderForm.typeId"
            >
              <a-select-option
                :value="item.id"
                v-for="(item, index) in orderType"
                :key="index"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="客户/门店名称" prop="strName">
            <a-select
              :value="createOrderForm.strName"
              show-search
              placeholder="请输入客户/门店名称"
              :default-active-first-option="false"
              :filter-option="false"
              :not-found-content="null"
              style="width: 224px"
              class="hidden"
              :disabled="orderInfo.code && orderInfo.name ? true : false"
              @search="handleSearch"
              @select="handleChange"
            >
              <a-select-option
                :value="item.id"
                v-for="(item, index) in shopList"
                :key="index"
                >{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="集团名称">
            <a-input
              disabled
              placeholder="请输入集团名称"
              :maxLength="50"
              style="width: 224px"
              v-model="groupName"
            />
          </a-form-model-item>
          <a-form-model-item label="客户/门店地址">
            <a-input
              disabled
              v-model="createOrderForm.strAddress"
              placeholder="请输入客户/门店地址"
              :maxLength="50"
              style="width: 224px"
            />
          </a-form-model-item>
          <a-form-model-item label="客户联系人">
            <a-input
              v-model="createOrderForm.contacts"
              placeholder="请输入客户联系人"
              :maxLength="50"
              style="width: 224px"
            />
          </a-form-model-item>
          <a-form-model-item label="联系电话" prop="mobile">
            <a-input
              v-model="createOrderForm.mobile"
              placeholder="请输入联系电话"
              :maxLength="11"
              style="width: 224px"
            />
          </a-form-model-item>
          <a-form-model-item label="指派区域经理/服务工程师">
            <a-select
              placeholder="请选择区域经理/服务工程师"
              v-model="createOrderForm.uid"
            >
              <a-select-option
                :value="item.id"
                v-for="(item, index) in allSaleList"
                :key="index"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="限定完成日">
            <div class="deadline">
              <span
                v-for="(item, index) in deadline"
                :key="index"
                @click="selectDate(item, index)"
                :class="active == index ? 'active' : ''"
                >{{ item.name }}</span
              >

              <div @click="isOpen = !isOpen">
                <a-date-picker
                  @change="dateChange"
                  :value="createOrderForm.nextVistDay"
                  :open="isOpen"
                />
              </div>
            </div>
          </a-form-model-item>

          <a-form-model-item label="服务内容概述" prop="subject">
            <a-input
              v-model="createOrderForm.subject"
              placeholder="简单概括服务内容"
              :maxLength="50"
            />
          </a-form-model-item>
          <a-form-model-item label="服务内容详情">
            <a-input
              v-model="createOrderForm.content"
              type="textarea"
              placeholder="服务内容内容详情"
            />
          </a-form-model-item>
          <a-form-model-item label="附件图片">
            <a-upload
              list-type="picture-card"
              :file-list="fileList"
              :customRequest="uploadFiles"
              :remove="handleDownloadFileRemove"
              :multiple="true"
              accept="image/*"
            >
              <div v-if="fileList.length < 9">
                <a-progress v-if="isLoading" :percent="percent" size="small" />
                <div class="ant-upload-text" v-else>
                  <img src="../assets/image/add.png" alt="" />
                </div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { getClient } from "../common/api/client";
import { debounce } from "lodash";
import moment from "moment";
import $http from "../common/http";
export default {
  name: "CreateOrderModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    allSaleList: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default: "",
    },
    orderInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      percent: 0,
      isLoading: false,
      // 是否展开日历弹层
      isOpen: false,
      previewVisible: false,
      previewImage: "",

      // 集团名称
      groupName: "",
      // 限定时间
      deadline: [
        { name: "明日", time: 1 },
        { name: "7天", time: 7 },
        { name: "30天", time: 30 },
        { name: "其他" },
      ],
      // 表单校验规则
      rules: {
        typeId: [
          { required: true, message: "请选择工单类型", trigger: "blur" },
        ],
        strName: [
          { required: true, message: "请选择客户/门店名称", trigger: "blur" },
        ],
        mobile: [
          {
            required: false,
            pattern: new RegExp(/^1[3456789]\d{9}$/, "g"),
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        subject: [
          { required: true, message: "请输入服务内容概述", trigger: "blur" },
        ],
      },

      labelCol: { span: 7 },
      wrapperCol: { span: 16, offset: 1 },
      // 新建工单请求参数
      createOrderForm: {
        contacts: "",
        content: "",
        mobile: "",
        nextVistDay: null,
        storeId: "",
        strAddress: "",
        strName: undefined,
        subject: "",
        typeId: undefined,
        uid: undefined,
        urlLists: [],
      },
      // 弹框显示状态
      isShow: false,
      // 已上传图片列表
      fileList: [],
      // 工单类型
      orderType: [
        {
          id: 1,
          name: "产品咨询",
        },
        {
          id: 2,
          name: "故障报修",
        },
        {
          id: 3,
          name: "巡店计划",
        },
        {
          id: 4,
          name: "其他",
        },
      ],
      // 限定完成日激活状态
      active: null,
      // 门店列表
      shopList: [],
    };
  },
  methods: {
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    // 自定义上传方法
    uploadFiles(data) {
      if (this.isLoading) return;
      let formData = new FormData();
      formData.append("file", data.file);
      $http.request
        .post("/api/v1/users/imageUpload", formData, {
          onUploadProgress: (progressEvent) => {
            this.isLoading = true;
            console.log(progressEvent);
            // 这里可以获取上传进度信息
            const { loaded, total } = progressEvent;
            console.log(`上传进度: ${Math.round((loaded / total) * 100)}%`);
            this.percent = Math.round((loaded / total) * 100);
          },
        })
        .then((res) => {
          this.isLoading = false;
          let file = this.fileFormatter(res.data);
          if (this.fileList.length >= 9) {
            return this.$message.info("最多上传9张图片");
          } else {
            this.fileList.push(file);
          }
        });
    },
    // 对上传成功返回的数据进行格式化处理，格式化a-upload能显示在已上传列表中的格式（这个格式官方文档有给出的）
    fileFormatter(data) {
      let file = {
        uid: data.newFileName, // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
        name: data.newFileName, // 文件名
        status: "done", // 状态有：uploading done error removed
        url: `${$http.baseURL}/img${data.path}`,
      };
      return file;
    },
    // 删除文件
    handleDownloadFileRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },

    // 获取客户门店
    handleSearch: debounce(async function (name = "") {
      const params = {
        page: 0,
        size: 999,
        name,
      };
      const res = await getClient(params);
      console.log(res);
      this.shopList = res.data.content;
    }, 500),
    // 选择店铺
    handleChange(id) {
      this.createOrderForm.storeId = id;
      this.shopList.forEach((v) => {
        if (v.id == id) {
          this.createOrderForm.strName = v.name;
          if (v.groupName) {
            this.groupName = v.groupName;
          }
          if (v.address) {
            this.createOrderForm.strAddress = v.address;
          }
        }
      });
    },
    // 选择时间
    dateChange(date, dateStr) {
      console.log("dateStr", dateStr);
      this.isOpen = false;
      if (dateStr) {
        this.active = 3;
        this.createOrderForm.nextVistDay = moment(dateStr, "YYYY-MM-DD");
      } else {
        this.active = null;
        this.createOrderForm.nextVistDay = null;
      }
    },
    // 根据天数计算时间
    getLaterDate(dayNum, dateTime = null) {
      // 如果为null,则格式化当前时间为时间戳
      if (!dateTime) dateTime = +new Date();
      // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
      if (dateTime.toString().length == 10) dateTime *= 1000;
      const timestamp = +new Date(Number(dateTime));

      const one_day = 86400000; // 24 * 60 * 60 * 1000;
      const addVal = dayNum * one_day + timestamp;
      //x天后的日期
      const date = new Date(addVal);

      //格式化日期
      const filters = (n) => {
        return n < 10 ? (n = "0" + n) : n;
      };
      const month = filters(date.getMonth() + 1);
      const day = filters(date.getDate());
      const hours = filters(date.getHours());
      const minutes = filters(date.getMinutes());
      const seconds = filters(date.getSeconds());

      const lastTime = `${date.getFullYear()}-${month}-${day}`;

      return lastTime;
    },

    // 选择限定完成日
    selectDate(item, index) {
      this.active = index;
      const date = this.getLaterDate(item.time);
      console.log("日期：", date);
      if (index == 3) {
        this.createOrderForm.nextVistDay = null;
        this.isOpen = true;
      } else {
        this.isOpen = false;
        this.createOrderForm.nextVistDay = moment(date, "YYYY-MM-DD");
        console.log(this.createOrderForm.nextVistDay);
      }
    },
    // 确认新建工单
    handleOk: debounce(function () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.isOpen = false;
          if (this.orderInfo.id) {
            this.createOrderForm.id = this.orderInfo.id;
            this.createOrderForm.urlLists = [];
          }
          this.fileList.forEach((v) => {
            this.createOrderForm.urlLists.push(v.url);
          });

          this.$emit("createOrder", this.createOrderForm);
        }
      });
    }, 500),
    // 取消
    handleCancel() {
      this.$emit("cancel");
      this.$refs.ruleForm.resetFields();
      this.previewVisible = false;
      this.isOpen = false;
      this.active = null;
      this.fileList = [];
      this.groupName = "";
      this.$parent.orderInfo = {};
      this.createOrderForm = {
        contacts: "",
        content: "",
        mobile: "",
        nextVistDay: null,
        storeId: "",
        strAddress: "",
        strName: undefined,
        subject: "",
        typeId: undefined,
        uid: undefined,
        urlLists: [],
      };
    },
  },
  created() {
    this.isShow = this.visible;
    this.handleSearch();
  },
  watch: {
    visible(val) {
      this.isShow = val;
    },
    orderInfo(val) {
      console.log(val, "val");
      if (val.code) {
        this.createOrderForm.strName = val.name;
        this.createOrderForm.storeId = val.storeId;
        this.createOrderForm.strAddress = val.strStoreAddress;
        this.createOrderForm.contacts = val.contacts;
        this.createOrderForm.content = val.content;
        this.createOrderForm.mobile = val.mobile;
        this.createOrderForm.subject = val.subject;
        if (val.assignedUserId) {
          this.createOrderForm.uid = val.assignedUserId;
        }
        if (val.dealLine) {
          this.createOrderForm.nextVistDay = moment(val.dealLine, "YYYY-MM-DD");
          this.active = 3;
        } else {
          this.createOrderForm.nextVistDay = null;
        }

        if (this.orderInfo.urlLists.length == 0) {
          this.fileList = [];
        } else {
          this.createOrderForm.urlLists = this.orderInfo.urlLists;
          this.createOrderForm.urlLists.forEach((v) => {
            let file = {
              uid: v.id, // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
              name: v.id.toString(), // 文件名
              status: "done", // 状态有：uploading done error removed
              url: v.url,
            };
            this.fileList.push(file);
          });
          console.log(this.fileList);
        }
        this.orderType.forEach((v) => {
          if (v.name == val.type) {
            this.createOrderForm.typeId = v.id;
          }
        });
      }
    },
  },
  computed: {
    // 获取今天日期
    getCurrentDay() {
      let date = new Date();

      date.setTime(date.getTime());
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      let currentDay = year + "-" + month + "-" + day;
      return currentDay;
    },
  },
};
</script>

<style scoped lang="scss">
.CreateOrderModal {
  .create-order-modal::v-deep {
    .ant-modal-header {
      .ant-modal-title {
        text-align: center !important;
      }
    }
    .ant-modal-body {
      padding: 20px 78px 0;
      .ant-form-item {
        margin-bottom: 10px;
        .deadline {
          display: flex;
          align-items: center;
          span {
            margin-right: 20px;
            cursor: pointer;
          }
          .ant-calendar-picker {
            width: 128px;
          }
          .active {
            color: #83ba7b;
          }
        }
        textarea.ant-input {
          min-height: 100px;
        }
        .ant-input,
        .ant-input[disabled],
        .ant-select-selection {
          color: #303030;
          background-color: #fff;
        }

        .hidden {
          .ant-select-arrow {
            display: none;
          }
        }

        .ant-select {
          width: 224px;
          color: #303030;
        }

        .ant-upload-select-picture-card i {
          font-size: 32px;
          color: #999;
        }

        .ant-upload-select-picture-card .ant-upload-text {
          img {
            width: 26px;
            height: 26px;
          }
        }
      }
    }
    .ant-modal-footer {
      text-align: center;
      padding-bottom: 44px;
      border: 0;
      div button {
        width: 72px;
        height: 32px;
        &:nth-child(2) {
          margin-left: 80px;
          background: #83ba7b;
          border: 0;
        }
      }
    }
  }
}
</style>
