<template>
  <div class="BatchImport">
    <a-upload
      :customRequest="uploadFiles"
      accept=".xls, .xlsx"
      :showUploadList="false"
    >
      <a-button>批量导入</a-button>
    </a-upload>
  </div>
</template>

<script>
import $http from "../common/http";
export default {
  name: "BatchImport",
  props: {
    url: {
      type: String,
      default: "",
    },
  },

  data() {
    return {};
  },
  methods: {
    // 自定义上传方法
    async uploadFiles(data) {
      let formData = new FormData();
      formData.append("file", data.file);

      $http.request
        .post(this.url, formData, {
          onUploadProgress: (progressEvent) => {
            this.$parent.isLoading = true;
            console.log(progressEvent, 111111);
            // 这里可以获取上传进度信息
            const { loaded, total } = progressEvent;
            console.log(`上传进度: ${Math.round((loaded / total) * 100)}%`);
            this.$parent.percent = Math.round((loaded / total) * 100);
          },
        })
        .then((res) => {
          console.log(res);
          if (this.$parent.getClientList) {
            this.$parent.form.page = 0;
            this.$parent.pagination.current = 1;
            this.$parent.getClientList();
          } else if (this.$parent.getOrderList) {
            this.$parent.form.page = 0;
            this.$parent.pagination.current = 1;
            this.$parent.getOrderList();
          } else {
            this.$parent.params.page = 0;
            this.$parent.pagination.current = 1;
            this.$parent.getUserList();
            this.$store.commit("updateRefreshPath", "client");
            this.$store.commit("updateRefreshPath", "order");
          }
          this.$message.success(res.msg);
          this.$parent.isLoading = false;
        })
        .catch((err) => {
          if (this.$parent.getClientList) {
            this.$parent.form.page = 0;
            this.$parent.pagination.current = 1;
            this.$parent.getClientList();
          } else if (this.$parent.getOrderList) {
            this.$parent.form.page = 0;
            this.$parent.pagination.current = 1;
            this.$parent.getOrderList();
          } else {
            this.$parent.params.page = 0;
            this.$parent.pagination.current = 1;
            this.$parent.getUserList();
            this.$store.commit("updateRefreshPath", "client");
            this.$store.commit("updateRefreshPath", "order");
          }
          this.$parent.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.BatchImport {
  .ant-btn {
    border: none;
    width: 88px;
    height: 32px;
    background: #3298f1;
    border-radius: 4px;
    color: #fff;
  }
}
</style>
