<template>
  <div class="DeleteClientModal">
    <div class="delete-client-modal" ref="deleteRefModel">
      <a-modal
        v-model="isShow"
        title="客户删除警告！"
        @ok="handleOk"
        :maskClosable="false"
        centered
        width="395px"
        :getContainer="() => $refs.deleteRefModel"
        @cancel="handleCancel"
        cancelText="否"
        okText="是"
      >
        <div>已选中的客户将被删除！</div>
        <div>是否继续操作?</div>
      </a-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeleteClientModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // 是否显示
      isShow: false,
    };
  },
  methods: {
    // 确认
    handleOk() {
      this.$emit("ok");
    },
    handleCancel() {
      this.$emit("cancel");
    },
  },
  created() {},
  watch: {
    visible(val) {
      this.isShow = val;
    },
  },
};
</script>

<style scoped lang="scss">
.delete-client-modal::v-deep {
  .ant-modal-header {
    .ant-modal-title {
      text-align: center !important;
      color: #f4501e;
    }
  }
  .ant-modal-body {
    text-align: center;
    span {
      color: #ec4e44;
    }
  }
  .ant-modal-footer {
    text-align: center;
    padding-bottom: 44px;
    border: 0;
    div button {
      width: 72px;
      height: 32px;
      &:nth-child(2) {
        margin-left: 80px;
        background: #83ba7b;
        border: 0;
      }
    }
  }
}
</style>
