<template>
  <div class="SuccessModal">
    <!-- 操作提示框 -->
    <div class="alert-user-modal" ref="alertRefModel">
      <a-modal
        v-model="isShow"
        title="密码已更新"
        centered
        width="395px"
        :getContainer="() => $refs.alertRefModel"
        :maskClosable="false"
        :closable="false"
      >
        <div>请使用新密码重新登录</div>
        <template slot="footer">
          <a-button @click.stop="handleCancel">确定</a-button>
        </template>
      </a-modal>
    </div>
  </div>
</template>

<script>
export default {
  // 组件名称
  name: "SuccessModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 是否显示
      isShow: false,
    };
  },
  methods: {
    handleCancel() {
      console.log(111);
      this.isShow = false;
      this.$emit("cancel");
    },
  },
  watch: {
    visible(val) {
      this.isShow = val;
    },
  },
};
</script>

<style scoped lang="scss">
.alert-user-modal::v-deep {
  .ant-modal-header {
    .ant-modal-title {
      text-align: center !important;
      color: #f4501e;
    }
  }
  .ant-modal-body {
    text-align: center;
  }
  .ant-modal-footer {
    text-align: center;
    padding-bottom: 44px;
    border: 0;
    button {
      width: 72px;
      height: 32px;
      background: #83ba7b !important;
      border: 0 !important;
      color: #fff;
    }
  }
}
</style>
