import $http from '../http'

// 获取用户列表
export const getUser = data => {
    return $http.get('/api/v1/admin/user/list', data)
}

// 获取所在区域
export const getArea = data => {
    return $http.get('/api/v1/config/user/region', data)
}

// 新建用户
export const addUser = data => {
    return $http.post('/api/v1/admin/user', data)
}

// 删除用户
export const delUser = data => {
    console.log('data:', data);
    return $http.del(`/api/v1/admin/user/${data.id}`, data)
}

// 编辑用户（禁用、修改密码）
export const editUser = data => {
    const params = JSON.parse(JSON.stringify(data))
    delete params.id
    return $http.put(`/api/v1/admin/user/${data.id}`, params)
}

// 用户离职
export const leaveUser = data => {
    const params = JSON.parse(JSON.stringify(data))
    delete params.id
    return $http.put(`/api/v1/admin/user/${data.id}/dimission`, params)
}

// 获取用户详情
export const getUserDetails = data => {
    return $http.get(`/api/v1/admin/user/${data.id}`, data)
}

// 获取近期完成度
export const getCompleteness = data => {
    const params = JSON.parse(JSON.stringify(data))
    delete params.id
    return $http.get(`/api/v1/admin/user/${data.id}/completeness`, params)
}

// 获取负责的门店
export const getStores = data => {
    const params = JSON.parse(JSON.stringify(data))
    delete params.id
    return $http.get(`/api/v1/admin/user/${data.id}/stores`, params)
}

// 获取历史回访报告
export const getTasks = data => {
    const params = JSON.parse(JSON.stringify(data))
    delete params.id
    return $http.get(`/api/v1/admin/store/${data.id}/tasks`, params)
}
