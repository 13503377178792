<template>
  <div class="AssignConfirmModal">
    <div class="assign-confirm-modal" ref="assignRefModel">
      <a-modal
        v-model="isShow"
        title="重新指派确认"
        :maskClosable="false"
        @ok="handleOk"
        centered
        width="1068px"
        :getContainer="() => $refs.assignRefModel"
        @cancel="handleCancel"
        cancelText="取消"
      >
        <a-table
          :columns="columns"
          :data-source="
            selectedRows.slice(
              (pagination.current - 1) * pagination.pageSize,
              pagination.current * pagination.pageSize
            )
          "
          :pagination="pagination"
          :rowKey="(record) => record.id"
        >
          <span slot="group" slot-scope="groupName">
            <span>{{ groupName ? groupName : "无" }}</span>
          </span>
          <span slot="saleName" slot-scope="saleName">
            <span>{{ saleName ? saleName : "无" }}</span>
          </span>
          <span slot="nextVistDay" slot-scope="nextVistDay">
            <span>{{ nextVistDay ? nextVistDay : "/" }}</span>
          </span>
        </a-table>
        <div class="confirm">
          <span class="confirm-left"
            >上述客户已指派区域经理/服务工程师，是否重新指派为“{{
              assignConfirmInfo.saleName
            }}”？</span
          >
          <span class="confirm-right"
            ><a-radio-group v-model="value1" :options="options1"
          /></span>
        </div>
        <div class="confirm" v-if="assignConfirmInfo.nextVistDay">
          <span class="confirm-left"
            >上述客户已设置下次拜访截止日，是否重新设置为“{{
              assignConfirmInfo.nextVistDay
            }}”？</span
          >
          <span class="confirm-right"
            ><a-radio-group v-model="value2" :options="options2"
          /></span>
        </div>
        <template slot="footer">
          <a-button @click.prevent="handleOk">提交并关闭</a-button>
        </template>
      </a-modal>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "客户/门店名称",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "集团名称",
    dataIndex: "groupName",
    key: "groupName",
    align: "center",
    scopedSlots: { customRender: "group" },
  },
  {
    title: "当前区域经理/服务工程师",
    key: "saleName",
    dataIndex: "saleName",
    align: "center",
    scopedSlots: { customRender: "saleName" },
  },
  {
    title: "下次拜访截止日",
    key: "nxtDealDate",
    dataIndex: "nxtDealDate",
    align: "center",
    scopedSlots: { customRender: "nextVistDay" },
  },
];
export default {
  name: "AssignConfirmModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    selectedRows: {
      type: Array,
      default() {
        return [];
      },
    },
    assignConfirmInfo: {
      type: Object,
      default() {
        return {
          saleName: "",
          nextVistDay: "",
        };
      },
    },
  },
  components: {},
  data() {
    return {
      // 分页配置对象
      pagination: {
        position: "bottom",
        pageSize: 5,
        current: 1,
        total: 0,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["5", "10", "20"], // 每页数量选项
        showQuickJumper: true,
        // 显示总数
        showTotal: (total) => `共 ${total} 条`,
        // // 改变每页数量时更新显示
        onShowSizeChange: (page, pageSize) => this.onSizeChange(page, pageSize),
        // 点击页码事件
        onChange: (page, pageSize) => this.onPageChange(page, pageSize),
      },
      options1: [
        { label: "是", value: 1 },
        { label: "否", value: 2 },
      ],
      options2: [
        { label: "是", value: 1 },
        { label: "否", value: 2 },
      ],
      value1: 1,
      value2: 1,
      // 是否显示
      isShow: false,
      columns,
    };
  },
  methods: {
    // 改变每页数量时更新显示
    onSizeChange(page, pageSize) {
      console.log("每页数量:", pageSize);
      this.pagination.current = 1;
      this.pagination.pageSize = pageSize;
    },
    // 点击页码事件
    onPageChange(page) {
      console.log("页码值:", page);
      this.pagination.current = page;
    },
    // 确认
    handleOk() {
      this.$emit("ok", {
        value1: this.value1,
        value2: this.value2,
      });
      this.pagination.current = 1;
    },
    handleCancel() {
      this.pagination.current = 1;
      this.value1 = 1;
      this.value2 = 1;
      this.$emit("cancel");
    },
  },
  created() {},
  watch: {
    visible(val) {
      this.isShow = val;
    },
    selectedRows(val) {
      this.pagination.total = val.length;
      console.log(this.selectedRows.length, 1111111111111);
    },
  },
};
</script>

<style scoped lang="scss">
.assign-confirm-modal::v-deep {
  .ant-modal-header {
    .ant-modal-title {
      text-align: center !important;
    }
  }
  .ant-modal-body {
    text-align: center;
    padding-left: 75px;
    padding-right: 75px;
    .confirm {
      display: flex;
      width: 100%;
      &:nth-child(2) {
        margin-top: 52px;
      }
      &:nth-child(3) {
        margin-top: 20px;
      }
      .confirm-left {
        text-align: left;
        display: inline-block;
        width: 70%;
      }
      .confirm-right {
        text-align: left;
        display: inline-block;
        width: 30%;
      }
    }
  }
  .ant-modal-footer {
    text-align: center;
    padding-bottom: 44px;
    border: 0;
    button {
      width: 120px;
      height: 30px;
      background: #83ba7b !important;
      border: 0 !important;
      color: #fff;
    }
  }
}
</style>
