<template>
  <div @click="isShow = false" class="Home">
    <a-layout id="components-layout-demo-custom-trigger">
      <a-layout-sider :trigger="null" collapsible>
        <div class="logo">
          <img src="../assets/image/logo.png" alt="柯灵展新售后服务系统" />
        </div>
        <a-menu
          theme="dark"
          mode="inline"
          :selectedKeys="selectedKeys"
          :openKeys="openKeys"
          @openChange="onOpenChange"
          @click="changeMenu"
        >
          <template v-for="item in menuData">
            <a-menu-item v-if="!item.children" :key="item.key">
              <a-icon :type="item.icon" />
              <span>{{ item.name }}</span>
            </a-menu-item>
            <sub-menu v-else :key="item.key" :menu-info="item" />
          </template>
        </a-menu>
      </a-layout-sider>
      <a-layout>
        <a-layout-header style="background: #fff; padding: 0">
          <!-- <a-icon
          class="trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)"
        /> -->
          <div class="header-top">
            <div class="title">{{ title }}</div>
            <div class="user" @click.stop="userSet">
              <div class="avatar">
                <img
                  :src="
                    userInfo.avatarPath == 'null' ? avatar : userInfo.avatarPath
                  "
                  alt=""
                />
              </div>
              <div class="name">{{ userInfo.name }}</div>
              <div class="icon">
                <img
                  src="../assets/image/arrowbottom.png"
                  :class="isShow ? 'open' : 'close'"
                  alt=""
                />
              </div>
              <div class="user-set-modal" v-show="isShow">
                <div @click="editPwd" class="editPwd">修改密码</div>
                <div @click="exit" class="exit">退出登录</div>
                <div class="triangle"></div>
              </div>
            </div>
          </div>
          <div class="header-bottom">
            <!-- 面包屑 -->
            <a-breadcrumb :routes="routes" separator=">">
              <template slot="itemRender" slot-scope="{ route }">
                <!-- <span v-if="routes.length === 1">
                  {{ route.meta.title }}
                </span> -->
                <span
                  @click="toPage(route)"
                  :class="route.path ? 'breadcrumb-title' : ''"
                >
                  {{ route.meta.title }}
                </span>
              </template>
            </a-breadcrumb>
          </div>
        </a-layout-header>
        <a-layout-content
          :style="{
            margin: '24px 16px',
            padding: '24px',
            background: '#fff',
            minHeight: '280px',
          }"
        >
          <a-config-provider :locale="locale">
            <keep-alive>
              <router-view
                v-if="$route.meta.keepAlive"
                @userToClient="userToClient"
                @clientToOrder="clientToOrder"
                @userToQues="userToQues"
                @questionToTemplate="questionToTemplate"
              />
            </keep-alive>
          </a-config-provider>

          <a-config-provider :locale="locale">
            <router-view
              v-if="!$route.meta.keepAlive"
              @templateToQuestion="templateToQuestion"
              @userToClient="userToClient"
              @clientToOrder="clientToOrder"
              @userToQues="userToQues"
            ></router-view>
          </a-config-provider>
        </a-layout-content>
      </a-layout>
    </a-layout>
    <pwd-modal
      title="修改当前密码"
      :visible="pwdVisible"
      @cancel="editModalCancel"
      @ok="editHandleOk($event)"
      ref="pwdModal"
      cancelText="取消"
      okText="确认"
      :flag="true"
    ></pwd-modal>
    <!-- 提示弹框 -->
    <success-modal
      :visible="alertVisible"
      @cancel="alertModalCancel"
    ></success-modal>
  </div>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import PwdModal from "../components/PwdModal.vue";
import SuccessModal from "../components/SuccessModal.vue";
import { editPwd } from "../common/api/login";
import { routerMap, pathMap } from "../common/global";

import { Icon } from "ant-design-vue";
import avatar from "../assets/image/avatar.png";
import { Menu } from "ant-design-vue";
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_3272051_n4pbw15slt.js",
});
const SubMenu = {
  template: `
  <a-sub-menu :key="menuInfo.key" v-bind="$props" v-on="$listeners">
        <span slot="title">
          <a-icon :type="menuInfo.icon" v-show="menuInfo.show"   /><span>{{ menuInfo.name }}</span>
        </span>
        <template v-for="item in menuInfo.children">
          <a-menu-item v-if="!item.children" :key="item.key" >
            <a-icon :type="item.icon" v-show="item.show"  :style="{visibility:menuInfo.show?'visible':'hidden'}" />
            <span>{{ item.name }}</span>
          </a-menu-item>
          <sub-menu v-else :key="item.key" :menu-info="item"  />
        </template>
      </a-sub-menu>
    `,
  name: "SubMenu",
  isSubMenu: true,
  props: {
    ...Menu.SubMenu.props,
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
};
export default {
  data() {
    return {
      routerMap,
      pathMap,
      openKeys: ["sub1"],
      rootSubmenuKeys: ["sub1", "sub4", "sub5"],
      avatar,
      // 标题
      title: "客户管理",
      // 默认激活项
      selectedKeys: ["/client"],
      // 提示弹框状态
      alertVisible: false,
      // 修改个人密码弹框状态
      pwdVisible: false,
      // 个人信息
      userInfo: {},
      // 是否显示个人设置弹窗
      isShow: false,
      locale: zhCN,
      // collapsed: false,
      // 左侧导航菜单数据
      menuData: [
        // {
        //   name: "客户管理",
        //   icon: "user",
        //   key: "sub1",
        //   show: true,
        //   children: [
        //     {
        //       name: "终端门店管理",
        //       path: "/client",
        //       key: "/client",
        //       icon: "user",
        //       show: false,
        //     },
        //     {
        //       name: "集团客户管理",
        //       path: "/group",
        //       key: "/group",
        //       icon: "user",
        //       show: false,
        //     },
        //   ],
        // },
        // {
        //   name: "工单管理",
        //   icon: "file-done",
        //   path: "/order",
        //   key: "/order",
        //   show: true,
        // },
        // {
        //   name: "回访管理",
        //   icon: "solution",
        //   path: "/ques",
        //   key: "/ques",
        //   show: true,
        // },
        // {
        //   name: "系统管理",
        //   icon: "setting",
        //   key: "sub4",
        //   show: true,
        //   children: [
        //     {
        //       name: "用户管理",
        //       key: "sub4-1",
        //       icon: "user",
        //       show: false,
        //       children: [
        //         {
        //           key: "/user",
        //           name: "用户列表",
        //           path: "/user",
        //           icon: "user",
        //           show: false,
        //         },
        //         {
        //           name: "人员完成度",
        //           path: "/completeness",
        //           key: "/completeness",
        //           icon: "user",
        //           show: false,
        //         },
        //       ],
        //     },
        //     {
        //       name: "问题管理",
        //       path: "/question",
        //       key: "/question",
        //       icon: "user",
        //       show: false,
        //     },
        //     {
        //       name: "Tag管理",
        //       path: "/tag",
        //       key: "/tag",
        //       icon: "user",
        //       show: false,
        //     },
        //     {
        //       name: "管理",
        //       path: "/template",
        //       key: "/template",
        //       icon: "user",
        //       show: false,
        //     },
        //   ],
        // },
        // {
        //   name: "统计管理",
        //   icon: "pie-chart",
        //   key: "sub5",
        //   show: true,
        //   children: [
        //     {
        //       name: "回访报告评分统计",
        //       path: "/statistical",
        //       key: "/statistical",
        //       icon: "user",
        //       show: false,
        //     },
        //     {
        //       name: "工作完成率报告",
        //       path: "/workreport",
        //       key: "/workreport",
        //       icon: "user",
        //       show: false,
        //     },
        //     {
        //       name: "工单数据统计",
        //       path: "/orderreport",
        //       key: "/orderreport",
        //       icon: "user",
        //       show: false,
        //     },
        //   ],
        // },
      ],
      routes: [
        {
          meta: {
            title: "柯灵展新",
          },
          path: "",
        },
      ],
      pathList: [
        "/client",
        "/group",
        "/order",
        "/ques",
        "/user",
        "visitor",
        "/question",
        "/tag",
        "/template",
        "/completeness",
        "/statistical",
      ],
    };
  },
  methods: {
    // 只展开当前父级菜单
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      console.log(latestOpenKey, "latestOpenKey");
      console.log(this.rootSubmenuKeys.indexOf(latestOpenKey) === -1);
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
      console.log(this.openKeys);
    },
    toPage(route) {
      console.log(route, "route");
      console.log(this.routes, "routes");
      if (route.path == "/template") {
        this.$store.commit("updateTempQuestions", []);
      }
      let result = this.pathList.includes(route.path);
      if (
        (result && this.routes.length > 2) ||
        (route.path == "/clientdetails" && this.routes.length > 3)
      ) {
        if (route.path == "/clientdetails") {
          console.log("back");
          this.$router.back();
        } else {
          console.log("push");
          this.$router.push(route.path);
        }
      }
    },
    // 问题列表跳转到新增问卷模板
    questionToTemplate() {
      this.selectedKeys[0] = "/template";
      this.title = "系统管理";

      this.$router.push("/createtemplate");

      window.sessionStorage.setItem("defaultKey", "/template");
      window.sessionStorage.setItem("openKeys", "sub4");
      this.openKeys = ["sub4"];
    },
    // 新增问卷模板跳转到问题列表
    templateToQuestion(params) {
      this.selectedKeys[0] = "/question";
      this.title = "系统管理";

      this.$router.push("/question");

      window.sessionStorage.setItem("defaultKey", "/question");
      window.sessionStorage.setItem("openKeys", "sub4");
      this.openKeys = ["sub4"];
    },
    // 客户详情跳转到工单列表
    clientToOrder(params) {
      this.$store.commit("updateIsKeepAlive", true);
      console.log(params, "params");
      this.selectedKeys[0] = "/order";
      this.title = "工单管理";

      this.$router.push({
        path: "/order",
        query: {
          params: JSON.stringify(params),
        },
      });

      window.sessionStorage.setItem("defaultKey", "/order");
      window.sessionStorage.setItem("openKeys", "sub6");
      this.openKeys = ["sub6"];
    },
    // 跳转到问卷列表
    userToQues(params) {
      this.$store.commit("updateIsKeepAlive", true);
      console.log(params, this.$store.state.isKeepAlive, "999999999999");
      this.selectedKeys[0] = "/ques";
      this.title = "回访管理";

      this.$router.push({
        path: "/ques",
        query: {
          params: JSON.stringify(params),
        },
      });
      window.sessionStorage.setItem("defaultKey", "/ques");
      window.sessionStorage.setItem("openKeys", "sub6");
      this.openKeys = ["sub6"];
    },
    // 跳转到客户列表
    userToClient(id) {
      this.$store.commit("updateIsKeepAlive", true);
      this.selectedKeys[0] = "/client";
      this.title = "客户管理";

      this.$router.push({
        path: "/client",
        query: {
          id,
        },
      });

      window.sessionStorage.setItem("defaultKey", "/client");
      window.sessionStorage.setItem("openKeys", "sub1");
      this.openKeys = ["sub1"];
    },
    //路由内容切换
    changeMenu({ item, key, keyPath }) {
      console.log(key, "key");
      this.$store.commit("updateTempQuestions", []);
      if (pathMap.client.includes(key)) {
        this.title = "客户管理";
        sessionStorage.setItem("title", "客户管理");
        sessionStorage.setItem("openKeys", "sub1");
      } else if (pathMap.system.includes(key)) {
        this.title = "系统管理";
        sessionStorage.setItem("title", "系统管理");
        if (key === "/user" || key === "/visitor" || key === "/completeness") {
          sessionStorage.setItem("openKeys", "sub4,sub4-1");
        } else {
          sessionStorage.setItem("openKeys", "sub4");
        }
      } else if (pathMap.statistics.includes(key)) {
        this.title = "统计管理";
        sessionStorage.setItem("title", "统计管理");
        sessionStorage.setItem("openKeys", "sub5");
      } else {
        let name = "";
        if (key === "/order") {
          name = "工单管理";
        } else {
          name = "回访管理";
        }
        this.title = name;
        sessionStorage.setItem("title", name);
        // sessionStorage.removeItem("openKeys");
      }

      this.selectedKeys[0] = key;
      //获取路由对象并切换
      this.$router.push(key);
      // console.log(key);

      window.sessionStorage.setItem("defaultKey", key);
    },
    // 关闭提示弹框
    alertModalCancel() {
      // console.log(2222);
      this.alertVisible = false;
      this.$router.replace("/login");
      // console.log(3333);
    },
    // 修改密码
    editPwd() {
      this.pwdVisible = true;
    },
    // 关闭修改密码弹框
    editModalCancel() {
      this.pwdVisible = false;
    },
    // 确认修改密码
    async editHandleOk(e) {
      console.log(e);
      const id = window.sessionStorage.getItem("id");
      const params = {
        id,
        confirmpassword: e.confirmPwd,
        oldpassword: e.oldPwd,
        password: e.password,
      };
      try {
        await editPwd(params);
        this.pwdVisible = false;
        console.log(this.pwdVisible);
        window.sessionStorage.removeItem("token");
        this.$refs.pwdModal.$refs.ruleForm.resetFields();
        this.alertVisible = true;
      } catch (err) {}
    },
    userSet() {
      this.isShow = true;
    },
    // 退出登录
    exit() {
      window.sessionStorage.removeItem("token");
      this.$router.replace("/login");
      this.$message.info("已退出登录");
    },
  },
  components: {
    "sub-menu": SubMenu,
    IconFont,
    PwdModal,
    SuccessModal,
  },
  created() {
    console.log(
      window.atob(window.sessionStorage.getItem("sign")) == "a%q&22podll.1vz",
      111
    );
    if (
      window.atob(window.sessionStorage.getItem("sign")) == "a%q&22podll.1vz"
    ) {
      this.menuData = [
        {
          name: "客户管理",
          icon: "user",
          key: "sub1",
          show: true,
          children: [
            {
              name: "终端门店管理",
              path: "/client",
              key: "/client",
              icon: "user",
              show: false,
            },
            {
              name: "集团客户管理",
              path: "/group",
              key: "/group",
              icon: "user",
              show: false,
            },
          ],
        },
        {
          name: "工单管理",
          icon: "file-done",
          path: "/order",
          key: "/order",
          show: true,
        },

        {
          name: "回访管理",
          icon: "solution",
          path: "/ques",
          key: "/ques",
          show: true,
        },
        {
          name: "系统管理",
          icon: "setting",
          key: "sub4",
          show: true,
          children: [
            {
              name: "用户管理",
              key: "sub4-1",
              icon: "user",
              show: false,
              children: [
                {
                  key: "/user",
                  name: "用户列表",
                  path: "/user",
                  icon: "user",
                  show: false,
                },
                // {
                //   key: "/visitor",
                //   name: "访客列表",
                //   path: "/visitor",
                //   icon: "user",
                //   show: false,
                // },
                {
                  name: "人员完成度",
                  path: "/completeness",
                  key: "/completeness",
                  icon: "user",
                  show: false,
                },
              ],
            },
            {
              name: "问题管理",
              path: "/question",
              key: "/question",
              icon: "user",
              show: false,
            },
            {
              name: "Tag管理",
              path: "/tag",
              key: "/tag",
              icon: "user",
              show: false,
            },
            {
              name: "问卷模板管理",
              path: "/template",
              key: "/template",
              icon: "user",
              show: false,
            },
          ],
        },
        {
          name: "统计管理",
          icon: "pie-chart",
          key: "sub5",
          show: true,
          children: [
            {
              name: "回访报告评分统计",
              path: "/statistical",
              key: "/statistical",
              icon: "user",
              show: false,
            },
            {
              name: "工作完成率报告",
              path: "/workreport",
              key: "/workreport",
              icon: "user",
              show: false,
            },
            {
              name: "工单数据统计",
              path: "/orderreport",
              key: "/orderreport",
              icon: "user",
              show: false,
            },
          ],
        },
      ];
    } else {
      this.menuData = [
        {
          name: "客户管理",
          icon: "user",
          key: "sub1",
          show: true,
          children: [
            {
              name: "终端门店管理",
              path: "/client",
              key: "/client",
              icon: "user",
              show: false,
            },
            {
              name: "集团客户管理",
              path: "/group",
              key: "/group",
              icon: "user",
              show: false,
            },
          ],
        },
        {
          name: "工单管理",
          icon: "file-done",
          path: "/order",
          key: "/order",
          show: true,
        },

        {
          name: "回访管理",
          icon: "solution",
          path: "/ques",
          key: "/ques",
          show: true,
        },

        {
          name: "统计管理",
          icon: "pie-chart",
          key: "sub5",
          show: true,
          children: [
            {
              name: "回访报告评分统计",
              path: "/statistical",
              key: "/statistical",
              icon: "user",
              show: false,
            },
            {
              name: "工作完成率报告",
              path: "/workreport",
              key: "/workreport",
              icon: "user",
              show: false,
            },
            {
              name: "工单数据统计",
              path: "/orderreport",
              key: "/orderreport",
              icon: "user",
              show: false,
            },
          ],
        },
      ];
    }
    // this.routes = this.$route.matched.filter((item) => item.meta.title);
    this.userInfo.name = window.sessionStorage.getItem("name");
    this.userInfo.avatarPath = window.sessionStorage.getItem("avatarPath");
    this.title = window.sessionStorage.getItem("title");
    let defaultKey = window.sessionStorage.getItem("defaultKey");
    let openKeys = window.sessionStorage.getItem("openKeys");
    if (openKeys) {
      const arr = openKeys.split(",");
      this.openKeys = arr;
    }
    console.log(defaultKey);
    if (defaultKey) {
      this.selectedKeys[0] = defaultKey;
      if (this.routerMap[defaultKey]) {
        this.routes = [
          {
            meta: {
              title: "柯灵展新",
            },
            path: "",
          },
          ...this.routerMap[defaultKey],
        ];
      } else {
        this.routes = [...this.routes, ...arr];
      }
    } else {
      this.$router.push("/client");
      this.routes.push(
        {
          meta: {
            title: "柯灵展新",
          },
          path: "",
        },
        {
          path: "",
          meta: {
            title: "客户管理",
          },
        },
        {
          path: "/client",
          meta: {
            title: "终端门店管理",
          },
        }
      );
    }
  },
  watch: {
    $route(e) {
      let arr = e.matched.filter((items) => items.meta.title);
      let path = arr[0].path;

      if (this.routerMap[path]) {
        this.routes = [
          {
            meta: {
              title: "柯灵展新",
            },
            path: "",
          },
          ...this.routerMap[path],
        ];
      } else {
        this.routes = [...this.routes, ...arr];
      }
    },
  },
};
</script>

<style scoped lang="scss">
.Home {
  height: 100%;
}
.ant-layout {
  display: flex !important;
  .ant-layout {
    height: 100vh;
  }

  background-color: #f5f5f5 !important;

  .ant-layout-sider {
    width: 264px !important;
    min-width: 264px !important;
    max-width: 264px !important;
    background-color: #2a3644;
    .logo {
      margin: 16px;
      img {
        width: 220px;
        height: 88px;
      }
    }
    .ant-menu::v-deep {
      background-color: #2a3644;
      .ant-menu-submenu {
        .anticon,
        span {
          font-size: 22px;
          color: #83ba7b;
        }
      }
      .ant-menu-item {
        height: 80px;
        line-height: 80px;

        .anticon,
        span {
          font-size: 22px;
          color: #83ba7b;
        }
      }

      .ant-menu-item-selected {
        background-color: #83ba7b;
        .anticon,
        span {
          color: #fff !important;
        }
      }
    }
  }
  .ant-layout-header {
    height: 120px;
    .header-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 153px 0 32px;
      height: 65px;
      border-bottom: 2px solid #f2f3f4;
      box-sizing: border-box;
      .title {
        font-size: 20px;
        font-weight: 500;
      }
      .user {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        .user-set-modal {
          position: absolute;
          left: 30px;
          bottom: -120px;
          box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
          background: #fff;
          z-index: 99;
          border-radius: 16px;
          // overflow: hidden;
          box-sizing: border-box;
          color: #303030;
          div {
            width: 180px;
            height: 60px;
            line-height: 60px;
            text-align: center;
          }
          div:nth-child(1) {
            border-bottom: 3px solid #f8f9f9;
            border-radius: 16px 16px 0 0;
          }
          div:nth-child(2) {
            border-radius: 0 0 16px 16px;
          }
          div:nth-child(-n + 2):hover {
            background: #e6f7ff;
          }
          .triangle {
            position: absolute;
            top: -10px;
            left: 80px;
            width: 0;
            height: 0;
            content: "";
            border-style: solid;
            border-width: 10px;
            border-color: #fff #fff transparent transparent;
            transform: rotate(-45deg);
            box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.08);
          }
        }
        .avatar {
          img {
            height: 44px;
            width: 44px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        .name {
          margin: 0 20px;
          color: #303030;
        }
        .icon {
          img {
            width: 20px;
            height: 23px;
          }
          .close {
            transform: rotate(-180deg);
            -webkit-transform: rotate(-180deg);
            transition: transform 0.5s;
          }
          .open {
            transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transition: transform 0.5s;
          }
        }
      }
    }
    .header-bottom {
      display: flex;
      align-items: center;
      height: 55px;
      padding-left: 18px;
      .breadcrumb-title {
        cursor: pointer;
      }
    }
  }
  .ant-layout-content {
    padding: 0 !important;
    margin: 32px !important;
    background-color: #f5f5f5 !important;
    min-height: auto !important;
  }
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}
</style>
