<template>
  <div class="AssignSaleModal">
    <div class="assign-sale-modal" ref="assignRefModel">
      <a-modal
        v-model="isShow"
        title="指派区域经理/服务工程师"
        @ok="handleOk"
        :maskClosable="false"
        centered
        width="551px"
        :getContainer="() => $refs.assignRefModel"
        @cancel="handleCancel"
        cancelText="取消"
        okText="确定"
        :afterClose="() => $refs.ruleForm.resetFields()"
      >
        <a-form-model
          :model="assignForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="ruleForm"
          :rules="rules"
          :colon="false"
        >
          <a-form-model-item label="客户/门店名称">
            <span>{{ clientInfo.name }}</span>
          </a-form-model-item>
          <a-form-model-item label="指派区域经理/服务工程师" prop="uid">
            <a-select
              placeholder="请选择区域经理/服务工程师"
              v-model="assignForm.uid"
            >
              <a-select-option
                :value="item.id"
                v-for="(item, index) in allSaleList"
                :key="index"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="上次拜访时间">
            <span>{{
              clientInfo.lastVistDate ? clientInfo.lastVistDate : "无"
            }}</span>
          </a-form-model-item>
          <a-form-model-item label="拜访间隔">
            <span>{{
              clientInfo.period ? clientInfo.period + "天" : "无"
            }}</span>
          </a-form-model-item>
          <a-form-model-item label="下次拜访截止日">
            <a-date-picker
              @change="dateChange"
              :value="assignForm.nextVistDay"
            />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "AssignSaleModal",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    allSaleList: {
      type: Array,
      default() {
        return [];
      },
    },
    clientInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  components: {},

  data() {
    return {
      // 是否显示
      isShow: false,
      // 表单对象
      assignForm: {
        nextVistDay: null,
        uid: undefined,
      },
      labelCol: { span: 11 },
      wrapperCol: { span: 12, offset: 1 },
      rules: {
        uid: [
          {
            required: true,
            message: "请选择区域经理/服务工程师",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    dateChange(date, dateStr) {
      if (dateStr) {
        this.assignForm.nextVistDay = moment(dateStr, "YYYY-MM-DD");
      } else {
        this.assignForm.nextVistDay = null;
      }
      // this.$refs["ruleForm"].clearValidate("nextVistDay");
    },
    // 确认
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit("ok", this.assignForm);
        }
      });
    },
    handleCancel() {
      this.$parent.clientInfo = {};
      this.assignForm.nextVistDay = null;
      this.assignForm.uid = undefined;
      this.$emit("cancel");
    },
  },
  watch: {
    visible(val) {
      this.isShow = val;
    },
    clientInfo(val) {
      if (val.saleId) {
        this.assignForm.uid = val.saleId;
      }
      if (val.nxtDealDate) {
        this.assignForm.nextVistDay = moment(val.nxtDealDate, "YYYY-MM-DD");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.assign-sale-modal::v-deep {
  .ant-modal-header {
    .ant-modal-title {
      text-align: center !important;
    }
  }
  .ant-modal-body {
    text-align: center;
    .ant-form-item {
      margin-bottom: 10px;
      .ant-form-item-control {
        text-align: left;
        .ant-select {
          width: 224px;
        }
      }

      .ant-calendar-picker {
        margin-right: 0 !important;
        .ant-input {
          width: 116px;
        }
      }
    }
  }
  .ant-modal-footer {
    text-align: center;
    padding-bottom: 44px;
    border: 0;
    div button {
      width: 72px;
      height: 32px;
      &:nth-child(2) {
        margin-left: 80px;
        background: #83ba7b;
        border: 0;
      }
    }
  }
}
</style>
