<template>
  <div class="Tag">
    <div class="tag-header">
      <div class="tag-header-top">
        <div class="title">
          <div class="oblong"></div>
          <div>Tag列表</div>
        </div>
        <div class="btn" @click="createTag">新增Tag</div>
      </div>
    </div>
    <div class="tag-main">
      <a-table
        :columns="columns"
        :data-source="tagList"
        :loading="loading"
        :rowKey="(record) => record.id"
        :pagination="pagination"
      >
        <template slot="name" slot-scope="text, record, i">
          <a-input
            placeholder="请输入"
            v-model.trim="record.name"
            :maxLength="10"
            ref="inputRef"
            v-if="i === index && flag"
          ></a-input>
          <span v-else class="name">{{ record.name }}</span>
        </template>

        <div slot="action" slot-scope="text, record, i" class="action">
          <template v-if="i === index && flag">
            <div class="save" @click="save(text)">保存</div>
            <div class="cancel" @click="cancel">取消</div>
          </template>
          <template v-else>
            <div class="edit" @click="editTag(record, i)">修改</div>
            <div class="del" @click="delTag(record)">删除</div>
          </template>
        </div>
      </a-table>
    </div>
    <div class="handle-modal" ref="handleRefModel">
      <a-modal
        v-model="visible"
        :maskClosable="false"
        title="操作确认"
        centered
        width="395px"
        :getContainer="() => $refs.handleRefModel"
        @ok="handleOk"
        @cancel="handleCancel"
        cancelText="否"
        okText="是"
      >
        <div>
          确认
          <span>“{{ text }}”</span> {{ current.name }} ？
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { addTag, getTag, delTag, editTag } from "../common/api/tag";
import { cloneDeep } from "lodash";
import MyModal from "../components/MyModal.vue";
const columns = [
  {
    title: "Tag内容",
    dataIndex: "name",
    key: "name",
    width: 1000,
    scopedSlots: { customRender: "name" },
    align: "center",
  },

  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: "center",
  },
];
export default {
  name: "Tag",
  props: {},
  components: {
    MyModal,
  },
  data() {
    return {
      tag: "",
      type: "",
      loading: false,
      current: {},
      text: "",
      visible: false,
      index: null,
      columns,
      tagList: [],
      flag: false,
      form: {
        page: 0,
        size: 10,
      },
      // 分页配置对象
      pagination: {
        position: "bottom",
        pageSize: 10,
        current: 1,
        total: 0,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["5", "10", "20"], // 每页数量选项
        showQuickJumper: true,
        // 显示总数
        showTotal: (total) => `共 ${total} 条`,
        // 改变每页数量时更新显示
        onShowSizeChange: (page, pageSize) => this.onSizeChange(page, pageSize),
        // 点击页码事件
        onChange: (page, pageSize) => this.onPageChange(page, pageSize),
      },
    };
  },
  methods: {
    async handleOk() {
      const { id } = this.current;
      if (this.text === "删除") {
        const res = await delTag({ id });
        console.log(res);

        this.$message.success(res.data);
        if (this.tagList.length === 1 && this.form.page !== 0) {
          this.form.page--;
          this.pagination.current--;
        }
        this.$store.commit("updateRefreshPath", "question");
      }

      this.visible = false;
      this.getTagList();
    },
    handleCancel() {
      this.current = {};
      this.visible = false;
    },
    // 点击页码事件
    onPageChange(page) {
      if (this.flag) {
        this.tagList.splice(0, 1);
        this.flag = false;
      }
      this.pagination.current = page;
      this.form.page = page - 1;
      this.getTagList();
    },
    // 改变每页数量时更新显示
    onSizeChange(page, pageSize) {
      console.log("每页数量:", pageSize);
      this.pagination.current = 1;
      this.pagination.pageSize = pageSize;
      this.form.page = 0;
      this.form.size = pageSize;
      this.getTagList();
    },
    // 获取Tag列表
    async getTagList() {
      this.loading = true;
      const res = await getTag(this.form);
      this.loading = false;
      console.log(res);
      this.tagList = res.data.content;
      this.pagination.total = res.data.totalElements;
    },
    // 生成随机颜色
    generateRandomHexColor() {
      const hex = Math.floor(Math.random() * 0xffffff).toString(16);
      return "#" + ("000000" + hex).slice(-6);
    },
    save: debounce(async function (params) {
      if (!params.name) return this.$message.info("请输入Tag内容");

      // console.log(params);
      if (params.id) {
        const res = await editTag(params);
        this.$message.success(res.msg);
      } else {
        params.color = this.generateRandomHexColor();
        const res = await addTag(params);
        this.$message.success(res.msg);
      }
      this.$store.commit("updateRefreshPath", "question");
      this.flag = false;
      this.getTagList();
    }, 300),
    // async save(params) {
    //   if (!params.name) return this.$message.info("请输入Tag内容");

    //   // console.log(params);
    //   if (params.id) {
    //     const res = await editTag(params);
    //     this.$message.success(res.msg);
    //   } else {
    //     params.color = this.generateRandomHexColor();
    //     const res = await addTag(params);
    //     this.$message.success(res.msg);
    //   }
    //   this.$store.commit("updateRefreshPath", "question");
    //   this.flag = false;
    //   this.getTagList();
    // },
    cancel() {
      if (!this.type) {
        this.tagList.splice(0, 1);
      } else {
        this.tagList[this.index].name = this.tag;
      }

      this.flag = false;
      this.index = null;
    },

    async createTag() {
      this.type = "";
      this.form.page = 0;
      this.pagination.current = 1;
      await this.getTagList();
      this.tagList.unshift({ name: "", id: "" });
      this.index = 0;
      this.flag = true;
      setTimeout(() => {
        this.$refs.inputRef.focus();
      }, 0);
    },
    editTag(record, i) {
      this.type = "edit";
      this.tag = cloneDeep(record.name);
      console.log(record, i);
      this.index = i;
      this.flag = true;
    },
    async delTag(record) {
      this.text = "删除";
      this.current = record;
      this.visible = true;
    },
  },
  created() {
    this.getTagList();
  },
};
</script>

<style scoped lang="scss">
.Tag {
  border-radius: 16px;
  background-color: #fff;
  padding: 24px;
  box-sizing: border-box;
  .tag-header {
    .tag-header-top {
      display: flex;
      align-items: center;
      .title {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #303030;
        .oblong {
          margin-right: 8px;
          width: 4px;
          height: 24px;
          background: #83ba7b;
        }
      }
      .btn {
        margin: 0 16px 0 30px;
        padding: 0 16px;
        height: 32px;
        background: #83ba7b;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .tag-main {
    margin-top: 20px;
    .ant-table-wrapper::v-deep {
      .ant-table-tbody {
        tr {
          td {
            padding: 9px 16px;
          }
        }
      }
    }

    .ant-input {
      width: 344px;
      text-align: center;
    }
    .name {
      line-height: 32px;
    }
    .action {
      display: flex;
      justify-content: center;

      div {
        width: 64px;
        height: 24px;
        line-height: 22px;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid #60b538;
        color: #fff;
        cursor: pointer;
        &:nth-child(n + 2) {
          margin-left: 10px;
        }
        &.save {
          background: #60b538;
        }
        &.cancel {
          border: 1px solid #bfc1c6;
          background: #fff;
          color: #bfc1c6;
        }

        &.edit {
          border-color: #3298f1;
          color: #3298f1;
        }
        &.del {
          border-color: #dc3333;
          color: #dc3333;
        }
      }
    }
  }
  .handle-modal::v-deep {
    .ant-modal-header {
      .ant-modal-title {
        text-align: center !important;
      }
    }
    .ant-modal-body {
      text-align: center;
      span {
        color: #ec4e44;
      }
    }
    .ant-modal-footer {
      text-align: center;
      padding-bottom: 44px;
      border: 0;
      div button {
        width: 72px;
        height: 32px;
        &:nth-child(2) {
          margin-left: 80px;
          background: #83ba7b;
          border: 0;
        }
      }
    }
  }
}
</style>
