import $http from '../http'




// 获取问卷模板列表
export const getTemplate = data => {
    return $http.get(`/api/v1/admin/template`, data)
}

// 删除问卷模板
export const delTemplate = data => {
    return $http.del(`/api/v1/admin/template/del/${data.ids}`)
}







// 禁用模板
export const disableTemplate = data => {
    return $http.put(`/api/v1/admin/template/disable/${data.id}`)
}

// 启用模板
export const enableTemplate = data => {
    return $http.put(`/api/v1/admin/template/enable/${data.id}`)
}

// 新建模板
export const createTemplate = data => {
    return $http.post(`/api/v1/admin/template`, data)
}

// 编辑模板
export const editTemplate = data => {
    return $http.put(`/api/v1/admin/template/${data.id}`, data)
}

// 获取模板详情
export const getTemplateById = data => {
    return $http.get(`/api/v1/templates/${data.id}`)
}