import $http from '../http'

// 新建问题
export const addQuestion = data => {
    return $http.post(`/api/v1/admin/items`, data)
}

// 删除问题
export const delQuestion = data => {
    return $http.del(`/api/v1/admin/items/del/${data.ids}`, data)
}

// 修改问题
export const editQuestion = data => {
    return $http.put(`/api/v1/admin/items/${data.idx}`, data)
}

// 获取应用该问题的问卷模板
export const getTemplateById = data => {
    return $http.get(`/api/v1/admin/items/${data.id}/templates`)
}


// 获取问题列表
export const getQuestion = data => {
    return $http.get(`/api/v1/admin/items`, data)
}

// 获取问题详情
export const getQuestionDetails = data => {
    return $http.get(`/api/v1/admin/items/${data.id}`, data)
}

// 新建问题组
export const addQuestionGroup = data => {
    return $http.post(`/api/v1/admin/itemgroups`, data)
}

// 获取问题组
export const getQuestionGroup = data => {
    return $http.get(`/api/v1/admin/itemgroups`, data)
}

// 删除Tag
export const delQuestionGroup = data => {
    return $http.del(`/api/v1/admin/itemgroups/${data.id}`, data)
}

// 修改问题组
export const editQuestionGroup = data => {
    const params = JSON.parse(JSON.stringify(data))
    delete params.id
    return $http.put(`/api/v1/admin/itemgroups/${data.id}`, params)
}

