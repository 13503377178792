<template>
  <div class="Order">
    <div class="order-header">
      <div class="order-header-top">
        <module-title text="工单搜索"></module-title>
      </div>
      <div class="order-header-bottom">
        <div class="order-header-bottom-left">
          <a-form-model
            :model="form"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :colon="false"
          >
            <a-form-model-item label="工单编号">
              <a-input
                v-model="form.code"
                placeholder="请输入工单编号"
                @pressEnter="find"
              />
            </a-form-model-item>
            <a-form-model-item label="客户名称">
              <a-input
                v-model="form.name"
                placeholder="请输入客户名称"
                @pressEnter="find"
              />
            </a-form-model-item>
            <a-form-model-item label="集团品牌">
              <searchSelect
                :bid="form.bid"
                :iptWidth="220"
                @updateParams="groupChange"
              ></searchSelect>
            </a-form-model-item>
            <a-form-model-item label="工单类型">
              <a-select @change="radioTypeChange" :defaultValue="defaultValue">
                <a-select-option
                  v-for="(item, index) in orderType"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="工单状态">
              <a-select v-model="form.statusId">
                <a-select-option
                  :value="item.id"
                  v-for="(item, index) in orderStatus"
                  :key="index"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="创建人">
              <a-radio-group :defaultValue="0" @change="radioCreateChange">
                <a-radio
                  v-for="(item, index) in createBy"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-form-model>
        </div>
        <div class="order-header-bottom-right" @click="find">查询</div>
      </div>
    </div>
    <div class="order-main">
      <div class="order-main-header">
        <div class="order-main-header-left">
          <module-title text="工单列表"></module-title>
          <div class="creat-user" @click="createOrder">新建工单</div>
          <div>
            <batch-import :url="orderUrl"></batch-import>
          </div>
          <a
            class="download"
            download
            :href="
              http.baseURL +
              '/template/%E5%B7%A5%E5%8D%95%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88.xlsx'
            "
            >模板下载</a
          >
        </div>
        <div class="order-main-header-right">
          <div>批量操作</div>
          <div :class="['assign', active ? 'active' : '']" @click="assignTask">
            指派
          </div>
          <div :class="['ok', active ? 'active' : '']" @click="assignOk">
            完成
          </div>
          <div :class="['close', active ? 'active' : '']" @click="assignCancel">
            关闭
          </div>
        </div>
      </div>
      <div class="order-main-footer">
        <a-table
          :columns="columns"
          :data-source="orderList"
          :loading="loading"
          :row-selection="rowSelection"
          :rowKey="(record) => record.id"
          :pagination="pagination"
          :customRow="rowClick"
        >
          <!-- 类型筛选 -->
          <span slot="customType">
            <a-select v-model="type" @change="typeChange">
              <a-select-option
                :value="item.id"
                v-for="(item, index) in orderType"
                :key="index"
                >{{ item.name }}
              </a-select-option>
            </a-select>
          </span>
          <!-- 创建人筛选 -->
          <span slot="customCreate">
            <a-select v-model="create" @change="createChange">
              <a-select-option
                :value="item.id"
                v-for="(item, index) in createBy"
                :key="index"
                >{{ item.name }}
              </a-select-option>
            </a-select>
          </span>
          <!-- 工单状态筛选 -->
          <span slot="customStatus">
            <a-select v-model="status" @change="statusChange">
              <a-select-option
                :value="item.id"
                v-for="(item, index) in orderStatusOther"
                :key="index"
                >{{ item.name }}
              </a-select-option>
            </a-select>
          </span>
          <span slot="name" slot-scope="item">
            <span>{{ item.name ? item.name : item.strStoreName }}</span>
          </span>
          <span slot="group" slot-scope="groupName">
            <span>{{ groupName ? groupName : "/" }}</span>
          </span>
          <span slot="mobile" slot-scope="mobile">
            <span>{{ mobile ? mobile : "/" }}</span>
          </span>
          <span slot="contacts" slot-scope="contacts">
            <span>{{ contacts ? contacts : "/" }}</span>
          </span>
          <div slot="action" class="action" slot-scope="item, text, index">
            <div
              :class="[
                'edit',
                activeIndex == index && activeType == 'edit' ? 'active' : '',
              ]"
              @click.stop="editOrder(item, text, index)"
            >
              编辑
            </div>
            <div
              :class="[
                'complete',
                activeIndex == index && activeType == 'complete'
                  ? 'active'
                  : '',
              ]"
              @click.stop="editOrderStatus(item, text, index, 'ok')"
            >
              完成
            </div>
            <div
              :class="[
                'shut',
                activeIndex == index && activeType == 'shut' ? 'active' : '',
              ]"
              @click.stop="editOrderStatus(item, text, index, 'no')"
            >
              关闭
            </div>
          </div>
        </a-table>
      </div>
    </div>
    <create-order-modal
      :visible="createVisible"
      @cancel="createModalCancel"
      :allSaleList="allSaleList"
      @createOrder="createWorkOrder($event)"
      :title="title"
      :orderInfo="orderInfo"
      ref="createOrder"
    ></create-order-modal>
    <edit-status-modal
      :visible="editStatusVisible"
      :title="editStatusTitle"
      @ok="editStatusOk"
      @cancel="editStatusCancel"
    ></edit-status-modal>
    <assign-task-modal
      :visible="assignTaskVisible"
      :allSaleList="allSaleList"
      @ok="assignTaskOk"
      @cancel="assignTaskCancel"
      ref="assignTask"
    ></assign-task-modal>
    <div v-if="isLoading" class="progress-box">
      <a-progress
        type="circle"
        :width="100"
        strokeColor="#83ba7b"
        :percent="percent"
      />
    </div>
    <MyMask v-if="isLoading"></MyMask>
  </div>
</template>

<script>
import MyMask from "../components/MyMask.vue";
import ModuleTitle from "../components/ModuleTitle";
import CreateOrderModal from "../components/CreateOrderModal.vue";
import {
  getOrder,
  addOrder,
  editOrder,
  editStatus,
  assignOrder,
} from "../common/api/order";
import BatchImport from "../components/BatchImport.vue";
import { orderUrl } from "../common/url";
import { getUser } from "../common/api/user";
import EditStatusModal from "../components/EditStatusModal.vue";
import AssignTaskModal from "../components/AssignTaskModal.vue";
import http from "../common/http";
import SearchSelect from "../components/SearchSelect.vue";

const columns = [
  {
    title: "工单编号",
    dataIndex: "code",
    key: "code",
    align: "center",
  },
  {
    title: "客户/门店名称",
    key: "name",
    scopedSlots: { customRender: "name" },
    align: "center",
  },
  {
    title: "集团名称",
    dataIndex: "groupName",
    key: "groupName",
    scopedSlots: { customRender: "group" },
    align: "center",
  },
  {
    key: "type",
    dataIndex: "type",
    align: "center",
    slots: { title: "customType" },
  },

  {
    title: "联系人",
    key: "contacts",
    dataIndex: "contacts",
    scopedSlots: { customRender: "contacts" },
    align: "center",
  },
  {
    title: "联系电话",
    key: "mobile",
    dataIndex: "mobile",
    scopedSlots: { customRender: "mobile" },
    align: "center",
  },
  {
    title: "创建时间",
    key: "createTime",
    dataIndex: "createTime",
    align: "center",
  },
  {
    slots: { title: "customCreate" },
    key: "createBy",
    dataIndex: "createBy",
    align: "center",
  },
  {
    slots: { title: "customStatus" },
    key: "status",
    dataIndex: "status",
    align: "center",
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: "center",
  },
];
export default {
  name: "Order",

  data() {
    return {
      percent: 0,
      isLoading: false,
      http,
      // 点击的按钮索引
      activeIndex: null,
      activeType: "",
      defaultValue: "",
      // 点击表格行
      rowClick: (record) => ({
        // 事件
        on: {
          click: () => {
            console.log(record, "record");
            this.$router.push({
              path: "/orderdetails",
              query: {
                id: record.id,
              },
            });
          },
        },
      }),
      // 工单信息
      orderInfo: {},
      // 新建工单弹框显示状态
      createVisible: false,
      // 筛选类型
      type: "工单类型",
      // 创建人筛选
      create: "创建人",
      // 工单状态筛选
      status: "工单状态",
      columns,
      // 列表项配置对象
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) =>
          this.onRowChange(selectedRowKeys, selectedRows),
      },
      // 分页配置对象
      pagination: {
        position: "bottom",
        pageSize: 10,
        current: 1,
        total: 0,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["5", "10", "20"], // 每页数量选项
        showQuickJumper: true,
        // 显示总数
        showTotal: (total) => `共 ${total} 条`,
        // 改变每页数量时更新显示
        onShowSizeChange: (page, pageSize) => this.onSizeChange(page, pageSize),
        // 点击页码事件
        onChange: (page, pageSize) => this.onPageChange(page, pageSize),
      },

      labelCol: { span: 7 },
      wrapperCol: { span: 16, offset: 1 },
      form: {
        page: 0,
        size: 10,
        name: "",
        code: "",
        bid: undefined,
        createBy: 0,
        typeId: "",
        statusId: "",
      },
      orderUrl,
      // 工单列表
      orderList: [],
      // 工单类型
      orderType: [
        {
          id: "",
          name: "不限",
        },
        {
          id: 1,
          name: "产品咨询",
        },
        {
          id: 2,
          name: "故障报修",
        },
        {
          id: 3,
          name: "巡店计划",
        },
        {
          id: "0",
          name: "其他",
        },
      ],
      // 工单状态
      orderStatus: [
        {
          id: "",
          name: "不限",
        },
        {
          id: 1,
          name: "排队中",
        },
        {
          id: 2,
          name: "处理中",
        },
        {
          id: 3,
          name: "已完成",
        },
        {
          id: 4,
          name: "已关闭",
        },
        {
          id: 8,
          name: "未结束 (排队中+处理中)",
        },
        {
          id: 9,
          name: "已结束 (已完成+已关闭)",
        },
      ],
      // 工单状态
      orderStatusOther: [
        {
          id: "",
          name: "不限",
        },
        {
          id: 1,
          name: "排队中",
        },
        {
          id: 2,
          name: "处理中",
        },
        {
          id: 3,
          name: "已完成",
        },
        {
          id: 4,
          name: "已关闭",
        },
      ],
      // 创建人
      createBy: [
        {
          id: 0,
          name: "不限",
        },
        {
          id: 1,
          name: "客户",
        },
        {
          id: 2,
          name: "客服",
        },
        {
          id: 3,
          name: "区域经理/服务工程师",
        },
      ],
      // 表格数据加载状态
      loading: false,
      // 业务员列表
      staffList: [],
      // 领导列表
      leaderList: [],
      // 搜索门店请求参数
      shopParams: {
        page: 0,
        size: 999,
        name: "",
      },
      title: "",
      editStatusVisible: false,
      assignTaskVisible: false,
      // 修改工单状态弹框标题
      editStatusTitle: "",
      // 修改工单状态请求参数
      editStatusParams: {},
      // 批量操作id数组
      assignId: [],
      // 所有业务员列表
      allSaleList: [],
      active: false,
    };
  },
  components: {
    MyMask,
    ModuleTitle,
    BatchImport,
    CreateOrderModal,
    EditStatusModal,
    AssignTaskModal,
    SearchSelect,
  },
  methods: {
    // 查询工单
    find() {
      this.form.page = 0;
      this.pagination.current = 1;
      this.getOrderList();
    },

    // 批量完成
    assignOk() {
      if (this.assignId.length == 0) {
        return this.$message.info("请选择工单");
      }
      this.editOrderStatus(1, 1, 1, "ok", this.assignId);
    },
    // 批量关闭
    assignCancel() {
      if (this.assignId.length == 0) {
        return this.$message.info("请选择工单");
      }
      this.editOrderStatus(1, 1, 1, "no", this.assignId);
    },
    // 选中项发生变化时的回调
    onRowChange(selectedRowKeys, selectedRows) {
      console.log(selectedRowKeys, selectedRows);
      this.assignId = selectedRowKeys;
      if (this.assignId.length > 0) {
        this.active = true;
      } else {
        this.active = false;
      }
    },
    // 修改工单状态
    editOrderStatus(item, text, index, status, ids) {
      this.activeIndex = index;
      if (ids) {
        this.editStatusParams.ids = ids.join(",");
      } else {
        this.editStatusParams.ids = item.id;
      }
      console.log(this.editStatusParams.ids);

      // 状态id 3:已完成 4:已关闭
      if (status == "ok") {
        if (item.status == "已完成") {
          return this.$message.info("当前订单状态为已完成，不需要更改");
        }
        this.activeType = "complete";
        this.editStatusTitle = "完成";
        this.editStatusParams.statusId = 3;
      } else if (status == "no") {
        if (item.status == "已关闭") {
          return this.$message.info("当前订单状态为已关闭，不需要更改");
        }
        this.activeType = "shut";
        this.editStatusTitle = "关闭";
        this.editStatusParams.statusId = 4;
      }
      this.editStatusVisible = true;
    },
    // 确认修改工单状态
    async editStatusOk() {
      const res = await editStatus(this.editStatusParams);
      console.log(res);
      this.$message.success(res.msg);
      this.activeIndex = null;
      this.activeType = "";

      this.editStatusVisible = false;

      this.getOrderList();
    },
    // 取消修改工单状态
    editStatusCancel() {
      this.activeIndex = null;
      this.activeType = "";
      this.editStatusVisible = false;
    },
    // 批量指派工单
    assignTask() {
      if (this.assignId.length == 0) {
        return this.$message.info("请选择工单");
      }
      this.assignTaskVisible = true;
    },
    // 确认批量指派工单
    async assignTaskOk(data) {
      console.log(data);
      let params = JSON.parse(JSON.stringify(data));
      params.nextVistDay = "";
      if (data.nextVistDay) {
        params.nextVistDay = data.nextVistDay.format("YYYY-MM-DD");
      } else {
        delete params.nextVistDay;
      }

      params.ids = this.assignId.join(",");
      console.log(params);
      const res = await assignOrder(params);

      this.$refs.assignTask.assignForm = {
        nextVistDay: null,
        uid: undefined,
      };
      this.$refs.assignTask.active = null;
      this.$message.success(res.msg);
      this.assignTaskVisible = false;
      this.getOrderList();
    },
    // 取消批量指派工单
    assignTaskCancel() {
      this.assignTaskVisible = false;
    },
    // 编辑工单
    editOrder(item, text, index) {
      this.activeIndex = index;
      this.activeType = "edit";
      this.title = "编辑工单";

      this.createVisible = true;
      this.orderInfo = text;
      console.log(this.orderInfo);
    },
    // 新建工单,编辑工单
    async createWorkOrder(data) {
      console.log("请求参数1", data);
      let params = JSON.parse(JSON.stringify(data));
      params.nextVistDay = "";
      if (data.nextVistDay) {
        params.nextVistDay = data.nextVistDay.format("YYYY-MM-DD");
      } else {
        delete params.nextVistDay;
      }
      console.log("请求参数2", params);
      if (params.id) {
        // 编辑工单
        const res = await editOrder(params);
        this.$message.success("编辑成功");
      } else {
        // 新建工单
        const res = await addOrder(params);
        this.$message.success(res.msg);
        this.pagination.current = 1;
        this.form.page = 0;
      }

      this.activeIndex = null;
      this.activeType = "";
      this.$refs.createOrder.$refs.ruleForm.resetFields();
      this.$refs.createOrder.createOrderForm = {
        contacts: "",
        content: "",
        mobile: "",
        nextVistDay: null,
        storeId: "",
        strAddress: "",
        strName: undefined,
        subject: "",
        typeId: undefined,
        uid: undefined,
        urlLists: [],
      };
      this.$refs.createOrder.fileList = [];
      this.$refs.createOrder.active = null;
      this.$refs.createOrder.groupName = "";

      this.createVisible = false;
      this.orderInfo = {};

      this.getOrderList();
    },
    // 获取直属领导列表
    async getLeaderList() {
      const params = {
        page: 0,
        size: 999,
        roleId: 3,
      };
      const res = await getUser(params);
      this.leaderList = res.data.content;
      this.handleAllSale(this.staffList, this.leaderList);
    },
    // 获取业务员列表
    async getStaffList() {
      const params = {
        page: 0,
        size: 999,
        roleId: 4,
      };
      const res = await getUser(params);
      // console.log("业务员列表:", res);
      this.staffList = res.data.content;
      this.getLeaderList();
    },
    // 获取所有业务员列表
    handleAllSale(a, b) {
      this.allSaleList = [...a, ...b];
      // 这里对id属性进行去重
      const map = new Map();
      this.allSaleList = this.allSaleList.filter(
        (item) => !map.has(item.id) && map.set(item.id, 1)
      );
    },
    // 关闭新建工单弹框
    createModalCancel() {
      this.activeIndex = null;
      this.activeType = "";
      this.createVisible = false;
    },
    // 选择集团
    groupChange(e) {
      if (e == 0) {
        this.form.bid = 0;
      } else {
        this.form.bid = e;
      }
    },
    // 工单搜索  工单类型
    radioTypeChange(e) {
      this.form.typeId = e;
    },
    // 工单搜索  工单状态
    radioStatusChange(e) {
      this.form.statusId = e;
    },
    // 工单搜索  创建人
    radioCreateChange(e) {
      this.form.createBy = e.target.value;
    },
    // 筛选类型
    typeChange(e) {
      if (e == "") {
        this.type = "工单类型";
      }
      this.form.typeId = e;
      this.pagination.current = 1;
      this.form.page = 0;
      this.getOrderList();
    },
    // 创建人筛选
    createChange(e) {
      if (e == 0) {
        this.create = "创建人";
      }
      this.form.createBy = e;
      this.pagination.current = 1;
      this.form.page = 0;
      this.getOrderList();
    },
    // 工单状态筛选
    statusChange(e) {
      if (e == 0) {
        this.status = "工单状态";
      }
      this.form.statusId = e;
      this.pagination.current = 1;
      this.form.page = 0;
      this.getOrderList();
    },
    // 新建工单
    createOrder() {
      this.title = "新建工单";
      this.createVisible = true;
      this.orderInfo = {};
    },
    // 获取工单列表
    async getOrderList() {
      let obj = JSON.parse(JSON.stringify(this.form));
      obj.page = obj.page.toString();
      obj.typeId = obj.typeId.toString();

      for (let k in obj) {
        if (!obj[k]) {
          delete obj[k];
        }
      }
      this.loading = true;
      // console.log("obj", obj);
      const res = await getOrder(obj);
      this.loading = false;
      console.log(res);
      this.orderList = res.data.content;
      this.pagination.total = res.data.totalElements;
    },
    // 改变每页数量时更新显示
    onSizeChange(page, pageSize) {
      console.log("每页数量:", pageSize);
      this.pagination.current = 1;
      this.pagination.pageSize = pageSize;
      this.form.page = 0;
      this.form.size = pageSize;
      this.getOrderList();
    },
    // 点击页码事件
    onPageChange(page) {
      console.log("页码值:", page);
      this.pagination.current = page;
      this.form.page = page - 1;
      this.getOrderList();
    },
  },
  created() {
    this.$store.commit("updateIsKeepAlive", false);
    console.log("created");
    if (this.$route.query.tempObj) {
      const params = JSON.parse(this.$route.query.tempObj);
      this.form.statusId = params.statusId;
      this.form.name = params.name;
    }
    this.getOrderList();
    this.getStaffList();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log("进入路由之前", to);
      console.log(from.name);
      let isKeepAlive = vm.$store.state.isKeepAlive;
      console.log(isKeepAlive, "isKeepAlive");
      console.log(from.name, "from.name");
      console.log(from.name == "Clientdetails " && isKeepAlive);
      if (from.name == "Clientdetails" && isKeepAlive) {
        to.meta.keepAlive = false;
      } else {
        to.meta.keepAlive = true;
      }

      console.log("进入路由之后", to);
    });
  },
  activated() {
    this.$store.commit("updateIsKeepAlive", false);

    if (this.$route.query.params) {
      const params = JSON.parse(this.$route.query.params);
      console.log(params, "传递参数");
      this.form.statusId = params.statusId;
      this.form.name = params.name;
    }
    this.getOrderList();
    this.getStaffList();
    let index = this.$store.state.refreshPath.findIndex((v) => {
      return v == "order";
    });
    if (index != -1) {
      console.log("触发了'activated'");
      this.getOrderList();
      this.getStaffList();
      this.$store.commit("delRefreshPath", "order");
    }
  },
};
</script>

<style scoped lang="scss">
.Order {
  box-sizing: border-box;
  .order-header {
    background: #ffffff;
    border-radius: 16px;
    padding: 24px 0 30px 24px;
    .order-header-bottom {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      padding-right: 150px;
      box-sizing: border-box;
      .order-header-bottom-left {
        flex: 1;
        .ant-form {
          display: flex;
          flex-wrap: wrap;
          .ant-row {
            width: 28%;
            margin-right: 32px;
            margin-bottom: 0;
            margin-top: 24px;
            display: flex;
            align-items: center;
            &:nth-child(-n + 3) {
              margin-top: 0;
            }
            &:nth-child(3) {
              margin-right: 0;
            }
            .ant-input {
              width: 220px;
              height: 30px;
            }
            .ant-select {
              width: 220px;
              height: 30px;
            }
            .ant-radio-group {
              display: flex;
            }
          }
        }
      }
      .order-header-bottom-right {
        margin-top: 5px;
        width: 72px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        color: #ffffff;
        background: #83ba7b;
        opacity: 1;
        border-radius: 4px;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
  .order-main {
    margin-top: 33px;
    background: #ffffff;
    border-radius: 16px;
    padding: 24px 32px 40px 24px;
    box-sizing: border-box;
    .order-main-header {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .order-main-header-left {
        display: flex;
        align-items: center;
        .title {
          display: flex;
          align-items: center;
          font-weight: 500;
          color: #303030;
          .oblong {
            margin-right: 8px;
            width: 4px;
            height: 24px;
            background: #83ba7b;
          }
        }
        .creat-user {
          margin: 0 16px 0 30px;
          width: 88px;
          height: 32px;
          background: #83ba7b;
          border-radius: 4px;
          text-align: center;
          line-height: 32px;
          color: #fff;
          cursor: pointer;
        }
        .download {
          margin-left: 16px;
          color: #83ba7b;
          font-size: 14px;
        }
      }
      .order-main-header-right {
        margin-right: 158px;
        display: flex;
        align-items: center;
        div:nth-child(n + 2) {
          margin-left: 16px;
          width: 72px;
          height: 32px;
          border-radius: 4px;
          text-align: center;
          line-height: 32px;
          font-size: 14px;
          color: #ffffff;
        }
        .assign {
          background: rgba(50, 152, 241, 0.5);
          cursor: pointer;
          &.active {
            background: #3298f1;
          }
        }
        .ok {
          background: rgba(30, 176, 64, 0.5);
          cursor: pointer;
          &.active {
            background: #1eb040;
          }
        }
        .close {
          background: rgba(191, 193, 198, 0.5);
          cursor: pointer;
          &.active {
            background: #bfc1c6;
          }
        }
      }
    }
    .order-main-footer {
      .action {
        display: flex;
        align-items: center;
        justify-content: center;

        div {
          width: 64px;
          height: 24px;
          line-height: 22px;
          color: #fff;
          opacity: 1;
          border-radius: 4px;
          cursor: pointer;
        }
        .complete {
          margin: 0 16px;
          background-color: #fff;
          color: #1eb040;
          border: 1px solid #1eb040;
          box-sizing: border-box;
          &.active {
            color: #fff;
            border: 0;
            background-color: #1eb040;
            line-height: 24px;
          }
        }
        .shut {
          box-sizing: border-box;
          color: #bfc1c6;
          border: 1px solid #bfc1c6;
          background-color: #fff;
          &.active {
            color: #fff;
            border: 0;
            background-color: #bfc1c6;
            line-height: 24px;
          }
        }
        .edit {
          box-sizing: border-box;
          color: #3298f1;
          border: 1px solid #3298f1;
          background-color: #fff;
          &.active {
            color: #fff;
            border: 0;
            background: #3298f1;
            line-height: 24px;
          }
        }
      }
    }
  }
  .progress-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
}
</style>
