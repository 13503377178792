import $http from '../http'

// 获取门店详情
export const getStore = data => {

    return $http.get(`/api/v1/admin/store/${data.id}`, data)
}

// 获取门店区域
export const getRegion = data => {

    return $http.get(`/api/v1/config/store/region`, data)
}

// 获取门店列表
export const getClient = data => {

    return $http.get(`/api/v1/admin/store/list`, data)
}

// 新建客户
export const createStore = data => {

    return $http.post(`/api/v1/admin/store`, data)
}

// 获取门店集团
// export const getGroup = data => {

//     return $http.get(`/api/v1/config/store/group`, data)
// }

// 批量删除门店
export const delStore = data => {

    return $http.del(`/api/v1/admin/store/${data.ids}`, data)
}

// 门店批量指派业务员
export const assignSale = data => {
    const params = JSON.parse(JSON.stringify(data))
    delete params.ids
    return $http.put(`/api/v1/admin/store/assign/${data.ids}`, params)
}

// 编辑客户
export const editStore = data => {
    const params = JSON.parse(JSON.stringify(data))
    delete params.id

    return $http.put(`/api/v1/admin/store/${data.id}`, data)
}

// 获取门店的工单列表
export const getStoreOrder = data => {
    const params = JSON.parse(JSON.stringify(data))
    delete params.id

    return $http.get(`/api/v1/admin/store/${data.id}/workorder`, params)
}

// 获取门店的历史回访报告
export const getTasks = data => {
    const params = JSON.parse(JSON.stringify(data))
    delete params.id

    return $http.get(`/api/v1/admin/store/${data.id}/tasks`, params)
}
