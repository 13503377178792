<template>
  <div class="PwdModal">
    <div class="pwd-user-modal" ref="pwdRefModel">
      <a-modal
        v-model="isShow"
        :maskClosable="false"
        :title="title"
        @ok="handleOk"
        centered
        width="395px"
        :getContainer="() => $refs.pwdRefModel"
        @cancel="handleCancel"
        :cancelText="cancelText"
        :okText="okText"
      >
        <a-form-model
          layout="horizontal"
          :model="form"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="ruleForm"
          :rules="rules"
        >
          <a-form-model-item label="请输入当前密码" prop="oldPwd" v-if="flag">
            <a-input
              v-model="form.oldPwd"
              placeholder="请输入当前密码"
              type="password"
            />
          </a-form-model-item>
          <a-form-model-item label="请输入新密码" prop="password">
            <a-input
              v-model="form.password"
              placeholder="请输入新密码"
              type="password"
            />
          </a-form-model-item>
          <a-form-model-item label="再次输入新密码" prop="confirmPwd">
            <a-input
              v-model="form.confirmPwd"
              placeholder="请再次输入新密码"
              type="password"
            />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "PwdModal",
  props: {
    cancelText: {
      type: String,
      default: "否",
    },
    okText: {
      type: String,
      default: "是",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default() {
        return {};
      },
    },
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    flag: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const validatePass = (rule, value, callback) => {
      // if (value === "") {
      //   callback(new Error("请输入新密码"));
      // } else {
      if (this.form.confirmPwd !== "") {
        this.$refs.ruleForm.validateField("confirmPwd");
      }
      callback();
      // }
    };
    const validatePass2 = (rule, value, callback) => {
      // if (value === "") {
      //   callback(new Error("请再次输入新密码"));
      // } else
      if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      labelCol: { span: 9 },
      wrapperCol: { span: 13, offset: 1 },
      // 表单数据对象
      form: {
        oldPwd: "",
        password: "",
        confirmPwd: "",
      },
      // 是否显示
      isShow: false,
      // 表单校验规则
      rules: {
        oldPwd: [
          { required: true, message: "请输入当前密码", trigger: "blur" },
          {
            min: 8,
            max: 16,
            message: "长度在 8 到 16 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            min: 8,
            max: 16,
            message: "长度在 8 到 16 个字符",
            trigger: "blur",
          },
          { validator: validatePass, trigger: "blur" },
        ],
        confirmPwd: [
          { required: true, message: "请再次输入新密码", trigger: "blur" },
          {
            min: 8,
            max: 16,
            message: "长度在 8 到 16 个字符",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur", required: true },
        ],
      },
    };
  },
  watch: {
    visible(val) {
      this.isShow = val;
    },
  },

  methods: {
    // 确认
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log("this.form", this.form);
          this.$emit("ok", this.form);
        }
      });
    },
    handleCancel() {
      this.$refs.ruleForm.resetFields();
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped lang="scss">
.pwd-user-modal::v-deep {
  .ant-modal-header {
    .ant-modal-title {
      text-align: center !important;
    }
  }
  .ant-modal-body {
    padding: 24px 0;
    text-align: center;
    display: flex;
    justify-content: center;
    .ant-form-item {
      margin-bottom: 19px;
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }
  .ant-modal-footer {
    text-align: center;
    padding-bottom: 44px;
    border: 0;
    div button {
      width: 72px;
      height: 32px;
      &:nth-child(2) {
        margin-left: 80px;
        background: #83ba7b;
        border: 0;
      }
    }
  }
}
</style>
