<template>
  <div class="notFound">
    <img src="../assets/image/404.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "404",
  props: {},
  components: {},
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<style scoped lang="scss">
.notFound {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 404px;
    height: 404px;
  }
}
</style>
