<template>
  <div class="Template">
    <div class="template-header">
      <div class="template-header-top">
        <div class="title">
          <div class="oblong"></div>
          <div>问卷模板列表</div>
        </div>
        <div class="btn" @click="createTemplate">新增问卷模板</div>
      </div>
      <div class="template-header-bottom">
        <div class="template-header-bottom-left">
          <a-form-model
            :model="form"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :colon="false"
          >
            <a-form-model-item label="问卷模板状态筛选">
              <a-radio-group v-model="form.enable">
                <a-radio
                  v-for="(item, index) in enableList"
                  :key="index"
                  :value="item.val"
                >
                  {{ item.text }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-form-model>
        </div>
        <div class="template-header-bottom-right" @click="search">筛选</div>
      </div>
    </div>
    <div class="template-main">
      <a-table
        :columns="columns"
        :loading="loading"
        :data-source="templateList"
        :rowKey="(record) => record.id"
        :pagination="pagination"
      >
        <div slot="action" slot-scope="item, record, index" class="action">
          <div @click="look(item.id)">查看</div>
          <div class="del" @click="del(item.id)">删除</div>
          <div
            class="enable"
            :style="{ background: item.enable ? '#1EB040' : '#F68714' }"
            @click="editStatus(item, index)"
          >
            {{ item.enable ? "启用中" : "停用中" }}
          </div>
        </div>
      </a-table>
    </div>
    <div ref="handleRefModel" class="handle-modal">
      <a-modal
        v-model="delVisible"
        title="操作确认"
        @ok="handleOk"
        :maskClosable="false"
        centered
        width="395px"
        :getContainer="() => $refs.handleRefModel"
        cancelText="否"
        okText="是"
      >
        <div>确认删除所选问卷模板？</div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import {
  getTemplate,
  delTemplate,
  disableTemplate,
  enableTemplate,
} from "../../src/common/api/template";
const columns = [
  {
    title: "问卷模板ID",
    dataIndex: "id",
    key: "id",
    width: 300,
    align: "center",
  },

  {
    title: "问卷模板名称",
    dataIndex: "name",
    key: "name",
    align: "center",
  },

  {
    title: "操作",
    key: "action",
    width: 280,
    scopedSlots: { customRender: "action" },
    align: "center",
  },
];
export default {
  name: "Template",
  props: {},

  data() {
    return {
      ids: "",
      delVisible: false,
      loading: false,
      templateList: [
        {
          id: 1,
          name: "卫生检查",
        },
        {
          id: 2,
          name: "环境检查",
        },
      ],
      pagination: {
        position: "bottom",
        pageSize: 10,
        current: 1,
        total: 0,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["5", "10", "20"], // 每页数量选项
        showQuickJumper: true,
        // 显示总数
        showTotal: (total) => `共 ${total} 条`,
        // 改变每页数量时更新显示
        onShowSizeChange: (page, pageSize) => this.onSizeChange(page, pageSize),
        // 点击页码事件
        onChange: (page, pageSize) => this.onPageChange(page, pageSize),
      },
      columns,
      form: {
        page: 0,
        size: 10,
        enable: "",
      },
      enableList: [
        {
          val: "",
          text: "所有",
        },
        {
          val: true,
          text: "启用中",
        },
        {
          val: false,
          text: "停用中",
        },
      ],
      labelCol: { span: 8 },
      wrapperCol: { span: 15, offset: 1 },
    };
  },
  methods: {
    async editStatus({ enable, id }, index) {
      console.log(index);
      if (enable) {
        const res = await disableTemplate({ id });
        this.$message.success(res.data);
        this.templateList[index].enable = !this.templateList[index].enable;
      } else {
        const res = await enableTemplate({ id });
        this.$message.success(res.data);
        this.templateList[index].enable = !this.templateList[index].enable;
      }
    },
    async handleOk() {
      const res = await delTemplate({ ids: this.ids });
      console.log(res);
      this.$message.success(res.data);
      if (this.templateList.length === 1 && this.form.page !== 0) {
        this.form.page--;
        this.pagination.current--;
      }
      this.delVisible = false;
      this.getTemplateList();
    },
    look(id) {
      localStorage.removeItem("tempName");
      this.$router.push({
        path: "/createtemplate",
        query: {
          id,
        },
      });
    },
    del(ids) {
      this.ids = ids;
      this.delVisible = true;
    },

    // 改变每页数量时更新显示
    onSizeChange(page, pageSize) {
      console.log("每页数量:", pageSize);
      this.pagination.current = 1;
      this.pagination.pageSize = pageSize;
      this.form.page = 0;
      this.form.size = pageSize;
      this.getTemplateList();
    },
    // 点击页码事件
    onPageChange(page) {
      console.log("页码值:", page);
      this.pagination.current = page;
      this.form.page = page - 1;
      this.getTemplateList();
    },

    async getTemplateList() {
      this.loading = true;
      const res = await getTemplate(this.form);
      this.loading = false;
      console.log(res);
      this.templateList = res.data.content;
      this.pagination.total = res.data.totalElements;
    },

    createTemplate() {
      localStorage.setItem("toQuestionType", "create");
      localStorage.removeItem("tempName");
      this.$router.push("/createtemplate");
    },
    search() {
      console.log(this.form);
      this.form.page = 0;
      this.pagination.current = 1;
      this.getTemplateList();
    },
  },
  created() {
    this.getTemplateList();
  },
};
</script>

<style scoped lang="scss">
.Template {
  border-radius: 16px;
  background-color: #fff;
  padding: 24px;
  box-sizing: border-box;
  .template-header {
    .template-header-top {
      display: flex;
      align-items: center;
      .title {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #303030;
        .oblong {
          margin-right: 8px;
          width: 4px;
          height: 24px;
          background: #83ba7b;
        }
      }
      .btn {
        margin: 0 16px 0 30px;
        padding: 0 16px;
        height: 32px;
        background: #83ba7b;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        color: #fff;
        cursor: pointer;
      }
    }
    .template-header-bottom {
      display: flex;
      align-items: center;
      padding: 24px 12px 0;
      .template-header-bottom-left {
        width: 30%;
        .ant-form {
          // display: flex;
          // align-items: center;

          .ant-row {
            margin-right: 25px;
            margin-bottom: 0;
            display: flex;
            align-items: center;

            .ant-input,
            .ant-select {
              width: 180px;
              height: 30px;
            }
          }
        }
      }
      .template-header-bottom-right {
        width: 72px;
        height: 32px;
        background: #83ba7b;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .template-main {
    margin-top: 24px;
    .action {
      display: flex;
      align-self: center;
      div {
        margin: auto;
        width: 64px;
        height: 24px;
        border-radius: 4px;
        border: 1px solid #3298f1;
        color: #3298f1;
        cursor: pointer;
        &.del {
          margin-left: 10px;
          border-color: #dc3333;
          color: #dc3333;
        }
        &.enable {
          border: none;
          color: #fff;
        }
      }
    }
  }
  .handle-modal::v-deep {
    .ant-modal-header {
      .ant-modal-title {
        text-align: center !important;
      }
    }
    .ant-modal-body {
      text-align: center;
      span {
        color: #ec4e44;
      }
    }
    .ant-modal-footer {
      text-align: center;
      padding-bottom: 44px;
      border: 0;
      div button {
        width: 72px;
        height: 32px;
        &:nth-child(2) {
          margin-left: 80px;
          background: #83ba7b;
          border: 0;
        }
      }
    }
  }
}
</style>
