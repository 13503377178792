<template>
  <div class="CurrentTasks">
    <div class="currentTasks-header">
      <div class="title">
        <div class="oblong"></div>
        <div>{{ flag ? "当" : "上" }}月应拜访客户列表</div>
      </div>
      <div class="detail">
        <div>
          <span> 月度： </span>
          <span>{{ currentMonth }}</span>
          <span class="status" v-if="flag">（进行中）</span>
        </div>
        <div class="completeness">
          <span> 月完成度： </span>
          <span :class="completeness >= 0.85 ? 'pass' : 'fail'">{{
            (completeness * 100).toFixed(0) + "% "
          }}</span>
        </div>
      </div>
    </div>
    <div class="currentTasks-main">
      <a-table
        :columns="columns"
        :data-source="tasksList"
        :rowKey="(record) => record.id"
        :pagination="pagination"
        :loading="loading"
      >
        <span slot="groupName" slot-scope="groupName">
          <span>{{ groupName ? groupName : "/" }}</span>
        </span>
        <!-- <span slot="region" slot-scope="region">
          <span>{{ region ? region : "/" }}</span>
        </span> -->
        <span slot="manager" slot-scope="manager">
          <span>{{ manager ? manager : "/" }}</span>
        </span>
        <span
          slot="reportPath"
          slot-scope="item"
          :class="item.reportPath ? 'pass' : 'fail'"
        >
          <span
            :class="item.reportPath ? 'download' : ''"
            @click="download(item)"
            >{{ item.reportPath ? "下载" : "未完成" }}</span
          >
        </span>
        <span slot="dealLine" slot-scope="dealLine">
          <span>{{ dealLine ? dealLine : "/" }}</span>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import { getCurrentTasks, getHistoryTasks } from "../common/api/statistics";
import http from "../common/http";

const columns = [
  {
    title: "客户/门店名称",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "所属集团",
    dataIndex: "groupName",
    scopedSlots: { customRender: "groupName" },
    key: "groupName",
    align: "center",
  },
  // {
  //   title: "区域",
  //   dataIndex: "region",
  //   scopedSlots: { customRender: "region" },
  //   key: "region",
  //   align: "center",
  // },
  {
    title: "区域经理/服务工程师",
    key: "username",
    dataIndex: "username",
    align: "center",
  },
  {
    title: "集团经理",
    dataIndex: "groupManager",
    key: "groupManager",
    scopedSlots: { customRender: "manager" },
    align: "center",
  },

  {
    title: "回访报告",
    // dataIndex: "reportPath",
    key: "reportPath",
    scopedSlots: { customRender: "reportPath" },
    align: "center",
  },
  {
    title: "最迟下次回访日",
    dataIndex: "dealLine",
    key: "dealLine",
    scopedSlots: { customRender: "dealLine" },
    align: "center",
  },
];
export default {
  name: "CurrentTasks",
  props: {},
  components: {},
  data() {
    return {
      // 当月完成度
      completeness: "",
      id: "",
      // 当月应拜访客户列表
      tasksList: [],
      columns,
      loading: false,
      // 当前月份
      currentMonth: "",
      month: "",
      params: {
        page: 0,
        size: 10,
        id: "",
      },
      // 分页配置对象
      pagination: {
        position: "bottom",
        pageSize: 10,
        current: 1,
        total: 0,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["5", "10", "20"], // 每页数量选项
        showQuickJumper: true,
        // 显示总数
        showTotal: (total) => `共 ${total} 条`,
        // 改变每页数量时更新显示
        onShowSizeChange: (page, pageSize) => this.onSizeChange(page, pageSize),
        // 点击页码事件
        onChange: (page, pageSize) => this.onPageChange(page, pageSize),
      },
      // 点击表格行
      // rowClick: (record) => ({
      //   // 事件
      //   on: {
      //     click: () => {
      //       console.log(record, "record");
      //       this.$emit("tasksToQues", {
      //         name: record.name,
      //         month: this.month,
      //       });
      //     },
      //   },
      // }),
      flag: true,
    };
  },
  methods: {
    // 获取当前月份
    getMonth() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      let eDate = year + "-" + month;
      console.log("当前月份", eDate);

      return eDate;
    },
    // 获取上个月月份
    getLastMonth() {
      var year, lastMonth;
      var date = new Date();
      var nowYear = date.getFullYear(); //当前年：四位数字
      var nowMonth = date.getMonth(); //当前月：0-11
      if (nowMonth == 0) {
        //如果是0，则说明是1月份，上一个月就是去年的12月
        year = nowYear - 1;
        lastMonth = 12;
      } else {
        //不是1月份，年份为当前年，月份本来是要减1的，但是由于`getMonth()`的月份本身就是少了1的，所以月份不用变。
        year = nowYear;
        lastMonth = nowMonth;
      }

      lastMonth = lastMonth < 10 ? "0" + lastMonth : lastMonth; //月份格式化：月份小于10则追加个0

      let oMonth = year + "-" + lastMonth;

      console.log("前1个月", oMonth);
      return oMonth;
    },
    // 下载回访报告
    download(item) {
      if (!item.reportPath) return;

      const url = http.baseURL + "/" + item.reportPath;
      // let fileName = this.getDay();
      let fileName = item.name + item.finTime.substr(0, 10).replace(/-/g, "");
      console.log(fileName, "fileName");
      console.log(url, "url");
      let reg =
        /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/;
      if (!reg.test(url)) {
        throw new Error("传入参数不合法,不是标准的文件链接");
      } else {
        let xhr = new XMLHttpRequest();
        xhr.open("get", url, true);
        xhr.setRequestHeader("Content-Type", `application/pdf`);
        xhr.responseType = "blob";
        let that = this;
        xhr.onload = function () {
          if (this.status == 200) {
            //接受二进制文件流
            var blob = this.response;
            that.downloadExportFile(blob, fileName);
          }
        };
        xhr.send();
      }
    },
    downloadExportFile(blob, tagFileName) {
      let downloadElement = document.createElement("a");
      let href = blob;
      if (typeof blob == "string") {
        downloadElement.target = "_blank";
      } else {
        href = window.URL.createObjectURL(blob); //创建下载的链接
      }
      downloadElement.href = href;
      downloadElement.download = tagFileName;
      //下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      if (typeof blob != "string") {
        window.URL.revokeObjectURL(href); //释放掉blob对象
      }
    },
    getDay() {
      let time = new Date(),
        year = time.getFullYear(),
        month = time.getMonth() + 1,
        day = time.getDate(),
        timeStem = time.getTime();
      return `${year}/${month}/${day}/${timeStem}.pdf`;
    },
    // 获取历史应拜访客户列表
    async getHistoryTasksList() {
      this.loading = true;
      const res = await getHistoryTasks(this.params);
      this.loading = false;

      this.tasksList = res.data.content;
      this.pagination.total = res.data.totalElements;
    },
    // 获取当月应拜访客户列表-当月(加上所有未完成)
    async getCurrentTasksList() {
      this.loading = true;
      const res = await getCurrentTasks(this.params);
      this.loading = false;

      this.tasksList = res.data.content;
      this.pagination.total = res.data.totalElements;
    },
    // 改变每页数量时更新显示
    onSizeChange(page, pageSize) {
      console.log("每页数量:", pageSize);
      this.pagination.current = 1;
      this.pagination.pageSize = pageSize;
      this.params.page = 0;
      this.params.size = pageSize;
      if (this.flag) {
        this.getCurrentTasksList();
      } else {
        this.getHistoryTasksList();
      }
    },
    // 点击页码事件
    onPageChange(page) {
      console.log("页码值:", page);
      this.pagination.current = page;
      this.params.page = page - 1;
      if (this.flag) {
        this.getCurrentTasksList();
      } else {
        this.getHistoryTasksList();
      }
    },
  },
  created() {
    console.log(this.$route.query.month);
    this.completeness = this.$route.query.completeness;
    this.params.id = this.$route.query.id;
    if (this.$route.query.month) {
      this.flag = false;
      this.month = this.getLastMonth();
      console.log(this.month, "month");
      this.currentMonth = this.month.replace("-", "年") + "月";
      this.params.month = this.month;
      this.getHistoryTasksList();
    } else {
      this.flag = true;
      this.month = this.getMonth();
      this.currentMonth = this.month.replace("-", "年") + "月";
      this.getCurrentTasksList();
    }
  },
  // beforeRouteLeave(to, from, next) {
  //   // //清除缓存方法一
  //   if (to.path == "/Ques") {
  //     if (to.meta.keepAlive) {
  //       to.meta.keepAlive = false; //当我们进入到C时开启B的缓存
  //     }
  //     next();
  //   }
  //   console.log("==========");
  //   console.log("to", to);
  //   console.log("from", from);
  //   console.log("==========");
  // },
};
</script>

<style scoped lang="scss">
.CurrentTasks {
  border-radius: 16px;
  background-color: #fff;
  padding: 24px;
  box-sizing: border-box;
  .currentTasks-header {
    margin-bottom: 20px;
    padding-right: 204px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      display: flex;
      align-items: center;
      font-weight: 500;
      color: #303030;
      .oblong {
        margin-right: 8px;
        width: 4px;
        height: 24px;
        background: #83ba7b;
      }
    }
    .detail {
      display: flex;
      align-items: center;
      .status {
        color: #9a9ea7;
      }
      .completeness {
        margin-left: 60px;
        .pass {
          margin-left: 3px;
          color: #1eb040;
        }
        .fail {
          margin-left: 3px;
          color: #e93024;
        }
      }
    }
  }
  .currentTasks-main {
    background-color: #fff;
    .download {
      cursor: pointer;
    }
    .pass {
      color: #3298f1;
    }
    .fail {
      color: #f68714;
    }
  }
}
</style>
