<template>
  <div class="ClientDetails">
    <div class="client-details-header">
      <div class="client-details-header-top">
        <div class="client-details-title">
          <div class="oblong"></div>
          <div>基本信息</div>
        </div>
        <div class="client-details-option">
          <div v-show="flag" class="edit common" @click="editStoreInfo">
            编辑
          </div>
          <div class="btn" v-show="!flag">
            <div class="save common" @click="save">保存</div>
            <div class="cancel common" @click="cancelStoreInfo">取消</div>
          </div>
          <div
            class="delete common"
            @click="delStore"
            v-if="$store.state.permission == 'a%q&22podll.1vz'"
          >
            删除
          </div>
        </div>
      </div>
      <div class="client-details-header-bottom">
        <div class="client-info">
          <a-form-model
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :colon="false"
            :model="form"
            ref="ruleForm"
            :rules="rules"
          >
            <a-form-model-item
              label="门店ID"
              :label-col="labelCol1"
              :wrapper-col="wrapperCol1"
            >
              {{ storeDetails.id }}
            </a-form-model-item>
            <a-form-model-item label="已指派区域经理/服务工程师">
              <span v-show="flag">
                {{ storeDetails.saleName ? storeDetails.saleName : "/" }}
              </span>
              <a-select
                v-show="!flag"
                v-model="form.uid"
                placeholder="请选择区域经理/服务工程师"
              >
                <a-select-option
                  :value="item.id"
                  v-for="(item, index) in allSaleList"
                  :key="index"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="所属集团品牌">
              <span v-show="flag">{{
                storeDetails.brandName
                  ? storeDetails.groupName + " ─ " + storeDetails.brandName
                  : "/"
              }}</span>
              <SearchSelect
                v-show="!flag"
                :bid="form.bid"
                :flag="false"
                :iptWidth="220"
                @updateParams="groupChange"
              ></SearchSelect>
            </a-form-model-item>

            <a-form-model-item
              label="门店名称"
              prop="name"
              :label-col="labelCol1"
              :wrapper-col="wrapperCol1"
            >
              <a-input
                v-model="form.name"
                placeholder="请输入门店名称"
                :maxLength="50"
                :class="flag ? 'name' : ''"
                v-show="!flag"
              />
              <span v-show="flag" :title="form.name ? form.name : ''">{{
                form.name ? form.name : "/"
              }}</span>
            </a-form-model-item>

            <a-form-model-item label="大区经理/工程服务经理">
              <span>{{
                storeDetails.regionManager ? storeDetails.regionManager : "/"
              }}</span>
            </a-form-model-item>

            <a-form-model-item label="集团经理">
              <span v-show="flag">{{
                storeDetails.groupManager ? storeDetails.groupManager : "无"
              }}</span>
              <a-input
                v-model="form.groupManager"
                placeholder="请输入集团经理"
                v-show="!flag"
                :maxLength="50"
              />
            </a-form-model-item>
            <a-form-model-item
              label="详细地址"
              class="hide"
              :label-col="labelCol1"
              :wrapper-col="wrapperCol1"
            >
              <span v-show="flag" :title="form.address ? form.address : ''">{{
                form.address ? form.address : "/"
              }}</span>
              <a-input
                v-model="form.address"
                placeholder="请输入地址"
                v-show="!flag"
                :maxLength="50"
              />
            </a-form-model-item>
            <!-- <a-form-model-item label="所属区域">
              <span v-show="flag">{{
                storeDetails.region ? storeDetails.region : "/"
              }}</span>
              <a-select
                v-show="!flag"
                v-model="form.region"
                placeholder="请选择区域"
              >
                <a-select-option
                  :value="item"
                  v-for="(item, index) in areaList"
                  :key="index"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-model-item> -->

            <a-form-model-item label="上次拜访">
              <span>{{
                storeDetails.lastVistDate ? storeDetails.lastVistDate : "/"
              }}</span>
            </a-form-model-item>
            <a-form-model-item label="拜访间隔(天)">
              <span v-show="flag">{{
                storeDetails.period ? storeDetails.period + " 天" : "/"
              }}</span>
              <span v-show="!flag"
                ><a-input
                  class="num"
                  @change="timeChange"
                  :value="form.period"
                />天</span
              >
            </a-form-model-item>
            <a-form-model-item
              label="下次拜访截止日"
              :label-col="labelCol1"
              :wrapper-col="wrapperCol1"
            >
              <span v-show="flag">{{
                storeDetails.nxtDealDate ? storeDetails.nxtDealDate : "/"
              }}</span>
              <a-date-picker
                v-show="!flag"
                @change="dateChange"
                :value="form.nextVistDay"
              />
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="client-details-body">
      <div class="client-details-header-top">
        <div class="client-details-title">
          <div class="oblong"></div>
          <div>未完成工单</div>
          <div class="over" @click="getOverOrder">已结束工单</div>
        </div>
      </div>

      <div class="client-details-header-bottom">
        <div class="client-info">
          <a-table
            :columns="columns"
            :data-source="orderList"
            :rowKey="(record) => record.id"
            :loading="loading"
            :pagination="pagination"
            :customRow="rowClick"
          >
            <span slot="period" slot-scope="text">{{
              text ? text + "天" : "/"
            }}</span>
            <span slot="img" slot-scope="img" class="img">
              <span v-if="img.length == 0">/</span>
              <img v-else :src="img[0].url" alt="" />
            </span>

            <span slot="assignedUser" slot-scope="text">{{
              text ? text : "/"
            }}</span>
          </a-table>
        </div>
      </div>
    </div>
    <div class="client-details-footer">
      <div class="client-details-header-top">
        <div class="client-details-title">
          <div class="oblong"></div>
          <div>历史拜访问卷</div>
          <div class="download" @click="assignDownload">下载所选</div>
        </div>
      </div>

      <div class="client-details-header-bottom">
        <div class="client-info">
          <a-table
            :columns="otherColumns"
            :data-source="tasksList"
            :rowKey="(record) => record.id"
            :loading="otherLoading"
            :pagination="otherPagination"
            :row-selection="rowSelection"
          >
            <span slot="finTime" slot-scope="finTime">{{
              finTime ? finTime : "/"
            }}</span>
            <span
              slot="action"
              class="action"
              slot-scope="item"
              @click.stop="download(item)"
              >下载</span
            >
          </a-table>
        </div>
      </div>
    </div>
    <delete-client-modal
      :visible="delClientVisible"
      @ok="delClientOk"
      @cancel="delClientCancle"
    ></delete-client-modal>
  </div>
</template>

<script>
import {
  getStore,
  delStore,
  getRegion,
  editStore,
  getStoreOrder,
  getTasks,
} from "../common/api/client";
import SearchSelect from "../components/SearchSelect.vue";
import DeleteClientModal from "../components/DeleteClientModal.vue";
import { getUser } from "../common/api/user";
import { getGroup } from "../common/api/order";
import { deepClone } from "../common/utils";
import moment from "moment";
import http from "../common/http";
const columns = [
  {
    title: "截止时间",
    dataIndex: "gapdate",
    key: "gapdate",
    // slots: { title: 'customTitle' },
    scopedSlots: { customRender: "period" },
    align: "center",
  },
  {
    title: "工单编号",
    dataIndex: "code",
    key: "code",
    align: "center",
  },
  {
    title: "工单类型",
    dataIndex: "type",
    key: "type",
    align: "center",
  },
  {
    title: "创建时间",
    key: "createTime",
    dataIndex: "createTime",
    align: "center",
  },
  {
    title: "来源",
    key: "createBy",
    dataIndex: "createBy",
    align: "center",
  },

  {
    title: "服务工单主题",
    key: "subject",
    dataIndex: "subject",
    align: "center",
  },
  {
    title: "附件图片",
    key: "urlLists",
    dataIndex: "urlLists",
    scopedSlots: { customRender: "img" },
    align: "center",
  },

  {
    title: "指派处理人",
    key: "assignedUser",
    dataIndex: "assignedUser",
    scopedSlots: { customRender: "assignedUser" },
    align: "center",
  },
];

const otherColumns = [
  {
    title: "完成时间",
    dataIndex: "finTime",
    key: "finTime",
    scopedSlots: { customRender: "finTime" },
    align: "center",
    width: 300,
  },
  {
    title: "提交人",
    dataIndex: "username",
    key: "username",
    align: "center",
    width: 300,
  },
  {
    title: "操作",
    // dataIndex: "reportPath",
    key: "reportPath",
    scopedSlots: { customRender: "action" },
    align: "center",
  },
];
export default {
  name: "ClientDetails",
  props: {},
  components: {
    DeleteClientModal,
    SearchSelect,
  },
  data() {
    return {
      // 点击表格行
      rowClick: (record) => ({
        // 事件
        on: {
          click: () => {
            console.log(record, "record");
            this.$router.push({
              path: "/orderdetails",
              query: {
                id: record.id,
              },
            });
          },
        },
      }),
      // otherRowClick: (record) => ({
      //   // 事件
      //   on: {
      //     click: () => {
      //       const url = http.baseURL + "/" + record.reportPath;
      //       const link = document.createElement("a");
      //       link.style.display = "none";
      //       link.href = url;
      //       link.target = "_blank";
      //       // 指定下载后的文件名，防跳转
      //       link.setAttribute("download", "标准数据模板.pdf");
      //       document.body.appendChild(link);
      //       link.click();
      //     },
      //   },
      // }),
      loading: false,
      otherLoading: false,
      columns,
      otherColumns,
      //门店id
      id: "",
      // 门店详情
      storeDetails: {},
      labelCol: { span: 11 },
      wrapperCol: { span: 12, offset: 1 },
      labelCol1: { span: 9 },
      wrapperCol1: { span: 12, offset: 1 },
      flag: true,
      delClientVisible: false,
      form: {
        name: "",
        address: "",
        bid: undefined,
        uid: undefined,
        region: undefined,
        nextVistDay: null,
        period: "",
      },
      rules: {
        name: [{ required: true, message: "请输入门店名称", trigger: "blur" }],
      },
      // 业务员列表
      staffList: [],
      // 直属领导列表
      leaderList: [],
      allSaleList: [],
      // 集团
      groupList: [],
      choose: true,
      isblur: false,
      iptVal: "",
      areaList: [],
      // 工单列表请求参数
      orderParams: {
        page: 0,
        size: 5,
        statusId: 0,
      },
      // 门店工单列表
      orderList: [],
      // 分页配置对象
      pagination: {
        position: "bottom",
        pageSize: 5,
        current: 1,
        total: 0,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["5", "10", "20"], // 每页数量选项
        showQuickJumper: true,
        // 显示总数
        showTotal: (total) => `共 ${total} 条`,
        // 改变每页数量时更新显示
        onShowSizeChange: (page, pageSize) => this.onSizeChange(page, pageSize),
        // 点击页码事件
        onChange: (page, pageSize) => this.onPageChange(page, pageSize),
      },
      otherPagination: {
        position: "bottom",
        pageSize: 5,
        current: 1,
        total: 0,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["5", "10", "20"], // 每页数量选项
        showQuickJumper: true,
        // 显示总数
        showTotal: (total) => `共 ${total} 条`,
        // 改变每页数量时更新显示
        onShowSizeChange: (page, pageSize) =>
          this.onOtherSizeChange(page, pageSize),
        // 点击页码事件
        onChange: (page, pageSize) => this.onOtherPageChange(page, pageSize),
      },
      // 历史拜访问卷请求参数
      tasksParams: {
        page: 0,
        size: 5,
      },
      // 历史拜访问卷列表
      tasksList: [],
      // 列表项配置对象
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) =>
          this.onRowChange(selectedRowKeys, selectedRows),
      },
      // 下载列表
      downloadList: [],
    };
  },
  methods: {
    // 选择集团品牌
    groupChange(e) {
      if (e == 0) {
        this.form.bid = 0;
      } else {
        this.form.bid = e;
      }
    },
    // 批量下载
    assignDownload() {
      if (this.downloadList.length == 0) {
        return this.$message.info("请选择问卷");
      }
      this.downloadList.forEach((v) => {
        this.download(v);
      });
    },
    onRowChange(selectedRowKeys, selectedRows) {
      console.log(selectedRows);
      this.downloadList = selectedRows;
    },
    // 下载历史拜访问卷
    download(item) {
      // let a = item.finTime.substr(0, 10).replace(/-/g, "");
      let fileName = item.name + item.finTime.substr(0, 10).replace(/-/g, "");
      console.log(item);
      const url = http.baseURL + "/" + item.reportPath;
      // let fileName = this.getDay();
      let reg =
        /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/;
      if (!reg.test(url)) {
        throw new Error("传入参数不合法,不是标准的文件链接");
      } else {
        let xhr = new XMLHttpRequest();
        xhr.open("get", url, true);
        xhr.setRequestHeader("Content-Type", `application/pdf`);
        xhr.responseType = "blob";
        let that = this;
        xhr.onload = function () {
          if (this.status == 200) {
            //接受二进制文件流
            var blob = this.response;
            that.downloadExportFile(blob, fileName);
          }
        };
        xhr.send();
      }
    },
    downloadExportFile(blob, tagFileName) {
      let downloadElement = document.createElement("a");
      let href = blob;
      if (typeof blob == "string") {
        downloadElement.target = "_blank";
      } else {
        href = window.URL.createObjectURL(blob); //创建下载的链接
      }
      downloadElement.href = href;
      downloadElement.download = tagFileName;
      //下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      if (typeof blob != "string") {
        window.URL.revokeObjectURL(href); //释放掉blob对象
      }
    },
    // getDay() {
    //   let time = new Date(),
    //     year = time.getFullYear(),
    //     month = time.getMonth() + 1,
    //     day = time.getDate(),
    //     timeStem = time.getTime();
    //   return `${year}/${month}/${day}/${timeStem}.pdf`;
    // },

    // 获取门店的历史拜访报告
    async getTasksList() {
      this.tasksParams.id = this.storeDetails.id;

      this.otherLoading = true;
      const res = await getTasks(this.tasksParams);
      this.otherLoading = false;
      console.log("历史拜访报告：", res);
      this.tasksList = res.data.content;
      this.otherPagination.total = res.data.totalElements;
    },
    // 获取已结束工单
    getOverOrder() {
      this.$emit("clientToOrder", {
        name: this.storeDetails.name,
        statusId: 9,
      });
    },
    // 改变每页数量时更新显示
    onSizeChange(page, pageSize) {
      console.log("每页数量:", pageSize);
      this.pagination.current = 1;
      this.pagination.pageSize = pageSize;
      this.orderParams.page = 0;
      this.orderParams.size = pageSize;
      this.getStoreOrderList();
    },
    // 点击页码事件
    onPageChange(page) {
      console.log("页码值:", page);
      this.pagination.current = page;
      this.orderParams.page = page - 1;
      this.getStoreOrderList();
    },
    // 改变每页数量时更新显示
    onOtherSizeChange(page, pageSize) {
      console.log("每页数量:", pageSize);
      this.otherPagination.current = 1;
      this.otherPagination.pageSize = pageSize;
      this.tasksParams.page = 0;
      this.tasksParams.size = pageSize;
      this.getTasksList();
    },
    // 点击页码事件
    onOtherPageChange(page) {
      console.log("页码值:", page);
      this.otherPagination.current = page;
      this.tasksParams.page = page - 1;
      this.getTasksList();
    },
    // 获取门店工单列表
    async getStoreOrderList() {
      this.orderParams.id = this.storeDetails.id;
      this.loading = true;
      const res = await getStoreOrder(this.orderParams);
      this.loading = false;
      console.log(res, "门店工单列表");
      this.orderList = res.data.content;
      this.pagination.total = res.data.totalElements;
    },
    // 保存基本信息
    save() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          console.log(this.form, "this.form");
          let params = deepClone(this.form);
          console.log(params, "params");
          params.id = this.id;
          params.nextVistDay = "";
          if (this.form.nextVistDay) {
            params.nextVistDay = this.form.nextVistDay.format("YYYY-MM-DD");
          } else {
            delete params.nextVistDay;
          }
          const res = await editStore(params);
          console.log(res);
          this.$store.commit("updateRefreshPath", "client");
          this.$message.success(res.data);
          this.flag = true;
          this.getStoreInfo();
        }
      });
    },
    // 选择时间
    dateChange(date, dateStr) {
      console.log("dateStr", dateStr);
      if (dateStr) {
        this.form.nextVistDay = moment(dateStr, "YYYY-MM-DD");
      } else {
        this.form.nextVistDay = null;
      }
    },
    // 输入天数
    timeChange(e) {
      var r = /^\+?[1-9][0-9]*$/;
      let flag = r.test(e.target.value);

      if (flag) {
        this.form.period = e.target.value;
        let time = this.getLaterDate(this.form.period);
        this.form.nextVistDay = moment(time, "YYYY-MM-DD");
      } else {
        this.form.period = "";
      }
    },
    // 根据天数计算时间
    getLaterDate(dayNum, dateTime = null) {
      // 如果为null,则格式化当前时间为时间戳
      if (!dateTime) dateTime = +new Date();
      // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
      if (dateTime.toString().length == 10) dateTime *= 1000;
      const timestamp = +new Date(Number(dateTime));

      const one_day = 86400000; // 24 * 60 * 60 * 1000;
      const addVal = dayNum * one_day + timestamp;
      //x天后的日期
      const date = new Date(addVal);

      //格式化日期
      const filters = (n) => {
        return n < 10 ? (n = "0" + n) : n;
      };
      const month = filters(date.getMonth() + 1);
      const day = filters(date.getDate());
      const hours = filters(date.getHours());
      const minutes = filters(date.getMinutes());
      const seconds = filters(date.getSeconds());

      const lastTime = `${date.getFullYear()}-${month}-${day}`;

      return lastTime;
    },
    // 获取门店区域
    async getAreaList() {
      const res = await getRegion();
      // console.log(res);
      this.areaList = res.data;
    },
    // 获取门店集团
    async getGrouplist() {
      const res = await getGroup();
      console.log(res);
      this.groupList = res.data;
    },

    // 获取直属领导列表
    async getLeaderList() {
      const params = {
        page: 0,
        size: 999,
        roleId: 3,
      };
      const res = await getUser(params);
      this.leaderList = res.data.content;
      this.handleAllSale(this.staffList, this.leaderList);
    },
    // 获取业务员列表
    async getStaffList() {
      const params = {
        page: 0,
        size: 999,
        roleId: 4,
      };
      const res = await getUser(params);
      // console.log("业务员列表:", res);
      this.staffList = res.data.content;

      this.getLeaderList();
    },
    // 获取所有业务员列表
    handleAllSale(a, b) {
      this.allSaleList = [...a, ...b];
      // 这里对id属性进行去重
      const map = new Map();
      this.allSaleList = this.allSaleList.filter(
        (item) => !map.has(item.id) && map.set(item.id, 1)
      );
    },
    // 确认删除门店
    async delClientOk() {
      const res = await delStore({
        ids: this.storeDetails.id,
      });
      console.log(res);
      this.$message.success(res.data);
      this.delClientVisible = false;
      this.$router.back();
    },
    // 取消删除门店
    delClientCancle() {
      this.delClientVisible = false;
    },
    // 删除门店
    delStore() {
      if (!this.flag) return this.$message.info("请退出编辑状态");
      this.delClientVisible = true;
    },
    // 获取门店详情
    async getStoreInfo() {
      const res = await getStore({
        id: this.id,
      });
      console.log(res);
      this.storeDetails = res.data;
      let obj = deepClone(this.storeDetails);
      this.form.name = obj.name;
      if (obj.saleId) {
        this.form.uid = obj.saleId;
      }
      if (obj.brand.id) {
        this.form.bid = obj.brand.id;
      }
      if (obj.groupManager) {
        this.form.groupManager = obj.groupManager;
      }
      if (obj.address) {
        this.form.address = obj.address;
      }
      if (obj.region) {
        this.form.region = obj.region;
      }
      if (obj.period) {
        this.form.period = obj.period;
      } else {
        this.form.period = "";
      }

      if (obj.nxtDealDate) {
        this.form.nextVistDay = moment(obj.nxtDealDate, "YYYY-MM-DD");
      } else {
        this.form.nextVistDay = null;
      }
    },
    // 编辑门店信息
    editStoreInfo() {
      this.flag = false;
    },
    // 取消编辑
    cancelStoreInfo() {
      this.$refs["ruleForm"].clearValidate();
      this.getStoreInfo();
      this.flag = true;
    },
  },
  async created() {
    this.id = this.$route.query.id;
    await this.getStoreInfo();
    await this.getStaffList();
    await this.getGrouplist();
    await this.getAreaList();
    await this.getStoreOrderList();
    await this.getTasksList();
  },
};
</script>

<style scoped lang="scss">
.ClientDetails {
  box-sizing: border-box;
  .client-details-header,
  .client-details-body,
  .client-details-footer {
    margin-bottom: 33px;
    padding: 24px 32px 47px 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 16px;
    .client-details-header-top {
      margin-bottom: 20px;
      padding-right: 35px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .client-details-title {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #303030;
        .oblong {
          margin-right: 8px;
          width: 4px;
          height: 24px;
          background: #83ba7b;
        }
      }
      .client-details-option {
        display: flex;
        .common {
          margin-left: 16px;
          width: 72px;
          height: 32px;
          background: #3298f1;
          text-align: center;
          line-height: 32px;
          color: #fff;
          border-radius: 4px;
          cursor: pointer;
        }
        .btn {
          display: flex;
          .cancel {
            background: rgba(191, 193, 198, 0.5);
          }
        }
        .delete {
          background: #e93024;
        }
      }
    }
    .client-details-header-bottom {
      display: flex;
      align-items: center;
      margin-left: 13px;
      padding: 18px 0 39px 34px;
      box-sizing: border-box;
      border: 2px solid rgba(191, 193, 198, 0.5019607843137255);
      font-size: 14px;
      color: #303030;

      .client-info {
        // margin-left: 38px;
        flex: 1;
        color: #303030;
        .ant-form {
          display: flex;
          flex-wrap: wrap;

          .ant-row {
            width: 27%;
            margin-bottom: 8px;

            color: #303030;
            &:nth-child(3n + 1) {
              margin-right: 80px;
            }
            &.hide {
              .ant-form-item-children {
                span {
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                  overflow: hidden;
                }
              }
            }

            &:nth-last-child(-n + 2) {
              margin-bottom: 0;
            }
            .ant-input {
              width: 130px;
              height: 30px;
              &.name {
                padding-left: 0;
              }
              &.num {
                margin-right: 5px;
                width: 52px;
                height: 30px;
              }
            }
            .ant-input[disabled] {
              background: #fff;
              color: #303030;
              border: 0;
            }
          }
        }
      }
    }
  }
  .client-details-body,
  .client-details-footer {
    .client-details-header-top {
      .over {
        margin-left: 30px;
        color: #83ba7b;
        cursor: pointer;
      }
      .download {
        margin-left: 30px;
        width: 88px;
        height: 32px;
        background: #83ba7b;
        line-height: 32px;
        text-align: center;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
      }
    }
    .client-details-header-bottom {
      border: 0;
      margin-left: 0;
      padding: 0;
      .client-info {
        margin-left: 0;
        .ant-table-body {
          .img {
            img {
              width: 48px;
              height: 42px;
              object-fit: cover;
            }
          }
          .action {
            color: #3298f1;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
