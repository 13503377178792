<template>
  <div class="ModuleTitle">
    <div class="title">
      <div class="oblong"></div>
      <div>{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  // 组件名称
  name: "ModuleTitle",

  props: {
    // 标题
    text: {
      type: String,
      default: "",
    },
  },

  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped lang="scss">
.title {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #303030;
  .oblong {
    margin-right: 8px;
    width: 4px;
    height: 24px;
    background: #83ba7b;
  }
}
</style>
