var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Question"},[_c('div',{staticClass:"ques-header"},[_c('div',{staticClass:"ques-header-top"},[_vm._m(0),_c('div',{staticClass:"btn",on:{"click":_vm.createQues}},[_vm._v("新增问题")]),_c('div',{staticClass:"btn group",on:{"click":_vm.toQuestionGroup}},[_vm._v("问题组管理")])]),_c('div',{staticClass:"ques-header-bottom"},[_c('div',{staticClass:"ques-header-bottom-left"},[_c('a-form-model',{attrs:{"model":_vm.form,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"colon":false}},[_c('a-form-model-item',[_c('a-input',{attrs:{"placeholder":"请输入问题名称关键词"},on:{"pressEnter":_vm.search},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.name"}})],1)],1),_c('div',{staticClass:"search",on:{"click":_vm.search}},[_vm._v("查询")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isTemp),expression:"isTemp"}],staticClass:"ques-header-bottom-right"},[_c('div',{style:({
            background: _vm.selectedRowKeys.length ? '#bfc1c6' : '#dfe0e2',
          }),on:{"click":_vm.reset}},[_vm._v(" 重置 ")]),_c('div',{staticClass:"finish",style:({
            background: _vm.selectedRowKeys.length ? '#83ba7b' : '#8ed79f',
          }),on:{"click":_vm.finish}},[_vm._v(" 完成 ")])])])]),_c('div',{ref:"quesMain",staticClass:"ques-main"},[_c('a-table',{attrs:{"columns":_vm.isTemp ? _vm.columns2 : _vm.columns1,"data-source":_vm.computedData,"loading":_vm.loading,"row-selection":_vm.isTemp ? _vm.rowSelection : undefined,"rowKey":function (record) { return record.id; },"pagination":_vm.pagination,"scroll":{ y: _vm.maxHeight }},scopedSlots:_vm._u([{key:"judgment",fn:function(judgment){return _c('div',{},[_vm._v(" "+_vm._s(judgment ? judgment : "/")+" ")])}},{key:"tags",fn:function(tags){return _c('div',{staticClass:"tags"},[(tags.length)?_vm._l((tags),function(item){return _c('div',{key:item.id,style:({ color: item.color })},[_vm._v(" "+_vm._s(item.name)+" ")])}):_c('div',{staticClass:"text"},[_vm._v("/")])],2)}},{key:"type",fn:function(type){return _c('span',{},[_vm._v(" "+_vm._s(type === "SELECT" ? "单选" : "录入")+" ")])}},{key:"imgNeed",fn:function(imgNeed){return _c('span',{},[(imgNeed == 0)?_c('span',[_vm._v("禁止")]):_vm._e(),(imgNeed == 1)?_c('span',[_vm._v("允许")]):_vm._e(),(imgNeed == 2)?_c('span',[_vm._v("必须")]):_vm._e()])}},{key:"manual",fn:function(manual, record){return _c('span',{},[(record.type == 'SELECT')?_c('span',[_vm._v(_vm._s(manual ? "手动" : "自动"))]):_c('span',[_vm._v("/")])])}},{key:"tagList",fn:function(tagList){return _c('div',{staticClass:"tag-list",style:({ height: _vm.maxHeight + 'px' })},_vm._l((tagList),function(item,index){return _c('div',{key:item.id,style:({
            background: _vm.getColor(item.id) ? item.color : '#fff',
            color: _vm.getColor(item.id) ? '#fff' : item.color,
            border: _vm.getColor(item.id) ? 'none' : '1px solid #000',
            borderColor: _vm.getColor(item.id) ? 'none' : item.color,
          }),on:{"click":function($event){return _vm.clickTag(item.id, index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)}},{key:"action",fn:function(item){return _c('div',{staticClass:"action"},[_c('div',{on:{"click":function($event){return _vm.look(item.id)}}},[_vm._v("查看")]),_c('div',{staticClass:"del",on:{"click":function($event){return _vm.del(item.id)}}},[_vm._v("删除")])])}}])},[_c('span',{attrs:{"slot":"customGroupName"},slot:"customGroupName"},[_c('a-select',{on:{"change":_vm.groupNameChange},model:{value:(_vm.groupName),callback:function ($$v) {_vm.groupName=$$v},expression:"groupName"}},_vm._l((_vm.questionGroupList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name)+" ")])}),1)],1),_c('span',{attrs:{"slot":"tag"},slot:"tag"},[_c('a-select',{on:{"change":_vm.tagChange},model:{value:(_vm.tag),callback:function ($$v) {_vm.tag=$$v},expression:"tag"}},_vm._l((_vm.tagList1),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name)+" ")])}),1)],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isTemp),expression:"isTemp"}],staticClass:"tag-box",style:({ height: _vm.maxHeight + 'px' })},_vm._l((_vm.tagList),function(item,index){return _c('div',{key:item.id,style:({
          background: _vm.getColor(item.id) ? item.color : '#fff',
          color: _vm.getColor(item.id) ? '#fff' : item.color,
          border: _vm.getColor(item.id) ? 'none' : '1px solid #000',
          borderColor: _vm.getColor(item.id) ? 'none' : item.color,
        }),on:{"click":function($event){return _vm.clickTag(item.id, index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)],1),_c('create-quse-modal',{key:_vm.componentKey,ref:"createQuseModal",attrs:{"visible":_vm.visible},on:{"ok":_vm.createQuseOk,"cancel":_vm.createQuseCancel}}),_c('div',{ref:"handleRefModel",staticClass:"handle-modal"},[_c('a-modal',{attrs:{"title":"操作确认","maskClosable":false,"centered":"","width":"395px","getContainer":function () { return _vm.$refs.handleRefModel; },"cancelText":"否","okText":"是"},on:{"ok":_vm.handleOk},model:{value:(_vm.delVisible),callback:function ($$v) {_vm.delVisible=$$v},expression:"delVisible"}},[_c('div',[_vm._v("确认删除所选问题？")])])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"oblong"}),_c('div',[_vm._v("问题列表")])])}]

export { render, staticRenderFns }