<template>
  <div class="LeaveModal">
    <div class="leave-user-modal" ref="leaveRefModel">
      <a-modal
        v-model="isShow"
        title="客户批量移交"
        :maskClosable="false"
        @ok="handleOk"
        centered
        width="450px"
        :getContainer="() => $refs.leaveRefModel"
        @cancel="handleCancel"
        cancelText="取消"
        okText="确定"
      >
        <a-radio-group v-model="value">
          <a-radio :style="radioStyle" :value="1">
            全部移交
            <a-select
              style="width: 224px"
              v-model="id"
              placeholder="请选择区域经理/服务工程师"
            >
              <a-select-option
                :value="item.id"
                v-for="(item, index) in fliterData"
                :key="index"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-radio>
          <a-radio :style="radioStyle" :value="2"> 有选择的移交 </a-radio>
        </a-radio-group>
      </a-modal>
    </div>
  </div>
</template>

<script>
export default {
  // 组件名称
  name: "LeaveModal",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    // 指派业务员列表
    appointList: {
      type: Array,
      default() {
        return [];
      },
    },
    currentId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      // 选中的radio
      value: 1,
      // 是否显示
      isShow: false,
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      // 业务员id
      id: undefined,
    };
  },
  watch: {
    visible(val) {
      this.isShow = val;
    },
  },
  methods: {
    // 确认
    handleOk() {
      this.$emit("ok", {
        value: this.value,
        id: this.id,
      });
    },
    // 取消
    handleCancel() {
      this.value = 1;
      this.id = undefined;
      this.$emit("cancel");
    },
  },
  computed: {
    fliterData() {
      return this.appointList.filter((v) => {
        return v.id != this.currentId;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.leave-user-modal::v-deep {
  .ant-modal-header {
    .ant-modal-title {
      text-align: center !important;
    }
  }

  .ant-modal-body {
    padding: 24px 75px;
    .ant-select {
      margin-left: 20px;
      height: 30px;
    }
    .ant-radio-wrapper:nth-child(1) {
      margin-bottom: 5px;
    }
  }
  .ant-modal-footer {
    text-align: center;
    padding-bottom: 44px;
    border: 0;
    div button {
      width: 72px;
      height: 32px;
      &:nth-child(2) {
        margin-left: 80px;
        background: #83ba7b;
        border: 0;
      }
    }
  }
}
</style>
