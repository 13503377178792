<template>
  <div class="MyModal">
    <div class="handle-modal" ref="handleRefModel">
      <a-modal
        v-model="isShow"
        title="操作确认"
        @ok="handleOk"
        :maskClosable="false"
        centered
        width="395px"
        :getContainer="() => $refs.handleRefModel"
        @cancel="handleCancel"
        cancelText="否"
        okText="是"
      >
        <div>
          将设置用户
          <span>“{{ info.name }}”</span> 为<span>“{{ text }}”</span>，
        </div>
        <div>是否继续操作?</div>
      </a-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default() {
        return {};
      },
    },
    text: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      // 是否显示
      isShow: false,
    };
  },
  watch: {
    visible(val) {
      this.isShow = val;
    },
  },

  methods: {
    // 确认
    handleOk() {
      this.$emit("ok");
    },
    handleCancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped lang="scss">
.handle-modal::v-deep {
  .ant-modal-header {
    .ant-modal-title {
      text-align: center !important;
    }
  }
  .ant-modal-body {
    text-align: center;
    span {
      color: #ec4e44;
    }
  }
  .ant-modal-footer {
    text-align: center;
    padding-bottom: 44px;
    border: 0;
    div button {
      width: 72px;
      height: 32px;
      &:nth-child(2) {
        margin-left: 80px;
        background: #83ba7b;
        border: 0;
      }
    }
  }
}
</style>
