import $http from '../http'


// 新建Tag
export const addTag = data => {
    return $http.post(`/api/v1/admin/tags`, data)
}

// 获取Tag列表
export const getTag = data => {
    return $http.get(`/api/v1/admin/tags`, data)
}

// 删除Tag
export const delTag = data => {
    return $http.del(`/api/v1/admin/tags/${data.id}`, data)
}

// 修改Tag
export const editTag = data => {
    const params = JSON.parse(JSON.stringify(data))
    delete params.id
    return $http.put(`/api/v1/admin/tags/${data.id}`, params)
}