import $http from '../http'

// 获取订单列表
export const getOrder = data => {

    return $http.get(`/api/v1/admin/workorder/list`, data)
}

// 获取门店集团
export const getGroup = data => {

    return $http.get(`/api/v1/admin/groups`, data)
}


// 新建工单
export const addOrder = data => {

    return $http.post(`/api/v1/admin/workorder`, data)
}

// 上传单张图片
export const uploadImg = data => {
    return $http.post(`/api/v1/users/imageUpload`, data)
}

// 批量导入
export const uploadExcel = (url, data) => {
    return $http.post(url, data)
}

// 编辑工单
export const editOrder = data => {
    const params = JSON.parse(JSON.stringify(data))
    delete params.id
    return $http.put(`/api/v1/admin/workorder/${data.id}`, params)
}

// 修改工单状态
export const editStatus = data => {
    const params = JSON.parse(JSON.stringify(data))
    delete params.ids
    return $http.put(`/api/v1/admin/workorder/status/${data.ids}`, params)
}

// 批量指派工单
export const assignOrder = data => {
    const params = JSON.parse(JSON.stringify(data))
    delete params.ids
    return $http.put(`/api/v1/admin/workorder/assign/${data.ids}`, params)
}

// 获取工单详情
export const getOrderInfo = data => {

    return $http.get(`/api/v1/admin/workorder/${data.id}`, data)
}